<div class="loadingio-spinner-dual-ring-45uwa8bw96g">
    <div class="ldio-ms32wywwu2l">
        <div></div>
        <div>
            <div></div>
        </div>
    </div>
</div>
<style type="text/css">
    @keyframes ldio-ms32wywwu2l {
        0% {
            transform: rotate(0)
        }
        100% {
            transform: rotate(360deg)
        }
    }
    
    .ldio-ms32wywwu2l div {
        box-sizing: border-box!important
    }
    
    .ldio-ms32wywwu2l>div {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        border: 10px solid #000;
        border-color: #f95959 transparent #f95959 transparent;
        animation: ldio-ms32wywwu2l 1s linear infinite;
    }
    
    .ldio-ms32wywwu2l>div:nth-child(2) {
        border-color: transparent
    }
    
    .ldio-ms32wywwu2l>div:nth-child(2) div {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
    }
    
    .ldio-ms32wywwu2l>div:nth-child(2) div:before,
    .ldio-ms32wywwu2l>div:nth-child(2) div:after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        top: -10px;
        left: 15px;
        background: #f95959;
        border-radius: 50%;
        box-shadow: 0 50px 0 0 #f95959;
    }
    
    .ldio-ms32wywwu2l>div:nth-child(2) div:after {
        left: -10px;
        top: 15px;
        box-shadow: 50px 0 0 0 #f95959;
    }
    
    .loadingio-spinner-dual-ring-45uwa8bw96g {
        width: 25px;
        height: 25px;
        display: inline-block;
        overflow: hidden;
        background: rgba(NaN, NaN, NaN, 0);
    }
    
    .ldio-ms32wywwu2l {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(0.34);
        backface-visibility: hidden;
        transform-origin: 0 0;
        /* see note above */
    }
    
    .ldio-ms32wywwu2l div {
        box-sizing: content-box;
    }
    /* generated by https://loading.io/ */
</style>