import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InitDisplayedCol } from '../../models/common';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-chart-summary-table',
  templateUrl: './chart-summary-table.component.html',
  styleUrls: ['./chart-summary-table.component.css']
})
export class ChartSummaryTableComponent implements OnInit, OnChanges {
  @Input() showFooter: boolean = false;
  @Input() tableWrapperClass: string = '';
  @Input() tableClass: string = '';
  @Input() headerClass: string = '';
  @Input() initColumns: InitDisplayedCol[];
  @Input() set data(payload: any) {
    let data = payload;

    this.dataSource = new MatTableDataSource(data);
  }
  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any>;

  constructor() { }

  ngOnInit(): void {
    this.displayedColumns = this.initColumns.map((col) => col.key);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.displayedColumns = this.initColumns.map((col) => col.key);
  }

  public customTrackBy(index) {
    return index;
  }

}
