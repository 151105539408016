import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IndexedDBService } from 'src/app/indexDB/indexedDB.service';
import { DataService } from 'src/app/shared/services/data.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideNavEmit: EventEmitter<any> = new EventEmitter();
  @Output() sendNotificationEmit: EventEmitter<any> = new EventEmitter();
  @Input() showMobileToggler: boolean;
  @Input() u: any = {};

  public baseUrl: string = environment.baseUrl;
  public _notifications: any = [];
  public hasNotification: boolean = false;

  constructor(
    private utilsService: UtilityService,
    private dataService: DataService,
    private router: Router,
    private _auth: AuthService,
    private toast: NotificationService,
    private iDBService: IndexedDBService,
  ) {}

  // open/close side menu... Emitted in Main Component
  public toggleSideNav() {
    this.toggleSideNavEmit.emit();
  }

  // populate notifications on app load before subscribing to websocket
  private getNotifications() {
    this.utilsService.currentNotifications.subscribe((data) => {
      if (data !== undefined) {
        this._notifications = data.notifications;
        this.hasNotification = true;
      }
    });
  }

  //
  public sendNotification(data) {
    this.sendNotificationEmit.emit(data);
    this.removeNotification(data.notificationType);
  }
  public removeNotification(data) {
    this.dataService.deleteANotification({ notificationType: data }).subscribe(
      (status) => {
        if (status.code !== 0) {
          this.toast.error(`${status.message}.`);
        }
      },
      (error) => {
        this.toast.error('An error occured when deleting notification.');
      }
    );
  }

  public logout(): void {
  
    this._auth.logout().subscribe({
      next: ()=>{
        this.toast.success("User logged out successfully");
        this.iDBService.deleteDatabase();
        
        this.router.navigate(['/login'])
        // location.href = '/login';

        //clear the indexDB database
      },
      error: (err)=>{
        localStorage.clear();
        this.toast.success("User logged out successfully")
        // this.router.navigate(['/login'])
        location.href = '/login';
      },
    });
  }

  private onInit() {
    this.getNotifications();
  }

  ngOnInit(): void {
    this.onInit();
  }
}
