import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operational-indicators',
  templateUrl: './operational-indicators.component.html',
  styleUrls: ['./operational-indicators.component.css']
})
export class OperationalIndicatorsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }

}
