import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NotificationService } from 'src/app/utils/notification.service';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-upload-ui',
  templateUrl: './file-upload-ui.component.html',
  styleUrls: ['./file-upload-ui.component.css'],
})
export class FileUploadUiComponent implements OnInit, OnChanges {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @ViewChild('imageElem', { static: false }) imageElem: ElementRef;

  @Input() classNames: string;
  @Input() disabled: boolean;
  @Input() editing: boolean;
  @Input() label: string;
  @Input() currentPhoto: string;
  public defaultPhoto =
    '../../../../../../assets/images/aquila-placeholder.svg';
  public initialLable: string;

  @Output() upload: EventEmitter<any> = new EventEmitter();
  @Output() removeFile: EventEmitter<null> = new EventEmitter();

  file: any;
  isFileUploaded: boolean = false;

  constructor(
    private toast: NotificationService,
    private utils: UtilityService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isFileUploaded = changes['currentPhoto']?.currentValue ? true : false;
  }

  ngOnInit(): void {
    this.initialLable = this.label;
  }

  defaultImagePlaceholder(ev: any) {
    return (ev.target.src = this.defaultPhoto);
  }

  selectFile() {
    if (this.disabled) return;
    this.fileInput.nativeElement.click();
  }

  setSelectedImg(ev: any) {
    const file = ev.target.files[0];

    const fileSizeValid = this.utils.limitFileSize(file.size);

    if (!fileSizeValid) {
      this.toast.error('File uploaded should not be more that 2MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = (rs) => {
        this.label = file.name;
        this.imageElem.nativeElement.src = reader.result;
        this.isFileUploaded = true;

        // * Handle file compression
        // const canvas = document.createElement('canvas');
        // const ctx = canvas.getContext('2d');

        // Set canvas dimensions to match the image
        // canvas.width = img.width;
        // canvas.height = img.height;

        // Draw the image onto the canvas
        // ctx.drawImage(img, 0, 0);

        // Compress the image as a JPEG with quality 0.7 (adjust as needed)
        // const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.7);

        // Convert data URL back to a Blob
        // const compressedBlob = this.dataURLToBlob(compressedDataUrl);

        // this.upload.emit(compressedBlob);
        this.upload.emit(reader.result);
      };

      img.onerror = (err: any) => {
        if (err.type == 'error') {
          this.toast.error('File not an image. Please upload an image');
        }
      };
    };

    reader.readAsDataURL(file);
  }

  dataURLToBlob(dataURL) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new Blob([u8arr], { type: mime });
  }

  public get formatLabel() {
    if (!this.label) return '';
    if (this.label.length > 25) {
      return this.label.slice(0, 15) + '...' + this.label.slice(-10);
    }
    return this.label;
  }

  onRemoveFile() {
    this.label = this.initialLable;
    this.imageElem.nativeElement.src = '';
    this.fileInput.nativeElement.value = '';
    this.isFileUploaded = false;
    this.removeFile.emit(null);
  }

  viewFile() {}
}
