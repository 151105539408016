import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  baseCoreUrl: string = environment.baseUrl + environment.coreUrl;
  baseUrl: string = environment.baseUrl

  public searchAreaEndpoint = `${this.baseCoreUrl}areas?page=0&pageSize=500&state=APPROVED&sort-field=lastUpdateTime&sort-order=DESC&filter=`
  public searchBranchByAreaEndpoint = (id)=> `${this.baseCoreUrl}areas/branches?areaId=${id}&page=0&pageSize=500&state=APPROVED&sort-field=last_update_time&sort-order=DESC&filter=`

  constructor(private http: HttpClient) { }

  public createArea(data) {
    return this.http.post<any>(this.baseCoreUrl + 'areas', data);
  }

  public updateArea(data) {
    return this.http.put<any>(this.baseCoreUrl + 'areas', data);
  }

  public getAreas(params: string) {
    return this.http.get(`${this.baseCoreUrl}areas?${params}`);
  
  }

  

  public searchArea(param: string):Observable<any> {
    return this.http.get(`${this.searchAreaEndpoint}${param}`);
  }
  public searchBranchByArea(id:number, payload: any):Observable<any> {
    const query = new URLSearchParams(payload).toString();
    return this.http.get(`${this.baseCoreUrl}areas/branches?areaId=${id}&${query}`);
  }


  public toggleAreas(data:any): Observable<any>{
    return this.http.post<any>(this.baseCoreUrl + 'areas/toggle', data);
  }

  public rejectAreas(data:any): Observable<any>{
    return this.http.post<any>(this.baseCoreUrl + 'areas/reject', data);
  }

  public getArea(areaId) {
    return this.http.get(`${this.baseCoreUrl}areas/${areaId}`);
  }

  public assignBranches(data: any) {
    return this.http.post(`${this.baseCoreUrl}areas/branches`, data);
  }
 


}
