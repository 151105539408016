import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Menu } from 'src/app/shared/models/common';
import { PermissionService } from 'src/app/utils/permission.service';
import { RoleService } from '../../shared/services/role.service';
import { UserService } from '../../shared/services/user.service';
import { Unsubscribe } from 'src/app/utils/unsubscribe.service';
import { getSideMenus } from './menu';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NotificationService } from 'src/app/utils/notification.service';
import { IndexedDBService } from 'src/app/indexDB/indexedDB.service';

interface SideNavToggle {
  screenWidth: number,
  collapsed: boolean
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent extends Unsubscribe implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private showReports: boolean;
  @Output() toggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter()
  collapsed: boolean = true
  screenWidth = 0

  public menus: Menu[] = [];
  public AllMenu: Menu[] = getSideMenus();
  public franchiseeDetails: any = {};
  public u: any = {};
  private hour: number;
  private year: any;

  constructor(
    public roleService: RoleService,
    private router: Router,
    private _auth: AuthService,
    private _user: UserService,
    private permissionsService: PermissionService,
    private sanitizer: DomSanitizer,
    private toast: NotificationService,
    private iDBService: IndexedDBService,
  ) {
    super()
    this.evaluateUserMenu();
  }
  /**
   * iterate through menu and submenu
   * compare list of (menu | submenu) permissions against client permissions
   * return filtered menu;
   */
  public evaluateUserMenu() {
    // this.subscription.add(
      this.permissionsService.UsersPermissions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((perms) => {
        if (!Object.keys(perms).length) return;
        const allMenu: Menu[] = JSON.parse(JSON.stringify(this.AllMenu));
        this.menus = allMenu.reduce((acc: Menu[], menu: Menu) => {
          if (menu.children) {
            menu.children = menu.children.filter((subMenu) =>
              this.permissionsService.hasPermissionList(subMenu.perm)
            );
            if (!menu.children.length) return acc;
            acc.push(menu);
            return acc;
          }
          if (
            !menu.children &&
            !this.permissionsService.hasPermissionList(menu.perm)
          )
            return acc;
          acc.push(menu);
          return acc;
        }, []);
      })
    // );
  }

  public navigate(link: string) {
    if (link === 'logout') {
      this.logout();
      return;
    }
    if (link === 'updates') {
      this.setViewedUpdate(this.u.id);
      return;
    }
    this.activateBtn(link);
    this.router.navigate([link]);
  }

  public activateBtn(link) {
    this.menus.forEach((element, i) => {
      if(element.link === link) {
        element.active = true;
      }else {
        element.active = false;
      }
    });
  }

  setViewedUpdate(id) {
    this._user.setViewedUpdateToTrue(id).subscribe((data) => {
      this.u.viewedUpdate = true;
      localStorage.setItem('user', JSON.stringify(this.u));
    });
  }

  logout(): void {
    this._auth.logout().subscribe({
      next: ()=>{
        ("User logged out successfully")
        this.toast.success("User logged out successfully")
        this.iDBService.deleteDatabase()
        location.href = '/login';

        //clear the indexDB database
      },
      error: (err)=>{
        localStorage.clear();
        this.toast.success("User logged out successfully")
        location.href = '/login';
      },
    });
  }

  ngOnInit(): void {
    this.listenOnWindowWidthChanged()
    
    this.hour = new Date().getHours();
    this.year = new Date().getFullYear();
    this.showReports =
      this.hour < 8 || (this.hour >= 10 && this.hour <= 16) || this.hour >= 20;

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event:any) => {
        this.activateBtn(`/${event.url.split('/')[1].split('?')[0]}`);
      });
  }

  listenOnWindowWidthChanged() {
    this.screenWidth = window.innerWidth
    if(this.screenWidth <= 768) {
      this.collapsed = false
      this.toggleSideNav.emit({ screenWidth: this.screenWidth, collapsed: this.collapsed })
    } else {
      this.collapsed = true
      this.toggleSideNav.emit({ screenWidth: this.screenWidth, collapsed: this.collapsed })
    }
    
    fromEvent(window, 'resize').pipe(takeUntil(this.unsubscribe$), debounceTime(200)).subscribe(ev => {
      if(window.innerWidth <= 768) {
        this.collapsed = false
        this.toggleSideNav.emit({ screenWidth: window.innerWidth, collapsed: this.collapsed })
      } else {
        this.collapsed = true
        this.toggleSideNav.emit({ screenWidth: window.innerWidth, collapsed: this.collapsed })
      }
    })
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed
    this.toggleSideNav.emit({ screenWidth: this.screenWidth, collapsed: this.collapsed })
  }

  closeNav() { 
    this.collapsed = false
    this.toggleSideNav.emit({ screenWidth: this.screenWidth, collapsed: this.collapsed })
  }

  renderIcon(icon: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(icon);
  }

}
