<app-modal-header-ui [title]="title"></app-modal-header-ui>

<div class="dialog-body">
    <div style="
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
    ">
        <div style="background-color: white; ">
            <!-- <div style="
          border-bottom: 1px solid;
          justify-content: space-between;
          display: flex;
          align-items: center;
          padding: 0px 15px;
        ">
                <h4 style="padding: 5px 0">{{ title }}</h4>
                <p style="font-size: 18px;">x</p>
            </div> -->

            <canvas #can id="can" width="300px" height="150px"></canvas>
            <div class="flex items-center justify-between mb-3">
                <app-button-icon-ui [buttonType]="'button'" classNames="p-3 rounded-xl text-white bg-success/90 hover:bg-success w-[150px]" (click)="save()" name="Save" value="submit"></app-button-icon-ui>
                <app-button-icon-ui [buttonType]="'button'" classNames="p-3 rounded-xl border border-error opacity-80 hover:opacity-100 text-error w-[150px]" (clickEmit)="clear()" name="Clear" value="clear"></app-button-icon-ui>
            </div>
            <!-- <div style="
          justify-content: space-between;
          display: flex;
          align-items: flex-end;
        ">
                <button style="
            width: 100%;

            width: 100%;
            padding: 5px;
            border: 1px solid;
          " (click)="clear()">
          Clear
        </button>
                <button style="color: white; width: 100%; padding: 5px; background: rgb(37,49,62)" data-dismiss="modal" (click)="save()">
          Save
        </button>
            </div> -->
        </div>
    </div>
</div>