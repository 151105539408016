import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, merge, mergeWith, switchMap } from 'rxjs';
import { OperationalIndicatorService } from 'src/app/pages/dashboard/services/operational-indicator.service';
import { TopFilterService } from 'src/app/pages/dashboard/services/topFIlter.service';
import { parChartData } from 'src/app/shared/charts/datasource';
import {
  ChartFilterControlsInterface,
  ReportInputTypes,
} from 'src/app/shared/models/common';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-portfolio-at-risk',
  templateUrl: './portfolio-at-risk.component.html',
  styleUrls: ['./portfolio-at-risk.component.css'],
})
export class PortfolioAtRiskComponent implements OnInit {
  public hideFilters: boolean = false;
  public isLoading: boolean = false;
  public subject = new Subject<number>();
  public tenantId = JSON.parse(localStorage.getItem('pf') || null).tenantId;
  public portfolioForm: FormGroup;
  public portfolioFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    {
      name: ReportInputTypes.LOAN_PRODUCT,
      type: 'LOAN_PRODUCT',
      position: 'right',
    },
    {
      name: ReportInputTypes.PORTFILIO_AT_RISK,
      type: 'PORTFILIO_AT_RISK',
      position: 'right',
    },
  ];
  public reportsInput = ReportInputTypes;

  public PAROptions = [
    // { label: 'Total PAR', value: 0},
    { label: 'PAR > 1', value: '1' },
    // { label: 'PAR > 7', value: '7'},
    { label: 'PAR > 30', value: '30' },
    { label: 'PAR > 60', value: '60' },
    { label: 'PAR > 90', value: '90' },
    { label: 'PAR > 180', value: '180' },
    // { label: 'PAR 180+', value: '180+'}
  ];

  parIcon = 'parAll';

  public formatType: 'currency' | 'percentile' = 'currency';

  parChartData?: any[];
  public parSortingOptions: {
    key: 'name' | 'value';
    sortingDirection: 'asc' | 'desc' | 'default';
  } = {
    key: 'name',
    sortingDirection: 'asc',
  };
  public showSortControl: boolean = false;

  public form: FormGroup;

  public hasCurrencySymbol = true;

  public sortLabel = 'Sort';

  public sortOptions = [
    { label: 'Default', value: 'default' },
    { label: 'Value: Asc.', value: 'value-asc' },
    { label: 'Value: Desc.', value: 'value-desc' },
    { label: 'Name: Asc.', value: 'name-asc' },
    { label: 'Name: Desc.', value: 'name-desc' },
  ];

  public switchPercentValue = 'value';
  public productId = '0';
  public parType = '1';
  public sortingOptions: {
    key: 'name' | 'value';
    sortingDirection: 'asc' | 'desc' | 'default';
  } = {
    key: 'name',
    sortingDirection: 'asc',
  };
  public sort = {
    field: 'branch_name',
    order: 'ASC',
  };

  constructor(
    private topFiltersFormInstance: TopFilterService,
    private service: OperationalIndicatorService,
    private utils: UtilityService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.fetchRecords();
    this.subject.next(Math.random());
  }

  private initForm() {
    this.form = this.fb.group({
      [ReportInputTypes.SORT]: new FormControl(`default`, [
        Validators.required,
      ]),
      [ReportInputTypes.LOAN_PRODUCT]: new FormControl(0, [
        Validators.required,
      ]),
      [ReportInputTypes.PORTFILIO_AT_RISK]: new FormControl('1', [
        Validators.required,
      ]),
    });
  }
  public setHideFilters(val: boolean) {
    this.hideFilters = val;
    if (val) {
      this.form.get(ReportInputTypes.SORT).disable();
      this.form.get(ReportInputTypes.LOAN_PRODUCT).disable();
      this.form.get(ReportInputTypes.PORTFILIO_AT_RISK).disable();
    } else {
      this.form.get(ReportInputTypes.SORT).enable();
      this.form.get(ReportInputTypes.LOAN_PRODUCT).enable();
      this.form.get(ReportInputTypes.PORTFILIO_AT_RISK).enable();
    }
  }

  public get productCtrl() {
    return this.form.get(ReportInputTypes.LOAN_PRODUCT) as FormControl;
  }

  onSort(sortOption: any) {
    this.sortLabel = sortOption.label;
    this.filterPAR({
      value: sortOption.value,
      name: ReportInputTypes.SORT,
      type: ReportInputTypes.SORT,
    });
  }

  switchPARPercentValue(ev: 'value' | 'percentage') {
    this.switchPercentValue = ev;
    this.hasCurrencySymbol = ev === 'value';
    this.parChartData = this.parData.map((item: any) => ({
      name: item.branch_name,
      value: ev === 'value' ? item.parValue : item.parPercentage,
    }));
    this.formatType = ev === 'value' ? 'currency' : 'percentile';
  }

  filterPAR(ev: any) {
    if (ev.type === ReportInputTypes.SORT) {
      const selectedString = ev.value === 'default' ? 'name-default' : ev.value;
      const [key, sortingDirection] = selectedString.split('-');
      this.sortingOptions = { key, sortingDirection };
    }
    if (ev.type === ReportInputTypes.LOAN_PRODUCT) {
      this.productId = ev.value?.toString();
      this.subject.next(Math.random());
    }
    if (ev.type === ReportInputTypes.PORTFILIO_AT_RISK) {
      this.parIcon = !ev.value
        ? 'parAll'
        : ev.value == '180+'
        ? 'par180Plus'
        : `par${ev.value}`;
      this.parType = ev.value.toString();
      this.subject.next(Math.random());
    }
  }

  parData: { branch_name: string; parPercentage: string; parValue: string }[] =
    [];

  fetchRecords() {
    merge(
      this.topFiltersFormInstance.topFilterForm.valueChanges,
      this.subject.asObservable()
    )
      .pipe(
        switchMap(() => {
          this.isLoading = true;
          const { areaId, branchId, loanOfficerId, endDate } =
            this.topFiltersFormInstance.topFilterForm.value;

          const { parType, productId, tenantId } = this;

          const queryParams = new URLSearchParams({
            parType: !parType ? '1' : parType,
            endDate: this.utils.formatDate_custom_yearFirst(endDate),
            ...(tenantId && { tenantId }),
            ...(productId && { productId }),
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
            sortField: this.sort.field,
            sortOrder: this.sort.order,
          });
          return this.service.getParChart(queryParams);
        })
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.parData = res.data.item;
        this.parChartData = res.data.item.map((item: any) => ({
          name: item.branch_name,
          value:
            this.switchPercentValue === 'value'
              ? item.parValue
              : item.parPercentage,
        }));
        if (this.parChartData.length < 2) {
          this.showSortControl = false;
        } else {
          this.showSortControl = true;
        }
      });
  }
}
