<div class="L-tableUI__tableWrapper">
    <div *ngIf="!dataSource?.data" class="h-[55vh] flex justify-center items-center">
        <p class="font-bold">Fetch list of records</p>
    </div>
    <div *ngIf="dataSource?.data && !dataSource.data.length" class="h-[55vh] flex justify-center items-center">
        <p class="font-bold">No Records found</p>
    </div>
    <table #table id="grid" *ngIf="dataSource?.data?.length" mat-table [dataSource]="dataSource" (matSortChange)="announceSortChange($event)" class="L-tableUI__table" matSort [matSortActive]="matSortActive" [matSortDirection]="matSortDirection">
        <ng-container *ngFor="let column of initColumns; let i = index" [matColumnDef]="column.key" [sticky]="column.value === 'checkbox'">
            <!-- kindly check styles for checkbox styling -->
            <ng-container *ngIf="column.type === 'checkbox'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="onCheckAll($event)" [checked]="masterCheckboxChecked" *ngIf="showCheckboxes"></mat-checkbox>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item">
                    <mat-checkbox class="pr-3 rounded-xl" (change)="onCheck($event, item)" [checked]="item.checked || false" *ngIf="showCheckboxes">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- if reversal -->
            <ng-container *ngIf="column.type === 'reversal'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                    mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen | titlecase }}
            <span class="text-danger" *ngIf="item[column.isReversed] === true"
              >(reversed)</span
            >
          </span>
                </td>
            </ng-container>
            <!-- if text -->
            <ng-container *ngIf="column.type === 'text'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                    mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            <!-- <ng-container *ngIf="item && column?.value"> -->
            {{ item[column.value] || "-" | titlecase | truncate: column.maxLen }}
            <!-- </ng-container> -->
            <!-- {{ item[column.value] | truncate: column.maxLen | titlecase }} -->
          </span>
                </td>
            </ng-container>
            <!-- if btn -->
            <ng-container *ngIf="column.type === 'btn'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                    mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="">
            <app-button-img-viewer
              [value]="item[column.value]"
            ></app-button-img-viewer>
          </span>
                </td>
            </ng-container>
            <!-- if email -->
            <ng-container *ngIf="column.type === 'email'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                    mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | truncate : column.maxLen }}
          </span>
                </td>
            </ng-container>
            <!-- if boolean -->
            <ng-container *ngIf="column.type === 'boolean'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                    mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" [ngClass]="column.class" *matCellDef="let item"
                    (click)="triggerRowClick(item)">
                    <span class="" [matTooltip]="item[column.value]">
            {{ item[column.value] | booleanValues }}
          </span>
                </td>
            </ng-container>
            <!-- if object access key in objectVal -->
            <ng-container *ngIf="column.type === 'object'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                    mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="">
            {{ item[column.value] | findValInObject : column.objectVal }}
          </span>
                </td>
            </ng-container>
            <!-- if number, use commas -->
            <ng-container *ngIf="column.type === 'number'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                    mat-header-cell *matHeaderCellDef>
                    <span>
                        {{ column.name }}
                    </span>
                    <span class="ml-[5px]" *ngIf="column.toolIcon" [matTooltip]="column.tooltip || '' " data-toggle="tooltip" data-placement="left">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1245_8661)">
                            <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00888 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#4787F3"/>
                            <path d="M7.99935 6.66675H7.33268C7.15587 6.66675 6.9863 6.73699 6.86128 6.86201C6.73625 6.98703 6.66602 7.1566 6.66602 7.33341C6.66602 7.51023 6.73625 7.67979 6.86128 7.80482C6.9863 7.92984 7.15587 8.00008 7.33268 8.00008H7.99935V12.0001C7.99935 12.1769 8.06959 12.3465 8.19461 12.4715C8.31964 12.5965 8.4892 12.6667 8.66602 12.6667C8.84283 12.6667 9.0124 12.5965 9.13742 12.4715C9.26244 12.3465 9.33268 12.1769 9.33268 12.0001V8.00008C9.33268 7.64646 9.19221 7.30732 8.94216 7.05727C8.69211 6.80722 8.35297 6.66675 7.99935 6.66675Z" fill="#4787F3"/>
                            <path d="M8 5.33325C8.55228 5.33325 9 4.88554 9 4.33325C9 3.78097 8.55228 3.33325 8 3.33325C7.44772 3.33325 7 3.78097 7 4.33325C7 4.88554 7.44772 5.33325 8 5.33325Z" fill="#4787F3"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1245_8661">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="">
            {{ item[column.value] | numberWithComma }}
          </span>
                </td>
            </ng-container>

            <!-- if numberWithDecimal, use commas and two place decimal -->
            <ng-container *ngIf="column.type === 'numberWithDecimal'">
                <th class="floatRight text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
                        {{ column.name }}
                    </span>
                    <span class="ml-[5px]" *ngIf="column.toolIcon" [matTooltip]="column.tooltip || '' " data-toggle="tooltip" data-placement="left">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1245_8661)">
                            <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00888 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.99261 1.46144 6.6994C1.71867 5.40619 2.35361 4.21831 3.28596 3.28596C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76752 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z" fill="#4787F3"/>
                            <path d="M7.99935 6.66675H7.33268C7.15587 6.66675 6.9863 6.73699 6.86128 6.86201C6.73625 6.98703 6.66602 7.1566 6.66602 7.33341C6.66602 7.51023 6.73625 7.67979 6.86128 7.80482C6.9863 7.92984 7.15587 8.00008 7.33268 8.00008H7.99935V12.0001C7.99935 12.1769 8.06959 12.3465 8.19461 12.4715C8.31964 12.5965 8.4892 12.6667 8.66602 12.6667C8.84283 12.6667 9.0124 12.5965 9.13742 12.4715C9.26244 12.3465 9.33268 12.1769 9.33268 12.0001V8.00008C9.33268 7.64646 9.19221 7.30732 8.94216 7.05727C8.69211 6.80722 8.35297 6.66675 7.99935 6.66675Z" fill="#4787F3"/>
                            <path d="M8 5.33325C8.55228 5.33325 9 4.88554 9 4.33325C9 3.78097 8.55228 3.33325 8 3.33325C7.44772 3.33325 7 3.78097 7 4.33325C7 4.88554 7.44772 5.33325 8 5.33325Z" fill="#4787F3"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1245_8661">
                            <rect width="16" height="16" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span>
                </th>
                <td mat-cell class="text-base text-black/70" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)" style="text-align: right">
                    <span [ngClass]="{'text-red-500': item['reversed']}">
            {{ item[column.value] | numberWithCommaAndTwoDecimal }}
          </span>
            </td>
            <td mat-footer-cell *matFooterCellDef style="text-align: right">
                <span>
            {{ column.footer | numberWithCommaAndTwoDecimal }}
          </span>
            </td>
        </ng-container>

            <!-- if id -->
            <ng-container *ngIf="column.type === 'id'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                    mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                    <span class="">
            {{ item[column.value] }}
          </span>
                </td>
            </ng-container>
            <!-- if currency format with symbol -->
            <ng-container *ngIf="column.type === 'currency'">
                <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" class="floatRight" mat-sort-header
                    [disabled]="!column.onSort" mat-header-cell *matHeaderCellDef>
                    <span>
            {{ column.name }}
          </span>
                </th>
                <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)"
                    style="text-align: right">
                    <span class="">
            <ng-container *ngIf="item?.chargeType; else formatCurrency">
              <span *ngIf="item?.chargeType === 'FLAT'; else percentFormat">
                {{ item[column.value] | wz_currency }}
              </span>
                    <ng-template #percentFormat>
                        {{ item[column.value] | wz_percentage_format_2 }}
                    </ng-template>
            </ng-container>

            <ng-template #formatCurrency>
                {{ item[column.value] | wz_currency }}
            </ng-template>
            </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'percentile'">
            <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | wz_percentage_format_2 }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'date'">
            <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | date : "dd-MM-YYYY" }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'dateTime'">
            <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | date : "dd-MM-YYYY h:mm:ss a" }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'dateTimeMeridian'">
            <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | date : "dd-MM-YYYY hh:mm a" }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'customDateSelect'">
            <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                <mat-form-field class="w-full" appearance="outline">
                    <input readonly matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="item[column.value]" />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'phone'">
            <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ item[column.value] | wz_phone_number }}
          </span>
            </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'multiValue'">
            <th class="text-black border-b  !border-b-black" [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" mat-sort-header [disabled]="!column.onSort"
                mat-header-cell *matHeaderCellDef>
                <span>
            {{ column.name }}
          </span>
            </th>
            <td mat-cell class="text-base text-black/70 " [ngClass]="column.border === 'x'?  'border-x border-black/5': column.border === 'l' ? 'border-l border-black/5' : column.border === 'r' ? 'border-r border-black/5': ''" *matCellDef="let item" (click)="triggerRowClick(item)">
                <span class="">
            {{ column.values | mergeMultiValues : item | titlecase }}
          </span>
            </td>
        </ng-container>

        <!-- if more (...) -->
        </ng-container>

        <!-- Header row first group -->
        <ng-container *ngFor="let header of headers" [matColumnDef]="header.colDef">
            <th mat-header-cell [ngClass]="header.value !== '' ? 'border-2 !border-black/5 text-black  !border-b !border-b-black rounded-t-xl': ''" *matHeaderCellDef [style.text-align]="'center'" [attr.colspan]="header.count">
                {{header.value}}
            </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headersCol; sticky: true"></tr>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <ng-container *ngIf="!usePermission">
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </ng-container>
        <ng-container *ngIf="usePermission">
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.hidden]="!hasPermission(row.perms)"></tr>
        </ng-container>
    </table>
</div>
<app-pagination-ui (togglePageControlEmit)="onPageCtrlToggle($event)" (pageSizeToggleEmit)="onPageSizeToggle($event)" [showPagination]="showPagination" [pageProp]="pageProp"></app-pagination-ui>