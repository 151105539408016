import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unhandled-transfer-ui',
  templateUrl: './unhandled-transfer-ui.component.html',
  styleUrls: ['./unhandled-transfer-ui.component.css']
})
export class UnhandledTransferUiComponent implements OnInit {

  public title: string;
  public listTitle: string;
  public message: string = $localize`:wd_yu_le_to_re_id_ad_pd_wh_vd_ts:Would you like to remove invalid transfers and proceed with valid transfers?`;
  public unhandledList: {name,id}[] = [];
  public showOk = false;

  constructor(
    private dialogRef: MatDialogRef<UnhandledTransferUiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if(this.data?.title) this.title =  this.data.title;
    if(this.data?.unhandledList) this.unhandledList =  this.data.unhandledList;
    if(this.data?.message === '') this.message =  '';
    if(this.data?.message) this.message =  this.data.message;
    if(this.data?.showOk) this.showOk =  !!this.data.showOk;
  }

  cancel() {
    this.dialogRef.close()
  }
  
  removeAll() {
    this.dialogRef.close(true);
  }

}
