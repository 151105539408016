import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, merge } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { OperationalIndicatorService } from 'src/app/pages/dashboard/services/operational-indicator.service';
import { TopFilterService } from 'src/app/pages/dashboard/services/topFIlter.service';
import {
  ChartFilterControlsInterface,
  ReportInputTypes,
} from 'src/app/shared/models/common';
import { CancelHttpRequestService } from 'src/app/shared/services/cancel-request.service';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-running-loans',
  templateUrl: './running-loans.component.html',
  styleUrls: ['./running-loans.component.css'],
})
export class RunningLoansComponent implements OnInit {
  public topFiltersFormInstance: FormGroup;
  public productId: any;
  public total: number = 0;
  public tenantId = JSON.parse(localStorage.getItem('pf') || null).tenantId;
  public numberOfTenantBranches: number = 0;

  public hideFilters: boolean = false;
  public showSortControl: boolean = false;
  public isLoading: boolean = false;
  public runningLoansPalette: string[] = [
    '#4d5d76',
    '#93A1B1',
    '#C0C7D1',
    '#E5E8EC',
  ];

  public reportsInput = ReportInputTypes;

  public sortLabel = 'Sort';

  public sortOptions = [
    { label: 'Default', value: 'default'},
    { label: 'Value: Asc.', value: 'value-asc'},
    { label: 'Value: Desc.', value: 'value-desc'},
    { label: 'Name: Asc.', value: 'name-asc'},
    { label: 'Name: Desc.', value: 'name-desc'},
  ]

  public runningLoansForm: FormGroup;
  public runningLoansFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    {
      name: ReportInputTypes.LOAN_PRODUCT,
      type: 'LOAN_PRODUCT',
      position: 'right',
    },
  ];
  public runningLoansChartData?: { name: string; value: number }[] = [];
  public runningLoansSortingOptions: {
    key: 'name' | 'value';
    sortingDirection: 'asc' | 'desc' | 'default';
  } = {
    key: 'name',
    sortingDirection: 'asc',
  };
  public subject = new Subject<number>();

  public form:FormGroup

  constructor(
    private operationalIndicatorService: OperationalIndicatorService,
    private topFilterService: TopFilterService,
    private utils: UtilityService,
    private fb: FormBuilder,
    private cancelRequestService: CancelHttpRequestService
  ) {
    this.topFiltersFormInstance = this.topFilterService.topFilterForm;
  }

  ngOnInit(): void {
    this.initForm()
    this.getRunningLoansChartData();
    this.subject.next(Math.random());
  }

  private initForm() {
    this.form = this.fb.group({
      [ReportInputTypes.SORT]: new FormControl(`default`, [Validators.required]),
      [ReportInputTypes.LOAN_PRODUCT]: new FormControl(0, [Validators.required]),
    });
  }

  public get productCtrl(){
    return this.form.get(ReportInputTypes.LOAN_PRODUCT) as FormControl
  }

  getRunningLoansChartData() {
    merge(
      this.topFilterService.topFilterForm.valueChanges,
      this.subject.asObservable()
    )
      .pipe(
        switchMap(() => {
          this.isLoading = true;
          const { areaId, branchId, loanOfficerId, endDate } =
            this.topFiltersFormInstance.value;
          const { productId, tenantId } = this;

          const queryParams = new URLSearchParams({
            endDate: this.utils.formatDate_custom_yearFirst(endDate),
            ...(tenantId && { tenantId }),
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
            ...(productId && { productId }),
          });

          return this.operationalIndicatorService.getRunningLoans(queryParams);
        })
      )
      .subscribe({
       
        next: (res) => {
          this.total = res.data.total[0].grandTotal;
          this.runningLoansChartData = res.data.data.map((item: any) => ({
            name: item.branchName,
            value: item.totalAmount,
          }));

          this.isLoading = false;

          this.numberOfTenantBranches =
            res.data.total[0].numberOfBranchesForTenant;
          if (this.runningLoansChartData.length < 2) {
            this.showSortControl = false;
          } else {
            this.showSortControl = true;
          }
        },
      });
  }

  onSort(sortOption: any) {
    this.sortLabel = sortOption.label
    this.filterRunningLoans({value: sortOption.value, name: ReportInputTypes.SORT, type: ReportInputTypes.SORT })
  }

  filterRunningLoans(ev: any) {
    switch (ev.type) {
      case ReportInputTypes.SORT:
        const selectedString =
          ev.value === 'default' ? 'name-default' : ev.value;
        const [key, sortingDirection] = selectedString.split('-');
        this.runningLoansSortingOptions = { key, sortingDirection };
        break;
      case ReportInputTypes.LOAN_PRODUCT:

        this.productId = ev.value;
        this.subject.next(Math.random());
        break;
      }
  }
}
