import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilityService } from './utility.service';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Message } from '@stomp/stompjs';

@Injectable({
  providedIn: 'root'
})

export class WebSocketService {

  ticketSubscription: Subscription
  dataSubscription: Subscription

  constructor(
    // private rxStompService: RxStompService,
    private utilsService: UtilityService
  ) { }

  // fetchWebSocketData() {
  //   this.rxStompService.activate()

  //   let token = localStorage['token']
  //   // this.rxStompService.publish({ destination: `/app/tickets/${token}`, body: 'get' });
  //   this.rxStompService.publish({ destination: `/app/data/${token}`, body: 'get' });


  //   this.ticketSubscription =
  //       // this.rxStompService.watch(`/tickets/${user.id}`).subscribe(message => {
  //       //     const supportDetails = JSON.parse(message.body);
  //       //     this.utilsService.setTicketData(supportDetails);
  //       // });

  //       this.dataSubscription =
  //       this.rxStompService.watch(`support/data/`).subscribe((message: Message) => {
  //           let notifications
  //           let data;
  //           data = JSON.parse(message.body)
  //           if (data.objectType == 'ticket') {
  //               this.utilsService.setCurrentTicketChange(data.data);
  //           }
  //           if (data.objectType == 'tickets') {
  //               this.utilsService.setTicketData(data.data);
  //           }
  //           if (data.objectType == 'notifications') {
  //               notifications = data.data
  //               this.utilsService.setNotificationData(notifications);
  //               this.utilsService.setCurrentNotificationLength(notifications.notifications.length);
  //               if (notifications.notifications.length > 0 && notifications.newNotification) {
  //                   let audio = new Audio('assets/audio/sound.mp3');
  //                   audio.muted
  //                   audio.play();
  //               }
  //           }

  //       });
  // }

  ngOnDestroy() { }

}
