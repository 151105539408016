import { Directive, ElementRef, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NotificationService } from '../notification.service';

@Directive({
  selector: '[appDecimalNumbers]',
})
export class DecimalNumbersDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor(
    @Self() private ngControl: NgControl,
    private elementRef: ElementRef,
    private notification: NotificationService
  ) {}

  // Allows only digits
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) return;

    const inputValue = this.elementRef.nativeElement.value.concat(event.key);
    if (inputValue[0].match(/[\D]/) || inputValue[0].match(/[0]/))
      event.preventDefault();
    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }
    return;
  }

  // Copy and Paste Action
  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData(
      'text/plain'
    );
    if (clipboardData && !this.regex.test(clipboardData)) {
      event.preventDefault();
    }
    return;
  }

  @HostListener('blur', ['$event']) onBlur(event: KeyboardEvent) {
    if (typeof this.ngControl.value === 'number') return;
    const value = this.ngControl.value
      ? this.ngControl.value.replace(/[^0-9\.]/g, '').trim()
      : null;
    if (value && isNaN(Number(value))) {
      this.ngControl.reset(null);
      this.notification.error('Invalid amount value');
    }
    // if ((value && isNaN(Number(value))) || (!value && !isNaN(Number(value)))) {
    //   this.ngControl.reset(null);
    //   this.notification.error('Invalid amount value')
    // }
  }
}
