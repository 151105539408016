<div *ngIf="showPagination" class="flex justify-between items-center flex-wrap bg-white text-black font-light p-2 px-6">
    <div class="flex items-center">
        <span>Rows per page</span>
        <select (change)="pageSizeToggle($event.target)" class="p-0 px-2 ml-2 border rounded-2xl" name="" id="" [value]="page?.pageSize">
      <option *ngFor="let count of pageSizeCounts">
        {{ count }}
      </option>
    </select>
    </div>
    <div class="ml-auto mr-2 flex justify-center items-center">
        <!-- <span>{{ page?.from || 0 }} to {{ page?.to || 0 }} of {{ page?.totalRecords || 0 }}</span> -->
        <div class="ml-2 flex gap-x-2 items-center">
            <button (click)="togglePageControl('first')" class="rounded-xl p-1.5 min-w-[30px] border border-primary hover:bg-primary/10 text-black" [disabled]="pageControls.first" [ngClass]="pageControls.first ? '!opacity-30' : ''">
        <!-- <mat-icon
          
          class=" text-[20px]"
          [ngClass]="pageControls.first ? '!cursor-not-allowed' : ''"
          
        >skip_previous</mat-icon> -->
        <span nz-icon class="text-lg align-middle" nzType="vertical-right" nzTheme="outline"></span>
      </button>
            <button (click)="togglePageControl('prev')" class="rounded-xl p-1.5 min-w-[30px] border border-primary hover:bg-primary/10 text-black" [disabled]="pageControls.prev" [ngClass]="
          pageControls.prev ? '!opacity-30' : ''
        ">
        <span nz-icon class="text-lg align-middle" nzType="left" nzTheme="outline"></span>

      </button>
            <span class="flex gap-x-2">
        <span class="text-xl" *ngIf="page?.pageNumber - 2 > 0">...</span>
            <!-- <span> Page {{ page?.pageNumber || 0 }} of {{ page?.totalPages }} </span> -->
            <button (click)="togglePageControl('prev')" *ngIf="page?.pageNumber - 1 > 0" class="rounded-xl p-1.5 min-w-[30px] border border-primary hover:bg-primary/10 text-black">
          <app-loader *ngIf="!page?.pageNumber"></app-loader>
          <span *ngIf="page?.pageNumber">{{ page.pageNumber - 1 | numberWithComma }}</span>
        </button>
            <button class="rounded-xl p-1.5 min-w-[30px] border bg-primary text-white">
          <app-loader *ngIf="!page?.pageNumber" color="success"></app-loader>
          <span *ngIf="page?.pageNumber">{{ page.pageNumber | numberWithComma }}</span>
        </button>
            <button *ngIf="page?.pageNumber + 1 < page?.totalPages" (click)="togglePageControl('next')" class="rounded-xl p-1.5 min-w-[30px] border border-primary hover:bg-primary/10 text-black">
          <app-loader *ngIf="!page?.pageNumber"></app-loader>
          <span *ngIf="page?.pageNumber">{{ page.pageNumber + 1 | numberWithComma }}</span>
        </button>
            <span class="text-xl" *ngIf="page?.pageNumber + 2 < page?.totalPages">...</span
        >
        <button
          *ngIf="page?.pageNumber !== page?.totalPages"
          (click)="togglePageControl('last')"
          class="rounded-xl p-1.5 min-w-[30px] border border-primary hover:bg-primary/10 text-black"
        >
          {{ page?.totalPages | numberWithComma }}
        </button>
      </span>
            <button (click)="togglePageControl('next')" class="rounded-xl p-1.5 min-w-[30px] border border-primary hover:bg-primary/10 text-black" [disabled]="pageControls.next" [ngClass]="pageControls.next ? '!opacity-30' : ''">
              <span nz-icon class="text-lg align-middle" nzType="right" nzTheme="outline"></span>

      </button>
            <button (click)="togglePageControl('last')" class="rounded-xl p-1.5 min-w-[30px] border border-primary hover:bg-primary/10 text-black" [disabled]="pageControls.last" [ngClass]="pageControls.last ? '!opacity-30' : ''">
       <span nz-icon class="text-lg align-middle" nzType="vertical-left" nzTheme="outline"></span>
      </button>
        </div>
    </div>
</div>