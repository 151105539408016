import { Directive, ElementRef, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NotificationService } from '../notification.service';

@Directive({
  selector: '[appPercent]'
})
export class PercentDirective {
  @Input() symbol: string = '%'

  // Allow decimal numbers and negative values
  // private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private regex: RegExp = new RegExp(/^(\d{1,2}(\.\d+)?|100(\.0+)?)$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(
    @Self() private ngControl: NgControl,
    private elementRef: ElementRef<HTMLInputElement>,
    private notification: NotificationService) {}

  // Allows only digits 
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if(this.specialKeys.includes(event.key)) return

    let inputValue = this.elementRef.nativeElement.value.concat(event.key)
    inputValue = inputValue.replace('%', '')

    // Prevent zero as initial input
    if(inputValue[0].match(/[\D]/) || inputValue[0].match(/[0]/)) event.preventDefault()

    if(Number(inputValue?.trim()) > 100) {
      this.notification.error('You can not enter a value greater than 100')
      event.preventDefault()
    }

    if(inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault()
    }
    return
  }

  @HostListener('blur', ['$event']) onBlur(event: KeyboardEvent) {
    const value = this.ngControl.value ? this.ngControl.value.replace(/[^0-9\.]/g, '').trim() : null
    if ((value && isNaN(Number(value))) ) {
      this.ngControl.reset(null);
      this.notification.error('Invalid amount value')
    }
     
  }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    let current: string = this.elementRef.nativeElement.value;
    if(current.includes('%')) {
      current =  current.replaceAll('%', '')
    }
    current = this.formatPercent(current);
    this.elementRef.nativeElement.value = current;
    const len = this.elementRef.nativeElement.value.length
    this.elementRef.nativeElement.setSelectionRange(len-1, len-1)   
  }

  formatPercent(amount) {
    return amount ? `${amount}${this.symbol}`: ``
  }

}
