<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed' : ''">
    <div class="logo-container">
        <button *ngIf="!collapsed" class="logo -ml-16" (click)="toggleCollapse()">
      <i class="fas fa-arrow-right close-icon"></i>
    </button>
        <img *ngIf="collapsed" src="assets/images/new-images/logo-2.png" class="text-center header-image" routerLink="/dashboard" />
        <button class="btn-close" *ngIf="collapsed" (click)="closeNav()">
      <i class="fas fa-times close-icon"></i>
    </button>
    </div>

    <ul class="sidenav-nav">
        <li class="sidenav-nav-item flex items-center gap-3" *ngFor="let menu of menus">
            <ng-container *hasPermissions="menu.perm">
                <div class="w-[10px]">
                    <svg class="text-primary" *ngIf="menu.active && collapsed" width="10" height="40" viewBox="0 0 10 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 0H3C6.86599 0 10 3.13401 10 7V43C10 46.866 6.86599 50 3 50H0V0Z"
              fill="currentcolor"
            />
          </svg>
                </div>
                <a *ngIf="menu.link !== 'logout'" href="" [routerLink]="menu.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="
            menu.active
              ? '!bg-primary !text-white active'
              : 'bg-white text-primary'
          " class="flex items-center gap-[5px] w-[100%] sidenav-nav-link">
                    <!-- <img [matTooltip]="menu.label" [matTooltipClass]="'bg-dark_white !text-primary text-lg p-3 pl-10 px-8 !-mt-[2.3em] !ml-[4em]'" class="sidenav-link-icon" [ngClass]="menu.active ? 'filter-white' : ''" [src]="menu.icon" alt=""> -->
                    <!-- <span [matTooltip]="menu.label" [matTooltipClass]="'bg-dark_white !text-primary text-lg p-3 pl-10 px-8 !-mt-[2.3em] !ml-[4em]'" class="sidenav-link-icon" [innerHTML]="renderIcon(menu.icon)"></span> -->
                    <mat-icon [matTooltip]="menu.label" [matTooltipClass]="
              'bg-dark_white !text-primary p-3 pl-10 px-8 !-mt-[2.3em] !ml-[4em]'
            " class="sidenav-link-icon" [svgIcon]="menu.icon"></mat-icon>

                    <div class="sidenav-link-text sidebar-text" [ngClass]="!collapsed ? 'hidden' : ''">
                        {{ menu.label }}
                    </div>
                </a>
                <a *ngIf="menu.link === 'logout'" (click)="logout()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [ngClass]="
            menu.active
              ? '!bg-primary !text-white active'
              : 'bg-white text-primary'
          " class="flex items-center gap-[5px] w-[100%] sidenav-nav-link">
                    <!-- <img [matTooltip]="menu.label" [matTooltipClass]="'bg-dark_white !text-primary text-lg p-3 pl-10 px-8 !-mt-[2.3em] !ml-[4em]'" class="sidenav-link-icon" [ngClass]="menu.active ? 'filter-white' : ''" [src]="menu.icon" alt=""> -->
                    <!-- <span [matTooltip]="menu.label" [matTooltipClass]="'bg-dark_white !text-primary text-lg p-3 pl-10 px-8 !-mt-[2.3em] !ml-[4em]'" class="sidenav-link-icon" [innerHTML]="renderIcon(menu.icon)"></span> -->
                    <mat-icon [matTooltip]="menu.label" [matTooltipClass]="
              'bg-dark_white !text-primary p-3 pl-10 px-8 !-mt-[2.3em] !ml-[4em]'
            " class="sidenav-link-icon" [svgIcon]="menu.icon"></mat-icon>

                    <div class="sidenav-link-text sidebar-text" [ngClass]="!collapsed ? 'hidden' : ''">
                        {{ menu.label }}
                    </div>
                </a>
            </ng-container>
        </li>
    </ul>
</div>