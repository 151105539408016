import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoanProductIndexedDBService } from 'src/app/indexDB/loanProduct.service';
import { LoanProductService } from 'src/app/pages/settings/services/loan-product.service';

@Component({
  selector: 'app-loan-product-search-select',
  templateUrl: './loan-product-search-select.component.html',
  styleUrls: ['./loan-product-search-select.component.css']
})
export class LoanProductSearchSelectComponent implements OnInit, OnDestroy {

  @Input() showDefaultListValue:boolean = true;
  @Input() defaultSelected:any;
  @Input() control: FormControl | string;
  @Input() disabled: boolean = false;
  @Input() type: 'client' | 'server' = 'client';
  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();

  public productLabel: string = $localize`:@@product:Product`;
  public loanProductData: { id: number, name: string }[] = [];
  public endpoint: string = '';
  public storedArrayString = localStorage.getItem('indexDBFetched');
  public storedArray = this.storedArrayString ? JSON.parse(this.storedArrayString) : [];
  private subscription: Subscription;
  
  constructor(
    private loanProductIDBService: LoanProductIndexedDBService,
    private loanProductService: LoanProductService
  ) { }

  ngOnInit(): void {
    this.getAllLoanProducts();
    this.endpoint = this.loanProductService.searchLoanProductsEndpoint;
    if (!this.storedArray.includes('loanProduct')) {
      this.type = 'server';
    }
  }

  emitSelected(value:{id:any, name:string}) {
    this.emitSelectedVal.emit(value);
  }

  getAllLoanProducts(){
    if (this.subscription) {
      // Unsubscribe from the previous subscription
      this.subscription.unsubscribe();
    }
    this.subscription = this.loanProductIDBService.getStoredData().subscribe({
      next: (loanProduct) => { 
        this.loanProductData = loanProduct;

        // Check if the loanProductData is empty after fetching
        if (loanProduct.length === 0) {
          // It's still empty, trigger a refetch
          this.getAllLoanProducts();
        }
       },
      error: (error) => { console.error('Error retrieving loan Products:', error); }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
