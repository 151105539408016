import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.css']
})
export class ReasonModalComponent implements OnInit {
  public title = "Disable"
  public inputTitle = "Reason"

  public proceedVal = {
    name: "Disable",
    class: "p-3 rounded-xl text-white bg-error/90 hover:bg-error w-[150px]"
  }
  public cancelVal = {name:"Cancel",
  class: "p-3 rounded-xl border border-error opacity-80 hover:opacity-100 text-error w-[150px]"
}
  public maxLength:number


  public form: FormGroup;


  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ReasonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public initForm(){
    this.form = this.fb.group({
      reason: new FormControl("", Validators.required)
    })
  }

  public cancel(){
    this.dialogRef.close();
  }

  public onSubmit(){
    if(!this.form.valid) return
    this.dialogRef.close(this.form.value)
  }

  ngOnInit(): void {
    // refer to instantiation(default value) to determine its data type
    if(this.data && this.data.title) this.title =  this.data.title;
    if(this.data && this.data.inputTitle) this.inputTitle =  this.data.inputTitle;
    if(this.data && this.data.proceedVal) this.proceedVal =  this.data.proceedVal;
    if(this.data && this.data.cancelVal) this.cancelVal =  this.data.cancelVal;
    if(this.data && this.data.maxLength) this.maxLength =  this.data.maxLength;




    this.initForm()

    if(this.data && this.data.value){
      this.form.get("reason")?.setValue(this.data.value)
    }
    if(this.data && this.data.disabled){
      this.form.get("reason")?.disable()
    }
    
    
  }
}
