import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-audits',
  templateUrl: './account-audits.component.html',
  styleUrls: ['./account-audits.component.css']
})
export class AccountAuditsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
