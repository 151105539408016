import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CancelLoanPayload, TogglePayload } from 'src/app/shared/models/common';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../client-management/services/client.service';

@Injectable({
  providedIn: 'root'
})
export class LoanService {
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient, private utilityService: UtilityService, private clientService: ClientService) { }

  public getLoanAccounts(queryParams:any): Observable<any> {
    const payload = this.utilityService.objToQueryParams(queryParams);
    return this.http.get(this.baseUrl + `accounts/loan-accounts${payload}`);
  }
  public getLoanAccount(id:number): Observable<any> {
    return this.http.get(this.baseUrl + `accounts/loan-accounts/${id}`);
  }
  
  public getLoanAccountContract(id:number, module): Observable<any> {
    return this.http.get(this.baseUrl + `accounts/loan-accounts/${id}/contract/${module}`);
  }
  public getLoanAccountSignedContract(id:number): Observable<any> {
    return this.http.get(this.baseUrl + `accounts/loan-accounts/${id}/contracts`);
  }
  public approveCancellation(payload: CancelLoanPayload): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'accounts/loan-accounts/cancel', payload);
  }

  public rejectCancellation(payload: CancelLoanPayload): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'accounts/loan-accounts/reject-cancellation-request', payload);
  }
  public cancellationRequest(payload: CancelLoanPayload): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'accounts/loan-accounts/cancellation-request', payload);
  }

  public reverseLoanTransaction(payload: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'accounts/loan-accounts/reverse', payload);
  }
  public writeOff(payload: CancelLoanPayload): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'accounts/loan-accounts/write-off', payload);
  }
  public waiveInterest(payload: TogglePayload): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'accounts/loan-accounts/waive-interest', payload);
  }
  public toggle(data: TogglePayload): Observable<any> {
    // this.currentTableRequest = req;
    const state = data.state === 'enabled' ? 'APPROVED': data.state.toUpperCase()
    const payload = {...data, state};
    return this.http.put<any>(this.baseUrl + 'accounts/loan-accounts/toggle', payload);
  }

  public disburse(ids:any, payload: any): Observable<any> {
    
    return this.http.post<any>(this.baseUrl + `accounts/loan-accounts/${ids}/disburse`, payload);
  }
  public edit(payload: any): Observable<any> {
    
    return this.http.put<any>(this.baseUrl + `accounts/loan-accounts/edit`, payload);
  }
  public expectedRepayment(id:number, date: string): Observable<any> {
    
    return this.http.get<any>(this.baseUrl + `accounts/loan-accounts/expected-repayment?accountId=${id}&date=${date}`);
  }
  public makeRepayment(id:number, payload: any): Observable<any> {
    
    return this.http.post<any>(this.baseUrl + `accounts/loan-accounts/${id}/repayment-approval`,payload);
  }
  public approve(data: TogglePayload): Observable<any> {
    // this.currentTableRequest = req;
    const payload = {...data};
    return this.http.put<any>(this.baseUrl + 'accounts/loan-accounts/approve', payload);
  }
  public getClientLoanAccounts(id:number, queryParams:any): Observable<any> {
    const payload = this.utilityService.objToQueryParams(queryParams);
    return this.http.get(this.baseUrl + `accounts/loan-accounts/clients/${id}${payload}`).pipe(
      tap((res) => {
        if (!res.successful) return;
        this.clientService.setClientAccountCount('loans', res.data.length);
      })
    );;
  }


  public getGroupLoanAccounts(id:number, req:any): Observable<any> {
    return this.http.get(this.baseUrl + `accounts/loan-accounts/group/${id}?${req}`);
  }
  
  public getGuarantorLoanAccounts(id:number, req:any): Observable<any> {
    return this.http.get(this.baseUrl + `accounts/loan-accounts/guarantor/${id}?${req}`);
  }

  public createLoanAccount(payload: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'accounts/loan-accounts', payload);
  }

  public getRepaymentApproval(req:any): Observable<any> {
    return this.http.get(this.baseUrl + `accounts/loan-accounts/repayment-approvals?${req}`);
  }

  public approveRepayment(payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}accounts/loan-accounts/repayments`, payload);
  }

  public rejectRepayment(payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}accounts/loan-accounts/reject-repayments`, payload);
  }

  public waivePenalty(id:number, payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}accounts/charges/wavePenalty/${id}`, payload);
  }

  public addDocument(newDocumentForm: Object) {
    return this.http.post<any>(this.baseUrl + 'accounts/loan-accounts/addDocument', newDocumentForm);
  }

  public saveContract(id:number, contractHtml:string, contractRef: string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}accounts/loan-accounts/${id}/set-contract`, {
      contractHtml,
      contractRef
    });
  }

  public getLoanAudit(params: any): Observable<any> {
    return this.http.get(`${this.baseUrl}accounts/audits`, { params } );
  }

}
