<div [ngClass]="{
    'grid grid-cols-[36%_minmax(64%,_1fr)_100%]': legends.length > 0
  }" class="filter-wrapper">
    <div class="legends max-w-[10rem]" *ngIf="legends.length > 0">
        <p *ngFor="let legend of legends; let i = index" class="flex items-center gap-[3px] text-[11px]">
            <span class="indicator" [ngClass]="{ 'approved': i === 0, 'active': i === 1, 'pending': i === 2 }"></span> {{ legend.label }}:
            <span class="text-end flex-1">{{ !legend.total ? '-' : (legend.total | number) }}</span>
        </p>
    </div>

    <form [formGroup]="form" class="flex justify-between" [ngClass]="{'flex-1': legends.length > 0}">
        <!-- <div class="flex justify-between mb-3 gap-2"> -->
        <div class="flex w-[45%] gap-1">
            <ng-container *ngFor="let control of formControls">
                <ng-container [ngSwitch]="control.type">
                    <mat-form-field class="w-[45%]" appearance="outline" *ngSwitchCase="'START_DATE'">
                        <mat-label i18n="@@start_date">Start Date</mat-label>
                        <input [formControlName]="control.name" name="startDate" [min]="startDateRange.min" [max]="startDateRange.max" readonly matInput [matDatepicker]="startDate" placeholder="Start Date" i18n-placeholder="@@start_date" />
                        <mat-datepicker-toggle matSuffix [for]="startDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="w-[45%]" appearance="outline" *ngSwitchCase="'END_DATE'">
                        <mat-label i18n="@@end_date">End Date</mat-label>
                        <input [formControlName]="control.name" name="endDate" [min]="endDateRange.min" [max]="endDateRange.max" readonly matInput [matDatepicker]="endDate" placeholder="End Date" i18n-placeholder="@@end_date" />
                        <mat-datepicker-toggle matSuffix [for]="endDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                    </mat-form-field>
                    <ng-container *ngIf="showSortControl">
                        <button mat-button [matMenuTriggerFor]="sorting" class="sort-btn" *ngSwitchCase="'SORT'">
                            {{ sortLabel }}
                            <img class="ml-2.5 h-5" src="assets/icons/sort-order.svg" alt="" />
                          </button>
                        <mat-menu #sorting="matMenu">
                            <button mat-menu-item *ngFor="let option of sortOptions" (click)="onSort(option)">
                              {{ option.label }}
                            </button>
                        </mat-menu>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>


        <div class="flex w-[45%] gap-1 ">
            <ng-container *ngFor="let control of formControls">
                <ng-container [ngSwitch]="control.type">

                    <div class=" w-full">
                        <mat-form-field class="w-full" appearance="outline" *ngSwitchCase="'CLIENT_STATUS'">
                            <mat-label i18n="@@client_status">Client Status</mat-label>
                            <mat-select [formControlName]="control.name">
                                <mat-option *ngFor="let option of clientStatus" [value]="option.value">{{ option?.label }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <app-client-search-select class="w-full" *ngSwitchCase="'CLIENT'" [branchId]="branchId" [areaId]="areaId" [control]="getControl(control.name)" type="client">
                        </app-client-search-select>

                        <app-loan-product-search-select class="w-full" *ngSwitchCase="'LOAN_PRODUCT'" [control]="getControl(control.name)" type="client">
                        </app-loan-product-search-select>

                        <app-deposit-product-search-select class="w-full" *ngSwitchCase="'DEPOSIT_PRODUCT'" [control]="getControl(control.name)" type="client">
                        </app-deposit-product-search-select>

                        <mat-form-field class="w-full" appearance="outline" *ngSwitchCase="'PORTFILIO_AT_RISK'">
                            <mat-label i18n="@@par">PAR</mat-label>
                            <mat-select [formControlName]="control.name">
                                <mat-option *ngFor="let option of PAROptions" [value]="option.value">{{ option?.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
            </ng-container>
        </div>


        <!-- </div> -->
    </form>
</div>