import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-activity-clients',
  templateUrl: './user-activity-clients.component.html',
  styleUrls: ['./user-activity-clients.component.css']
})
export class UserActivityClientsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  filterClients(ev: any) {
  }

}
