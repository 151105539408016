<div class="row p-2">
    <!-- First Column -->
    <div class="col-lg-4 p-3">
        <div class="flex justify-content-between align-items-base">
            <p><strong>Product</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Rate</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Compounding</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Posing</strong></p>
            <p> Group Savings </p>
        </div>
    </div>

    <!-- Second Column -->
    <div class="col-lg-4 p-3 seperator">
        <div class="flex justify-content-between align-items-base">
            <p><strong>Product</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Rate</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Compounding</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Posing</strong></p>
            <p> Group Savings </p>
        </div>
    </div>

    <!-- Third Column -->
    <div class="col-lg-4 p-3">
        <div class="flex justify-content-between align-items-base">
            <p><strong>Product</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Rate</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Compounding</strong></p>
            <p> Group Savings </p>
        </div>

        <div class="flex justify-content-between align-items-base">
            <p><strong>Interest Posing</strong></p>
            <p> Group Savings </p>
        </div>
    </div>
</div>