import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-header-ui',
  templateUrl: './modal-header-ui.component.html',
  styleUrls: ['./modal-header-ui.component.css']
})
export class ModalHeaderUiComponent {

  @Input() subtitle 
  @Input() title:string = ''; 
  @Input() showCloseBtn: boolean = true; 
  @Output() closeModal: EventEmitter<null> = new EventEmitter()

  constructor() { }

  dismiss() {
    this.closeModal.emit()
  }

}
