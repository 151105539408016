import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-icon2-ui',
  templateUrl: './button-icon2-ui.component.html',
  styleUrls: ['./button-icon2-ui.component.css']
})
export class ButtonIcon2UiComponent {

  @Input() name?: string;
  @Input() isDisabled: boolean = false;
  @Input() value: any;
  @Input() buttonType: 'button' | 'menu' | 'reset' | 'submit' = 'submit'
  @Input() classNames: string;
  @Input() tooltip: string;
  @Input() iconClassNames: string = ''
  // angular feather icons no longer supported
  @Input() icon?: string;
  @Output() clickEmit: EventEmitter<any> = new EventEmitter();
  

  constructor() {}

  public trigger(val:any) {
    this.clickEmit.emit(val);
  }

}
