<app-chart-wrapper [isLoading]="isLoading" [title]="'Portfolio-at-Risk'" [showValuePercentToggle]="true" (switch)="switchPARPercentValue($event)" (fullscreen)="setHideFilters($event)">

    <app-no-data-to-show chart *ngIf="!parChartData?.length && !isLoading"></app-no-data-to-show>

    <!-- <app-chart-filter [showSortControl]="showSortControl" filter [form]="portfolioForm" [formControls]="portfolioFormControls" *ngIf="!hideFilters" (filter)="filterPAR($event)"></app-chart-filter> -->

    <!-- Filters -->
    <form filter [formGroup]="form" class="flex gap-1 mb-4" [ngClass]="showSortControl?'justify-between':'justify-end'">



        <ng-container *ngIf="showSortControl">
            <button mat-button [matMenuTriggerFor]="sorting" class="sort-btn">
                {{ sortLabel }}
                <img class="ml-2.5 h-5" src="assets/icons/sort-order.svg" alt="" />
              </button>
            <mat-menu #sorting="matMenu">
                <button mat-menu-item *ngFor="let option of sortOptions" (click)="onSort(option)">
                  {{ option.label }}
                </button>
            </mat-menu>
        </ng-container>

        <div class="flex justify-between w-[60%]">

            <app-loan-product-search-select class="w-[48%]" (emitSelectedVal)="filterPAR({ type: reportsInput.LOAN_PRODUCT, value: $event?.id })" [control]="productCtrl" type="client">
            </app-loan-product-search-select>

            <mat-form-field class="w-[48%]" appearance="outline">
                <mat-label i18n="@@par">PAR</mat-label>
                <mat-select (selectionChange)="filterPAR({ type: reportsInput.PORTFILIO_AT_RISK, value: $event?.value })" [formControlName]="reportsInput.PORTFILIO_AT_RISK" >
                    <mat-option *ngFor="let option of PAROptions" [value]="option.value">{{ option?.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </form>
    <!-- End Filters -->


    <app-circle-chart chart *ngIf="parChartData?.length === 1 && !isLoading" [width]="'80px'" [height]="'80px'" [totalAmount]="parChartData[0]?.value ?? 0" [imageName]="parIcon" [type]="formatType" [classNames]="'text-primary'">
    </app-circle-chart>
    <app-column-chart *ngIf="parChartData?.length > 1 && !isLoading" chart [chartData]="parChartData" [hasCurrencySymbol]="hasCurrencySymbol" [sortingOptions]="sortingOptions"></app-column-chart>
</app-chart-wrapper>