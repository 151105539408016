import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IdNameObject } from 'src/app/auth/models/idnameobject';
import { PageRequest } from 'src/app/shared/models/common';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  private accountingUrl: string = environment.baseUrl + environment.accountingUrl;
  private filteredGlAccounts = new BehaviorSubject<IdNameObject[]>([]);
  private transactionTypes = [
    { id: 'DISBURSEMENT', name: $localize`:@@disbursement:Disbursement`  },
    { id: 'REPAYMENT', name:  $localize`:@@repayment:Repayment` },
    { id: 'REVERSAL', name:  $localize`:@@reversal:Reversal` },
    { id: 'CHARGE_APPLIED', name: $localize`:@@charge_applied:Charge Applied` },
    { id: 'CHARGE_PAID', name: $localize`:@@charge_paid:Charge Paid` },
    { id: 'INTEREST_APPLIED', name: $localize`:@@interest_applied:Interest Applied` },
    { id: 'INTEREST_PAID', name: $localize`:@@interest_paid:Interest Paid` },
    { id: 'PENALTY_PAID', name: $localize`:@@penalty_paid:Penalty Paid` },
    { id: 'DEPOSIT', name: $localize`:@@deposit:Deposit` },
    { id: 'WITHDRAWAL', name: $localize`:@@withdrawal:Withdrawal` },
    { id: 'REFUND', name: $localize`:@@refund:Refund` },
    { id: 'OVERDRAFT_INTEREST', name: $localize`:@@overdraft_interest:Overdraft Interest` },
    { id: 'APPROVE_TRANSFER', name: $localize`:@@approve_transfer:Approve Transfer` },
    { id: 'APPROVED_TRANSFER', name: $localize`:@@approved_transfer:Approved Transfer` },
    { id: 'FUNDS_TRANSFER', name: $localize`:@@funds_transfer:Funds Transfer` },
    { id: 'PENDING_TRANSFER', name: $localize`:@@pending_transfer:Pending Transfer` },
    { id: 'WRITE_OFF', name: $localize`:@@write_off:Write Off` },
    { id: 'WAIVER', name: $localize`:@@waiver:Waiver` },
    { id: 'COMPULSORY_SAVING', name: $localize`:@@compulsory_savings:Compulsory Saving` },
    { id: 'PAY_CHARGE', name: $localize`:@@pay_charge:Pay Charge` },
    { id: 'INTEREST_POSTING', name: $localize`:@@interest_posting:Interest Posting` },
    { id: 'INITIATE_TRANSFER', name: $localize`:@@initiate_transfer:Initiate Transfer` },
    { id: 'RECOVERY_REPAYMENT', name: $localize`:@@recovery_repayment:Recovery Repayment` },
    { id: 'MANUAL_ENTRY', name: $localize`:@@manual_entry:Manula Entry` },
    { id: 'PRINCIPAL_WRITTEN_OFF', name: $localize`:@@principal_write_off:Principal Write Off` },
    { id: 'INTEREST_WRITTEN_OFF', name: $localize`:@@interest_written_off:Interest Written Off` },
    { id: 'PENALTY_WRITTEN_OFF', name: $localize`:@@penalty_written_off:Penalty Written Off` },
    { id: 'INTEREST_WAIVER', name: $localize`:@@interest_waiver:Interest Waiver` },
    { id: 'OVERPAYMENT', name: $localize`:@@overpayment:Overpayment` },
    { id: 'CLOSURE', name: $localize`:@@closure:Closure` },
  ];
  



  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,
  ) {}

  public get getTransactionTypes() {
    return this.transactionTypes;
  }
  public get getFilteredGlAccounts(): IdNameObject[] {
    return this.filteredGlAccounts.value;
  }

  public setFilteredGlAccounts(param:IdNameObject[]):void  {
    return this.filteredGlAccounts.next(param);
  }

  public downloadExcel(req:any): Observable<any>{
    const headers = new HttpHeaders({'Accept': 'application/vnd.ms-excel'} )
      return this.http.get(`${this.accountingUrl}glAccounts/download`, { headers, params: req, responseType: 'blob' });
  }
  
  public downloadClosureExcel(req:any): Observable<any>{
    const headers = new HttpHeaders({'Accept': 'application/vnd.ms-excel'} )
      return this.http.get(`${this.accountingUrl}closure/download`, { headers, params: req, responseType: 'blob' });
  }

  getChartsOfAccount(queryParams: PageRequest): Observable<any> {
    const params = this.utilityService.objToQueryParams(queryParams);
    return this.http.get(`${this.accountingUrl}glAccounts${params}`)
  }

  getChartsOfAccountParentUsage(): Observable<any> {
    return this.http.get(`${this.accountingUrl}glAccounts/parents`)
  }
  getChartsOfAccountParentUsageByGroup(group:string): Observable<any> {
    return this.http.get(`${this.accountingUrl}glAccounts/parents?accountGroup=${group}`)
  }
  getGroupsAndCategory(): Observable<any> {
    return this.http.get(`${this.accountingUrl}accounts/groups-and-categories`)
  }

  getSingleChartOfAccount(chartId: number): Observable<any> {
    return this.http.get(`${this.accountingUrl}glAccounts/${chartId}`)
  }



  createChartOfAccount(payload: any): Observable<any> {
    return this.http.post(`${this.accountingUrl}glAccounts`, payload)
  }

  updateChartOfAccount(chartId: number, payload: any): Observable<any> {
    return this.http.put(`${this.accountingUrl}glAccounts/${chartId}`, payload)
  }

  approveChartOfAccount(payload: any): Observable<any>{
    return this.http.post(`${this.accountingUrl}glAccounts/approval`, payload);
  }

  getGlAccountLists(): Observable<any> {
    return this.http.get(`${this.accountingUrl}glAccounts/details`)
  }
  getGlAccountHeadersLists(): Observable<any> {
    return this.http.get(`${this.accountingUrl}accounts/headers`)
  }

  getEntryClosure(queryParams: PageRequest): Observable<any> {
    const params = this.utilityService.objToQueryParams(queryParams);
    return this.http.get(`${this.accountingUrl}closure${params}`)
  }

  approveEntryClosure(payload: any): Observable<any>{
    return this.http.post(`${this.accountingUrl}closure/approve`, payload);
  }

  openEntryClosure(payload: any): Observable<any>{
    return this.http.post(`${this.accountingUrl}closure/disable`, payload);
  }

  rejectEntryClosure(payload: any): Observable<any>{
    return this.http.post(`${this.accountingUrl}closure/reject`, payload);
  }

  createEntryClosure(payload: any): Observable<any> {
    return this.http.post(`${this.accountingUrl}closure`, payload)
  }

  getActiveClosureDate(): Observable<any> {
    return this.http.get(`${this.accountingUrl}closure/active-date`);
  }



}





