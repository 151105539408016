import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { objectStoreName } from './common/objectStoreName';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  private db: IDBDatabase;
  private dbName = environment.indexDBName;
  private dbVersion = environment.indexDBVersion;
  private branchStoreName = objectStoreName.branchStoreName;
  private areaStoreName = objectStoreName.areaStoreName;
  private loanOfficerStoreName = objectStoreName.loanOfficerStoreName;
  private loanProductStoreName = objectStoreName.loanProductStoreName;
  private depositProductStoreName = objectStoreName.depositProductStoreName;

  constructor() {}

  async initializeDatabase(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onupgradeneeded = (event: any) => {
        this.db = event.target.result;
        this.createObjectStore();
      };

      request.onsuccess = (event: any) => {
        this.db = event.target.result;
        resolve();
      };

      request.onerror = (event: any) => {
        reject('Failed to initialize IndexedDB');
      };
    });
  }

  private createObjectStore(): void {
    // Create Branch table
    if (!this.db.objectStoreNames.contains(this.branchStoreName)) {
      const objectStore = this.db.createObjectStore(this.branchStoreName, {
        keyPath: 'id',
      });
      objectStore.createIndex('areaIdIndex', 'areaId', { unique: false });
      //objectStore.createIndex('name', 'name', { unique: false });
    }

    // Create Account Officer table
    if (!this.db.objectStoreNames.contains(this.loanOfficerStoreName)) {
      const objectStore = this.db.createObjectStore(this.loanOfficerStoreName, {
        keyPath: 'id',
      });
      objectStore.createIndex('areaIdIndex', 'areaId', { unique: false });
      objectStore.createIndex('branchIdIndex', 'branchId', { unique: false });
      //objectStore.createIndex('name', 'name', { unique: false });
    }

    // Create Area table
    if (!this.db.objectStoreNames.contains(this.areaStoreName)) {
      const objectStore = this.db.createObjectStore(this.areaStoreName, {
        keyPath: 'id',
      });
      //objectStore.createIndex('idIndex', 'id', { unique: true });
      objectStore.createIndex('name', 'name', { unique: false });
    }

    // Create Loan Product table
    if (!this.db.objectStoreNames.contains(this.loanProductStoreName)) {
      const objectStore = this.db.createObjectStore(this.loanProductStoreName, {
        keyPath: 'id',
      });
      //objectStore.createIndex('idIndex', 'id', { unique: true });
      objectStore.createIndex('name', 'name', { unique: false });
    }

    // Create Deposit Product table
    if (!this.db.objectStoreNames.contains(this.depositProductStoreName)) {
      const objectStore = this.db.createObjectStore(
        this.depositProductStoreName,
        { keyPath: 'id' }
      );
      //objectStore.createIndex('idIndex', 'id', { unique: true });
      objectStore.createIndex('name', 'name', { unique: false });
    }
  }

  deleteDatabase(): void {
    const instance = localStorage.getItem('instance') || '0';
    if(Number(instance) > 1) {
      localStorage.setItem('instance', JSON.stringify(Number(instance) - 1))
    }else{
      indexedDB.deleteDatabase(this.dbName);
    }
  }

  clearAllTables(): void {
    const request = indexedDB.open(this.dbName);

    request.onsuccess = (event: any) => {
      const db = event.target.result;
      const objectStoreNames = db.objectStoreNames;

      for (let i = 0; i < objectStoreNames.length; i++) {
        const tableName = objectStoreNames[i];
        const transaction = db.transaction(tableName, 'readwrite');
        const objectStore = transaction.objectStore(tableName);

        const clearRequest = objectStore.clear();

        clearRequest.onsuccess = () => {};

        clearRequest.onerror = (error) => {
          console.error(`Error clearing records in table ${tableName}:`, error);
        };
      }
    };

    request.onerror = (error) => {
      console.error('Error opening database:', error);
    };
  }
}
