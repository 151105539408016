<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <app-modal-header-ui [title]="title"></app-modal-header-ui>

    <div class="dialog-body">
        <form (ngSubmit)="submit()" [formGroup]="customForm">
            <div class="flex my-2 flex-col">
                <mat-form-field appearance="outline" class="textarea-input">
                    <mat-label>{{label}}</mat-label>
                    <input matInput formControlName="customInput" />
                </mat-form-field>
            </div>
            <div class="flex items-center justify-between mb-3">
                <app-button-icon-ui [buttonType]="'submit'" classNames="p-3 rounded-xl text-white bg-success/90 hover:bg-success w-[150px]" name="Submit" value="submit"></app-button-icon-ui>
                <app-button-icon-ui [buttonType]="'button'" classNames="p-3 rounded-xl border border-error opacity-80 hover:opacity-100 text-error w-[150px]" (clickEmit)="cancel()" name="Cancel" value="cancel"></app-button-icon-ui>
            </div>
        </form>
    </div>
</div>