import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DailyRepaymentDisbursementComponent } from './components/daily-repayment-disbursement/daily-repayment-disbursement.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChartsModule } from 'src/app/shared/charts/charts.module';
import { RunningLoansComponent } from './components/running-loans/running-loans.component';
import { ClientsComponent } from './components/clients/clients.component';
import { DepositBalanceComponent } from './components/deposit-balance/deposit-balance.component';
import { PortfolioAtRiskComponent } from './components/portfolio-at-risk/portfolio-at-risk.component';
import { WriteOffsComponent } from './components/write-offs/write-offs.component';
import { DisbursedLoansComponent } from './components/disbursed-loans/disbursed-loans.component';
import { DisbursedLoansCountComponent } from './components/disbursed-loans-count/disbursed-loans-count.component';
import { ClientOccupationComponent } from './components/client-occupation/client-occupation.component';

@NgModule({
  declarations: [
    DailyRepaymentDisbursementComponent,
    RunningLoansComponent,
    ClientsComponent,
    DepositBalanceComponent,
    PortfolioAtRiskComponent,
    WriteOffsComponent,
    DisbursedLoansComponent,
    DisbursedLoansCountComponent,
    ClientOccupationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChartsModule,
  ],
  exports: [
    DailyRepaymentDisbursementComponent,
    RunningLoansComponent,
    ClientsComponent,
    DepositBalanceComponent,
    PortfolioAtRiskComponent,
    WriteOffsComponent,
    DisbursedLoansComponent,
    DisbursedLoansCountComponent,
    ClientOccupationComponent
  ]
})
export class OperationalIndicatorsModule { }
