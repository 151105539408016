import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';

import * as echarts from 'echarts';
import { UtilityService } from 'src/app/utils/utility.service';
@Component({
  selector: 'app-grouped-column-chart',
  templateUrl: './grouped-column-chart.component.html',
  styleUrls: ['./grouped-column-chart.component.css']
})
export class GroupedColumnChartComponent implements OnInit, OnChanges {
  @ViewChild('echarts') echartsElement: ElementRef;
  @ViewChild('echarts2') echarts2Element: ElementRef;

  @Input() width: string = '300px';
  @Input() palette?: string[] = ["#4d5d76", "#929FB1", "#D4D9E2"];
  @Input() chartData?: Object[] = [];
  @Input() defaultChartData?: Object[] = [];
  @Input() itemsPerPage: number = 4; // Number of items per page
  @Input() sortingOptions: { key: 'name' | 'value', sortingDirection: 'asc' | 'desc' | 'default' } = {
    key: 'name',
    sortingDirection: 'asc'
  }
  currentPage: number = 1;
  totalPages: number;
  options2: EChartsOption = {};
  @Input() options: EChartsOption = {
    legend: {
      show: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
      textStyle: {
        fontSize: 10,
      },
    },
    dataset: {
      source: []
    },
    grid: {
      left: '3%', // Set the left margin
      right: '4%', // Set the right margin
      top: '10%',  // Set the top margin
      containLabel: true,
      width: '90%', // Set the width of the chart within the grid
      height: '90%' // Set the height of the chart within the grid
    },
    xAxis: { 
      type: 'category',
      axisTick: {
        alignWithLabel: true,
        interval: 0,
      },
      axisLabel: {
        interval: 1,
        fontSize: 10,
      }
    },
    yAxis: {
      axisLabel: {
        fontSize: 10,
        formatter: (params) => {
          return !params ? '-' : params
        }
      }
    },
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [
      { type: 'bar', barWidth: '20px', name: 'Approved' }, 
      { type: 'bar', barWidth: '20px', name: 'Active'}, 
      { type: 'bar', barWidth: '20px', name: 'Pending'}
    ]
  };

  constructor(private utilityService: UtilityService) {
    this.options2 = JSON.parse(JSON.stringify(this.options));

   }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['sortingOptions']) {
      const sortingOptions = changes['sortingOptions'].currentValue;
      this.sortChartData(sortingOptions.key, sortingOptions.sortingDirection);
    }
    
    if(changes['chartData']) {
      this.defaultChartData = this.chartData;
      this.setUpChart2()
      this.sortChartData(this.sortingOptions.key, this.sortingOptions.sortingDirection);
    }
  }
  ngOnInit(): void {
    this.setUpChart2()
    this.sortChartData(this.sortingOptions.key, this.sortingOptions.sortingDirection);
  }

  setUpChart2() {
    const transformedData = this.defaultChartData
    .map((item:any) => [item.name, item.bar1, item.bar2, item.bar3]);
    this.options2.dataset = {
      source: [...transformedData]
    };

  }

  updateChartData(currentPage: number): void {
    const startIndex = (currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    // Update dataset source
    const propertyNames = [...Object.keys(this.chartData[0])];

    const transformedData = this.chartData
    .slice(startIndex, endIndex)
    .map((item:any) => [item.name, item.bar1, item.bar2, item.bar3]);

    // Assign the transformed data to the 'dataset'
    this.options.dataset = {
      source: [...transformedData]
    };

    // Update chart colors
    this.options.color = this.palette;


    // const maxVal = Math.max(
    //   ...this.chartData?.map((item) => item['bar1'])
    // )

    // const maxLabel = Math.ceil( maxVal/ this.utilityService.getValueScale(maxVal, 10).value) * this.utilityService.getValueScale(maxVal, 10).value;

    // this.options.yAxis = {
    //  ...this.options.yAxis,
    //   min: 0,
    //   max: maxLabel < 8 ? 8 : maxLabel,
    // };

    // Calculate the total number of pages
    this.totalPages = Math.ceil(this.chartData.length / this.itemsPerPage);

    this.updateChartOptions();
  }

  sortChartData(key: string, direction: 'asc' | 'desc' | 'default'): void {
    if (direction === 'default') {
      // Sort the data based on the original order when it was received
      this.chartData = this.defaultChartData;
    } else {
      this.chartData = this.chartData.slice().sort((a: any, b: any) => {        
        const aValue = typeof a[key] === 'string' ? a[key].trim() : a[key];
        const bValue = typeof b[key] === 'string' ? b[key].trim() : b[key];

        if (direction === 'asc') {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    }
  
    // Update the chart data
    this.updateChartData(this.currentPage);
  }

  updateChartOptions(): void {
    setTimeout(() => {

    if (this.echartsElement && this.echartsElement.nativeElement) {

     
      const echartsInstance = echarts.getInstanceByDom(this.echartsElement.nativeElement);
      const echarts2Instance: any = echarts.getInstanceByDom(
        this.echarts2Element.nativeElement
      );
      const yAxisOpt =
        echarts2Instance._model._componentsMap.get('yAxis')[0].axis;

      this.options.yAxis = {
        ...this.options.yAxis,
        min: 0,
        max: yAxisOpt.scale._extent[1] < 8 ? 8 : yAxisOpt.scale._extent[1],
      };

      echartsInstance.setOption(this.options);
    }
  }, 50);
  } 

}
