import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  baseUrl: string = environment.baseUrl + environment.coreUrl;

  constructor(private http: HttpClient, private dataService: DataService) { }

  public postStaff(data: Object){
    return this.http.post<any>(this.baseUrl + 'staffs', data);
  }

  public getAllStaff(req:any): Observable<any>{
    return this.http.get(this.baseUrl + `staffs?${req}`);
  }

  public getStaff(id: number): Observable<any> {
    return this.http.get(this.baseUrl + 'staffs/' + id);
  }

  public compareStaff(id: number): Observable<any> {
    return this.http.get(this.baseUrl + 'staffs/' + id + '/compare');
  }

  public toggleEditing(id, data: any): Observable<any> {
    return this.http.put<any>(this.baseUrl + `staffs/${id}/approve-edit`, data);
  }

  public updateStaff(data: Object,id) {
    return this.http.put<any>(this.baseUrl + 'staffs' + `/${id}`, data);
  }

  /* enable / disable branches.Takes data{'ids': [1,2,baseUrl3], 'enable': bool} */
  public toggleStaffs(data:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + 'staffs/toggle', data);
  }

  public enableDisableStaffs(data:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + 'staffs/toggleEnableAndDisable', data);
  }

  public getNonUserStaffs(): Observable<any> {
    return this.http.get(this.baseUrl + 'staffs/nonUsers');
  }
}
