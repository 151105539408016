<app-chart-wrapper [isLoading]="isLoading" [title]="'Disbursed Loans'" (fullscreen)="hideFilters = $event">
    <!-- <app-chart-filter filter [form]="disbursedLoanForm" [formControls]="disbursedLoanFormControls" *ngIf="!hideFilters" [showSortControl]="showSortControl" (filter)="filterDisbursedLoans($event)"></app-chart-filter> -->
    <!-- Filters -->
    <form filter [formGroup]="form" class="flex gap-1 mb-4" [ngClass]="showSortControl?'justify-between':'justify-end'">



        <ng-container *ngIf="showSortControl">
            <button mat-button [matMenuTriggerFor]="sorting" class="sort-btn">
            {{ sortLabel }}
            <img class="ml-2.5 h-5" src="assets/icons/sort-order.svg" alt="" />
          </button>
            <mat-menu #sorting="matMenu">
                <button mat-menu-item *ngFor="let option of sortOptions" (click)="onSort(option)">
              {{ option.label }}
            </button>
            </mat-menu>
        </ng-container>

        <app-loan-product-search-select [disabled]="hideFilters" class="w-[40%]" (emitSelectedVal)="filterDisbursedLoans({ type: reportsInput.LOAN_PRODUCT, value: $event?.id })" [control]="productCtrl" type="client">
        </app-loan-product-search-select>


    </form>
    <!-- End Filters -->
    <app-no-data-to-show chart *ngIf="!disbursedLoanChartData?.length && !isLoading"></app-no-data-to-show>
    <app-circle-chart [type]="'currency'" chart *ngIf="disbursedLoanChartData?.length === 1 && !isLoading" [width]="'50px'" [height]="'50px'" [totalAmount]="disbursedLoanChartData[0]?.value ?? 0" [imageName]="'hand_money'" [classNames]="'text-primary'">
    </app-circle-chart>


    <app-column-chart chart *ngIf="disbursedLoanChartData?.length > 1 && !isLoading" [chartData]="disbursedLoanChartData" [sortingOptions]="disbursedLoanSortingOptions"></app-column-chart>
</app-chart-wrapper>