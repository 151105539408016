import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Permissions } from 'src/app/shared/dto/permissions';
import { ReportInputTypes, StateTypes } from 'src/app/shared/models/common';
import { PermissionService } from 'src/app/utils/permission.service';
import { TopFilterService } from '../services/topFIlter.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-top-filters',
  templateUrl: './top-filters.component.html',
  styleUrls: ['./top-filters.component.css'],
})
export class TopFiltersComponent implements OnInit {
  public formInstance: FormGroup;
  public type: 'client' | 'server' = 'client';
  public reportInputTypes = ReportInputTypes;
  public permissions = Permissions;

  public selectedAreaId: number = 0;
  public selectedBranchId: number = 0;

  public startDateRange = {
    min: new Date('01/01/2016'),
    max: new Date(),
  };
  public endDateRange = {
    min: new Date(this.startDateRange.max),
    max: new Date(),
  };

  constructor(
    private permissionsService: PermissionService,
    private topFilterService: TopFilterService,
    private authService: AuthService
  ) {
    this.formInstance = this.topFilterService.topFilterForm;
  }

  public reset() {
    this.authService.getuser?.area
    this.formInstance.patchValue({
      [ReportInputTypes.AREA]: this.authService.getuser?.area.id,
      [ReportInputTypes.BRANCH]: this.authService.getuser?.branch.id,
      [ReportInputTypes.LOAN_OFFICER]: 0,
    });
  }

  public dateListner() {
    this.startDateRange.max = new Date(this.formInstance.get(ReportInputTypes.END_DATE).value);
    this.endDateRange.min = new Date(this.formInstance.get(ReportInputTypes.START_DATE).value)
  }

  

  public hasPermission(perms: string[]) {
    return this.permissionsService.hasPermissionList(perms);
  }

  get getAreaCtrl() {
    return this.formInstance.get(ReportInputTypes.AREA) as FormControl;
  }

  get getBranchCtrl() {
    return this.formInstance.get(ReportInputTypes.BRANCH) as FormControl;
  }

  get getLoanOfficerCtrl() {
    return this.formInstance.get(ReportInputTypes.LOAN_OFFICER) as FormControl;
  }

  get startDateCtrl() {
    return this.formInstance.get(ReportInputTypes.START_DATE) as FormControl;
  }

  get endDateCtrl() {
    return this.formInstance.get(ReportInputTypes.END_DATE) as FormControl;
  }
  public emitSelectedVal(
    key: ReportInputTypes,
    val: { id: any; name: string }
  ) {
    switch (key) {
      case ReportInputTypes.AREA:
        this.selectedAreaId = val?.id || 0;
        if (val?.id === 0) {
        } else {
          //set form value to All
          this.formInstance.get(ReportInputTypes.BRANCH).setValue(0);
          this.formInstance.get(ReportInputTypes.LOAN_OFFICER).setValue(0);
        }
        break;
      case ReportInputTypes.BRANCH:
        this.selectedBranchId = val?.id || 0;
        if (val?.id !== 0) {
          this.formInstance.get(ReportInputTypes.LOAN_OFFICER).setValue(0);
        }
        break;
      case ReportInputTypes.GROUP:
        break;
      case ReportInputTypes.LOAN_OFFICER:
        break;
    }
  }

  ngOnInit(): void {
    this.reset()
  }
}
