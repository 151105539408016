<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div
        class="flex items-center px-[15px] pb-[10px] border-b-[1px] border-primary sticky top-0 left-0 bg-white z-20 pt-6">
        <img src="assets/images/new-images/logo-2.png" class="flex-[0_0_10%] header-image w-[110px] h-[26.89px]"
            routerLink="/dashboard" />

        <h2 class="font-semibold m-[0_auto] text-xl text-center">{{ isViewMode ? 'Edit': 'New' }} Deposit Product</h2>
        <mat-icon class="text-red-500 text-4xl cursor-pointer" (click)="cancel()">highlight_off</mat-icon>
    </div>

    <div class="flex gap-4 p-4 m-4 rounded-[10px] bg-[#F2F2F2]">
        <div class="flex flex-col gap-[5px]">
            <h3 class="text-primary" i18n="@@basic_information">Basic Information</h3>
            <div class="p-3">
                <form [formGroup]="basicForm">
                    <div class="flex flex-col gap-[10px]">
                        <div class="flex items-center justify-between gap-4">
                            <mat-label i18n="@@product_name">Product Name <span
                                    class="text-red-500">*</span></mat-label>
                            <mat-form-field appearance="outline" class="short_height">
                                <input type="text" matInput appRemoveSpaces appTitleCase formControlName="name" />
                            </mat-form-field>
                        </div>

                        <div class="flex items-center justify-between gap-4">
                            <mat-label>Code <span class="text-red-500">*</span></mat-label>
                            <mat-form-field appearance="outline" class="short_height">
                                <input type="text" matInput appRemoveSpaces formControlName="code" />
                            </mat-form-field>
                        </div>

                        <div class="flex items-center justify-between gap-4">
                            <mat-label>Description</mat-label>
                            <mat-form-field appearance="outline" class="textarea-input">
                                <textarea matInput appRemoveSpaces appSentenceCase formControlName="description"
                                    rows="8"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="flex items-center justify-between gap-4">
                            <mat-label>Overdraft Limit <span class="text-red-500">*</span></mat-label>
                            <mat-form-field appearance="outline" class="short_height">
                                <input type="text" matInput appCurrency formControlName="overdraftLimit" (blur)="invalidateMinimumBalance()" />
                            </mat-form-field>
                        </div>

                    </div>
                </form>
            </div>
        </div>

        <div class="flex flex-col gap-[5px]">
            <h3 class="text-primary" i18n="@@interest_expense">Deposit Product Information</h3>
            <div class="border-2 border-gray-300 rounded-[10px] p-3">
                <form [formGroup]="interestExpenseForm">
                    <div class="flex flex-col gap-[15px]">
                        <div class="flex items-center justify-between gap-[10px]">
                            <mat-label></mat-label>
                            <div class="flex gap-20 !px-10">
                                <mat-label i18n="@@interst_expense" class="font-bold">Interest Expense</mat-label>
                                <mat-label i18n="@@overdraft" class="font-bold">Overdraft</mat-label>
                            </div>
                        </div>

                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@interst">Interest(%) <span class="text-red-500">*</span></mat-label>
                            <div class="flex gap-[15px]">
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <input type="text" matInput formControlName="interestRate" />
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <input type="text" matInput formControlName="overdraftInterestRate" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@interest_period">Interest Period <span
                                    class="text-red-500">*</span></mat-label>
                            <div class="flex gap-[15px]">
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <mat-select formControlName="interestPeriodUnit">
                                        <mat-option value="DAY">Day</mat-option>
                                        <mat-option value="WEEK">Week</mat-option>
                                        <mat-option value="MONTH">Month</mat-option>
                                        <mat-option value="YEAR">Year</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <mat-select formControlName="overdraftInterestPeriodUnit">
                                        <mat-option value="DAY">Day</mat-option>
                                        <mat-option value="WEEK">Week</mat-option>
                                        <mat-option value="MONTH">Month</mat-option>
                                        <mat-option value="YEAR">Year</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@compounding_frequency">Compounding Period <span
                                    class="text-red-500">*</span></mat-label>
                            <div class="flex gap-[15px]">
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <mat-select formControlName="compoundingPeriodUnit"
                                        (selectionChange)="updatePostingPeriodUnit($event)">
                                        <mat-option value="DAY">Day</mat-option>
                                        <mat-option value="WEEK">Week</mat-option>
                                        <mat-option value="MONTH">Month</mat-option>
                                        <mat-option value="YEAR">Year</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <mat-select formControlName="overdraftCompoundingPeriodUnit"
                                        (selectionChange)="updateOverdraftPostingPeriodUnit($event)">
                                        <mat-option value="DAY">Day</mat-option>
                                        <mat-option value="WEEK">Week</mat-option>
                                        <mat-option value="MONTH">Month</mat-option>
                                        <!-- <mat-option value="YEAR">Year</mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@posting_period">Posting Period <span
                                    class="text-red-500">*</span></mat-label>
                            <div class="flex gap-[15px]">
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <mat-select formControlName="interestPostingPeriodUnit">
                                        <mat-option value="DAY">Day</mat-option>
                                        <mat-option value="WEEK">Week</mat-option>
                                        <mat-option value="MONTH">Month</mat-option>
                                        <mat-option value="YEAR">Year</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <mat-select formControlName="overdraftInterestPostingPeriodUnit">
                                        <mat-option value="DAY">Day</mat-option>
                                        <mat-option value="WEEK">Week</mat-option>
                                        <mat-option value="MONTH">Month</mat-option>
                                        <mat-option value="YEAR">Year</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@interest_calculation">Interest Calculation <span
                                    class="text-red-500">*</span></mat-label>
                            <div class="flex gap-[15px]">
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <mat-select formControlName="interestCalculationType">
                                        <mat-option value="AVERAGE_BALANCE">Average Balance</mat-option>
                                        <mat-option value="MINIMUM_BALANCE">Minimum Balance</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="short_height w-[110px]">
                                    <mat-select formControlName="overdraftInterestCalculationType">
                                        <mat-option value="AVERAGE_BALANCE">Average Balance</mat-option>
                                        <mat-option value="MINIMUM_BALANCE">Minimum Balance</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="flex flex-col gap-[5px] pt-[1.4em]">
            <div class="border-2 border-gray-300 rounded-[15px]">
                <form [formGroup]="otherInfoForm">
                    <div class="flex flex-col gap-[15px] p-3">
                        <div class="flex items-center justify-between">
                            <mat-label i18n="@@other_information" class="font-bold">Other Information</mat-label>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@charges">Charges <span class="text-red-500">*</span></mat-label>
                            <mat-form-field appearance="outline" class="short_height w-[110px]">
                                <mat-select multiple formControlName="chargeIds"
                                    (selectionChange)="chargeIdChange($event.value)">
                                    <mat-option [value]="charge?.id" *ngFor="let charge of charges">{{ charge?.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@minimum_balance">Minimum Balance <span
                                    class="text-red-500">*</span></mat-label>
                            <mat-form-field appearance="outline" class="short_height w-[110px]">
                                <input type="tel" matInput appCurrency formControlName="openingBalance" (blur)="invalidateOverdraftLimit()" />
                            </mat-form-field>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@transfer_limit">Transfer Limit <span
                                    class="text-red-500">*</span></mat-label>
                            <mat-form-field appearance="outline" class="short_height w-[110px]">
                                <input type="tel" matInput appCurrency formControlName="transferLimit"
                                    (blur)="validateTransferLimits()" />
                            </mat-form-field>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@daily_transfer_limit">Daily Transfer Limit <span
                                    class="text-red-500">*</span></mat-label>
                            <mat-form-field appearance="outline" class="short_height w-[110px]">
                                <input type="tel" matInput appCurrency formControlName="dailyTransferLimit"
                                    (blur)="validateTransferLimits()" />
                            </mat-form-field>
                        </div>
                        <div class="flex items-center justify-between gap-[32px]">
                            <mat-label i18n="@@rounding_multiple">Rounding Multiple <span
                                    class="text-red-500">*</span></mat-label>
                            <mat-form-field appearance="outline" class="short_height w-[110px]">
                                <input type="text" appStrictNumbers matInput formControlName="roundingMultiple" />
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="mt-[30px] px-[15px]">
        <app-drag-drop-list [product]="'deposit'" [isDisabled]="isDisabled"
            [accountingCategories]="accountingCategories" [glAccounts]="glAccounts" [events]="events"
            [charges]="charges" [eventMaps]="eventMaps" [accountType]="activeAccountType"
            (emitAccountingList)="submit($event)"></app-drag-drop-list>

        <div class="flex gap-[20px] mb-[50px] justify-end">
            <app-button-icon-ui *ngIf="isViewMode && isDisabled" [buttonType]="'button'"
                classNames="px-[33px] py-[14px] rounded-[10px] text-white bg-success/90 hover:bg-success w-[120px] h-[45px]"
                (clickEmit)="editForm($event)" name="Edit" value="edit"></app-button-icon-ui>
            <app-button-icon-ui *ngIf="isViewMode && !isDisabled" [buttonType]="'button'"
                classNames="px-[33px] py-[14px] rounded-[10px] text-white bg-success/90 hover:bg-success w-[120px] h-[45px]"
                (clickEmit)="editForm($event)" name="Save" value="save"></app-button-icon-ui>
            <app-button-icon-ui *ngIf="!isViewMode" [buttonType]="'button'"
                classNames="px-[33px] py-[14px] rounded-[10px] text-white bg-success/90 hover:bg-success w-[120px] h-[45px]"
                (clickEmit)="submitForm()" name="Submit" value="submit"></app-button-icon-ui>
            <app-button-icon-ui [buttonType]="'button'"
                classNames="px-[33px] py-[14px] rounded-[10px] border border-error opacity-80 hover:opacity-100 text-error w-[120px] h-[45px]"
                (clickEmit)="cancel()" name="Cancel" value="cancel"></app-button-icon-ui>
        </div>
    </div>
</div>