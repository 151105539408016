<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

    <app-modal-header-ui title="New Loan Account"></app-modal-header-ui>

    <div class="dialog-body">
        <app-button-toggle-ui (stateEmit)="navigateToState($event)" [buttons]="toggleStates"></app-button-toggle-ui>

        <!-- Basic Form-->
        <form class="my-8 grid grid-cols-2 md:grid-cols-3 gap-7" [formGroup]="basicForm" [hidden]="activeTab === 'guarantor'">
            <app-searchable-select [label]="'Loan Products'" [options]="loanProducts" formControlName="loanProductId"></app-searchable-select>

            <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                <mat-label>Loan Size</mat-label>
                <input appStrictNumbers type="tel" formControlName="principal" matInput placeholder="" />
                <mat-hint class="mt-4" *ngIf="loanProductRecords">Min: {{ loanProductRecords?.minimumPrincipal | numberWithComma }} Max: {{ loanProductRecords?.maximumPrincipal | numberWithComma }}
                </mat-hint>
            </mat-form-field>

            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Loan Term</mat-label>
                <input appStrictNumbers type="tel" formControlName="loanPeriodLength" matInput placeholder="" />
                <span matSuffix>/
                    <mat-select formControlName="loanPeriodLengthUnit" (selectionChange)="selectedLoanTenorUnit()">
                        <mat-option [value]="repayment.value" *ngFor="let repayment of loanFrequencyOptions">{{ repayment.label }} </mat-option>
                    </mat-select>
                </span>
                <mat-hint class="mt-4" *ngIf="loanProductRecords">Min: {{ loanProductRecords?.minimumLoanPeriodLength }}, Max: {{ loanProductRecords?.maximumLoanPeriodLength }}</mat-hint>
            </mat-form-field>

            <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                <mat-label>Interest Rate</mat-label>
                <input type="tel" formControlName="interestRate" matInput placeholder="" />
                <span matSuffix>%
                <mat-select formControlName="interestRateUnit">
                    <mat-option value="DAY">Day</mat-option>
                    <mat-option value="WEEK">Week</mat-option>
                    <mat-option value="MONTH">Month</mat-option>
                    <mat-option value="YEAR">Year</mat-option>
                </mat-select>
            </span>
            </mat-form-field>

            <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                <mat-label>Grace Period</mat-label>
                <input type="tel" formControlName="gracePeriodLength" matInput placeholder="" />
                <span matSuffix>|
                    <mat-select formControlName="gracePeriodUnit">
                        <mat-option value="DAY">Day</mat-option>
                        <mat-option value="WEEK">Week</mat-option>
                        <mat-option value="MONTH">Month</mat-option>
                        <mat-option value="YEAR">Year</mat-option>
                    </mat-select>
                </span>
                <mat-hint class="mt-4" *ngIf="loanProductRecords">Min: {{ loanProductRecords?.minimumGracePeriod }}, Max: {{ loanProductRecords?.maximumGracePeriod }}</mat-hint>
            </mat-form-field>

            <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                <mat-label>Repayment Frequency</mat-label>
                <input type="tel" formControlName="loanRepaymentFrequency" matInput placeholder="" />
                <span matSuffix>
                    <mat-select formControlName="loanRepaymentFrequencyPeriodUnit">
                        <mat-option value="DAY">Day</mat-option>
                        <mat-option value="WEEK">Week</mat-option>
                        <mat-option value="MONTH">Month</mat-option>
                        <mat-option value="YEAR">Year</mat-option>
                    </mat-select>
                </span>
                <mat-hint class="mt-4" *ngIf="loanProductRecords">Min: {{ loanProductRecords?.minimumLoanRepaymentFrequency }}, Max: {{ loanProductRecords?.maximumLoanRepaymentFrequency }}</mat-hint>
            </mat-form-field>
        </form>

        <!-- Guarantor Form -->
        <form [formGroup]="guarantorForm" class="flex flex-col gap-5 my-8 max-h-[50vh] md:max-h-[unset] overflow-auto" [hidden]="activeTab === 'basic'">
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
                
                <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input  (blur)="checkPhoneNumber()" appStrictNumbers type="text" maxlength="11" formControlName="mobileNumber" matInput />
                    <span matSuffix>
                        <mat-icon *ngIf="!checkingPhone" style="font-size: 18px"
                            >security</mat-icon
                        >
                        <mat-spinner
                            *ngIf="checkingPhone"
                            class="pl-2"
                            diameter="18"
                            strokeWidth="1"
                        >
                        </mat-spinner>
                    </span>
                </mat-form-field>

                <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input appRemoveSpaces type="text" formControlName="firstName" matInput />
                </mat-form-field>
                <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                    <mat-label>Middle Name</mat-label>
                    <input appRemoveSpaces type="text" formControlName="middleName" matInput />
                </mat-form-field>
                <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input appRemoveSpaces type="text" formControlName="lastName" matInput />
                </mat-form-field>
    
                
            </div>

            <div class="grid grid-cols-3 md:grid-cols-5 gap-4">
                <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option value="MALE">Male</mat-option>
                        <mat-option value="FEMALE">Female</mat-option>
                        <mat-option value="OTHER">Other</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="guarantorForm.get('bvn') as bvn" style="width: 100%" appearance="outline">
                    <mat-label>BVN</mat-label>
                    <input maxlength="11" type="tel" appStrictNumbers matInput formControlName="bvn" (blur)="checkBVN()" />
                    <span matSuffix>
                        <mat-icon *ngIf="!checkingBVN" style="font-size: 18px"
                            >security</mat-icon
                        >
                        <mat-spinner
                            *ngIf="checkingBVN"
                            class="pl-2"
                            diameter="18"
                            strokeWidth="1"
                        >
                        </mat-spinner>
                    </span>
                </mat-form-field>
               
    
                <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                    <mat-label>Date of Birth</mat-label>
                    <input readonly [max]="dobAgeRange" matInput [matDatepicker]="dateOfBirth" placeholder="Choose a date" formControlName="dateOfBirth" />
                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth">
                    </mat-datepicker-toggle>
                    <mat-datepicker #dateOfBirth></mat-datepicker>
                    <mat-hint class="mt-4" *ngIf="loanProductRecords">Age range 18 - 60</mat-hint>
                </mat-form-field>
    
                <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                    <mat-label>Occupation</mat-label>
                    <input appRemoveSpaces type="text" formControlName="occupation" matInput />
                </mat-form-field>
    
                <mat-form-field style="width: 100%" class="w-full col-span-2 md:col-span-1" appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input appRemoveSpaces type="text" formControlName="email" matInput />
                </mat-form-field>
    
            </div>

            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <app-searchable-select [label]="'State'" [options]="states" formControlName="stateId">
                </app-searchable-select>
    
                <app-searchable-select [label]="'Local Govt.'" [options]="localGovernments" formControlName="lgaId">
                </app-searchable-select>
    
                <mat-form-field style="width: 100%" class="w-full" appearance="outline">
                    <mat-label>Town/City</mat-label>
                    <input appRemoveSpaces type="text" formControlName="townName" matInput />
                </mat-form-field>
    
                <mat-form-field style="width: 100%" class="w-full textarea-input" appearance="outline">
                    <mat-label>Address</mat-label>
                    <textarea appRemoveSpaces formControlName="addressLine1" matInput rows="1"></textarea>
                </mat-form-field>    
            </div>
            
            <div class="flex flex-wrap gap-4">
                <app-file-upload-ui label="Photo" [currentPhoto]="imageValues?.photo" [disabled]="isViewMode" (upload)="saveFile($event, 'photo')"></app-file-upload-ui>

                <app-file-upload-ui label="Signature" [currentPhoto]="imageValues?.signature" [disabled]="isViewMode" (upload)="saveFile($event, 'signature')"></app-file-upload-ui>
               
                <app-file-upload-ui label="ID Card" [currentPhoto]="imageValues?.idCard" [disabled]="isViewMode" (upload)="saveFile($event, 'idCard')"></app-file-upload-ui>
            </div>
        </form>

        <div class="flex items-center justify-center gap-3 my-3" [ngClass]="activeTab === 'basic'?'flex-row':'flex-col-reverse md:flex-row'">
            <ng-container *ngIf="activeTab === 'basic'; else guarantorActionBtns">
                <app-button-icon-ui classNames="btn border-error text-error bg-white w-100px" name="Cancel" value="Cancel " tooltip="Cancel" (clickEmit)="close()"></app-button-icon-ui>
                <app-button-icon-ui [iconPosition]="'right'" [icon]="'east'" classNames="btn text-white bg-success w-100px " name="Next" value="next " tooltip="Move to the next step" (clickEmit)="onSubmitBasic()"></app-button-icon-ui>
            </ng-container>
            <ng-template #guarantorActionBtns>
                <app-button-icon-ui class="w-full md:!w-[100px]" classNames="btn border-error text-error bg-white w-full  " name="Cancel" value="Cancel " tooltip="Cancel" (clickEmit)="close()"></app-button-icon-ui>
                <app-button-icon-ui [iconPosition]="'left'" [icon]="'west'" class="w-full md:!w-[100px]" classNames="btn w-full text-white bg-primary" name="Previous" value="previous " tooltip="Move to the previous step" (clickEmit)="gotoBasic()"></app-button-icon-ui>
                <app-button-icon-ui [iconPosition]="'right'" [icon]="'save'" class="w-full md:!w-[100px]" classNames="btn w-full text-white bg-success" name="Submit" value="submit " tooltip="Submit" (clickEmit)="onSubmitGuarantor()"></app-button-icon-ui>
            </ng-template>
        </div>
    </div>
</div>