import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-terms-and-condition-modal',
  templateUrl: './terms-and-condition-modal.component.html',
  styleUrls: ['./terms-and-condition-modal.component.css'],
})
export class TermsAndConditionModalComponent {

  @Output() redirectToDashboardEmit:EventEmitter<any> = new EventEmitter()
  
  public termsAndConditionAccepted: any = false;

  constructor() {}

  public redirectToDashboard() {
   this.redirectToDashboardEmit.emit()
  }

}
