import { Component, Input, OnInit } from '@angular/core';
import { Page, InitDisplayedCol } from '../../models/common';
import { DummyDataService } from '../../services/dummyData.service';

@Component({
  selector: 'app-generated-report-table',
  templateUrl: './generated-report-table.component.html',
  styleUrls: ['./generated-report-table.component.css']
})
export class GeneratedReportTableComponent implements OnInit {

  @Input() tableRecords: any[];

  public pageProp: Page = this.dummyData.dummyPage;
  public showPagination:boolean = true;
  public columns: InitDisplayedCol[] = [
    {
      name: 'Name',
      key: 'name',
      type: 'text',
      value: 'name',
    },
    {
      name: 'Description',
      key: 'description',
      type: 'text',
      value: 'description',
    }
   
  ]
  

  constructor(private dummyData: DummyDataService) { }

  ngOnInit(): void {
    this.tableRecords = this.dummyData.areasDummyTableData
  }

  public pageSizeToggle (size:number){

  }

  public pageCtrlToggle (ctrl:string){

  }

  public onSort(val:any){

  }

}
