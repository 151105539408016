import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Unsubscribe } from 'src/app/utils/unsubscribe.service';
import { AccountingCategory, AccountingTransaction, SearchableSelectTypes } from '../../models/common';
import { UtilityService } from 'src/app/utils/utility.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { filter, takeUntil } from 'rxjs';
import { WizerValidatorService } from 'src/app/utils/wizer.validators.service';
import { LoanProductService } from 'src/app/pages/settings/services/loan-product.service';
import { DragDropListComponent } from '../../components/drag-drop-list/drag-drop-list.component';

@Component({
  selector: 'app-new-view-loan-product',
  templateUrl: './new-view-loan-product.component.html',
  styleUrls: ['./new-view-loan-product.component.css']
})
export class NewViewLoanProductComponent extends Unsubscribe implements OnInit {

  @ViewChild(DragDropListComponent, { static: true }) dragDrop: DragDropListComponent;
  activeAccountType: 'ACCRUAL' | 'CASH' = 'ACCRUAL';
  newTransactionNames: { [key: string]: string } = {};
  basicForm: FormGroup;
  loanForm: FormGroup;
  initialBasicFormValue: any;
  initialLoanFormValue: any;
  initialLoanProductAccounting: any;
  disbursementForm: FormGroup;
  accountingForm: FormGroup;
  formValid: boolean = true;
  isViewMode: boolean = false;
  isDisabled: boolean = false;
  isChangedValue: boolean = false;
  public fundSourceLabel: string = $localize`:@@fund_source:Fund Source`;

  private accountCopy = {
    cash: {},
    accrual: {},
  };

  selectedLoanProductId: number;
  charges: any;
  penaltyCharges: any;
  savingsProduct: any;
  fundSources: any[];
  glAccounts: any[];
  selectedChargeIds: number[] = [];
  assets: SearchableSelectTypes[];
  incomes: SearchableSelectTypes[];
  expenses: SearchableSelectTypes[];
  liabilities: SearchableSelectTypes[];
  oldCalendarType: string;
  events: { label: string, value: string }[] = [
    { label: 'Cancel', value: 'CANCEL' },
    { label: 'Disbursement', value: 'DISBURSEMENT' },
    { label: 'Repayment', value: 'REPAYMENT' },
    { label: 'Waive', value: 'WAIVE' },
    { label: 'Write Off', value: 'WRITE_OFF' },
  ];
  eventMaps: any[] = [];
  accountingCategories: { key: string; value: AccountingCategory }[] = [
    { key: 'disbursement', value: { name: $localize`:disbursement:Disbursement`, row: [] } },
    { key: 'repayment', value: { name: $localize`:@@principal_repayment:Principal Repayment`, row: [] } },
    { key: 'interest', value: { name: $localize`:@@interest:Interest`, row: [] } },
    { key: 'fees_charges', value: { name: $localize`:@@fees_slash_charges:Fees/Charges`, row: [] } },
    { key: 'writeOff_waivers', value: { name: $localize`:@@writeOff_waivers:Write-Off/Waivers`, row: [] } }
  ]

  gracePeriodUnits = [
    { label: 'Day', value: 'DAY' },
    { label: 'Week', value: 'WEEK' },
    { label: 'Month', value: 'MONTH' },
  ];
  latePaymentPeriodUnits = [
    { label: 'Day', value: 'DAY' },
    { label: 'Week', value: 'WEEK' },
    { label: 'Month', value: 'MONTH' },
    { label: 'Year', value: 'YEAR' },
  ];
  repaymentFrequencyOptions = [
    { label: 'Day', value: 'DAY' },
    { label: 'Week', value: 'WEEK' },
    { label: 'Month', value: 'MONTH' },
  ];
  businessDays: any = {};
  workingDays = {
    day: {
      week: 5,
      month: 20,
      year: 260
    },
    week: {
      month: 4,
      year: 48
    },
    month: {
      year: 12
    }
  }
  calendarDays = {
    day: {
      week: 7,
      month: 30,
      year: 365
    },
    week: {
      month: 4,
      year: 52
    },
    month: {
      year: 12
    }
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private utils: UtilityService,
    private toast: NotificationService,
    private validator: WizerValidatorService,
    private loanProductService: LoanProductService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialoRef: MatDialogRef<NewViewLoanProductComponent>
  ) {
    super();

    if (data) {
      this.isViewMode = data.isViewMode;
      this.charges = data.charges;
      this.penaltyCharges = data.penaltyCharges;
      this.savingsProduct = data.savingsProduct;
      this.eventMaps = data.eventMaps;

      if (data.accountingData) {
        const { ASSET, INCOME, EXPENSE, LIABILITY, EQUITY } = data.accountingData;

        // Check if each property exists before spreading them
        const assetAccounts = ASSET ? [...ASSET] : [];
        const incomeAccounts = INCOME ? [...INCOME] : [];
        const expenseAccounts = EXPENSE ? [...EXPENSE] : [];
        const liabilityAccounts = LIABILITY ? [...LIABILITY] : [];
        const equityAccounts = EQUITY ? [...EQUITY] : [];

        // Concatenate and sort the accounts
        this.glAccounts = [
          ...assetAccounts,
          ...incomeAccounts,
          ...expenseAccounts,
          ...liabilityAccounts,
          ...equityAccounts,
        ].sort((a, b) => a.name.localeCompare(b.name));

        // Assign the asset accounts to fundSources
        this.fundSources = assetAccounts;
      }
    }

    this.initForm();

    if (data && this.isViewMode) {
      const { id, name, code, description, accountingType, loanProductCharges, latePaymentCharge, fundSources, isCalenderDays, loanProductAccounting, ...loanFormData } = data.loanProduct;
      this.initialLoanProductAccounting = cloneDeep(loanProductAccounting);
      this.selectedLoanProductId = id;
      this.activeAccountType = accountingType;
      this.basicForm.patchValue({ name, code, description });

      if (fundSources) {
        const fundSourcesSelected = fundSources.map(fundSource => fundSource.glAccountId);
        this.basicForm.get('fundSources').setValue(fundSourcesSelected)
      }

      const controlsConfig = {
        savingsProductId: [Validators.required],
        compulsorySavingsAmount: [Validators.required],
        prepaymentPenaltyAmount: [Validators.required]
      };

      for (const controlName in controlsConfig) {
        if (controlsConfig.hasOwnProperty(controlName)) {
          this.loanForm.addControl(controlName, new FormControl('', controlsConfig[controlName]));
        }
      }
      loanFormData.interestPeriodUnit = loanFormData.interestRatePeriodUnit
      const loanFormControlNames = Object.keys(this.loanForm.controls);
      // Filter properties from the object that match the control names
      const patchValues = Object.keys(loanFormData)
        .filter(key => loanFormControlNames.includes(key))
        .reduce((result, key) => {
          
          result[key] = loanFormData[key];
          return result;
        }, {});

      this.loanForm.patchValue(patchValues);

      if (loanProductCharges) {
        const defaultChargeIds = loanProductCharges.map(charge => charge.id);
        this.loanForm.get('chargeIds')?.setValue(defaultChargeIds);
      }
      if (latePaymentCharge) {
        this.loanForm.get('latePaymentChargeId').setValue(latePaymentCharge.id)
      }

      const isCalendarDaysSelected = isCalenderDays ? 'CALENDAR' : 'WORKING';
      this.loanForm.get('isCalenderDays').setValue(isCalendarDaysSelected)

      // Group the loanProductAccounting objects by groupLabel
      const groupedData = loanProductAccounting.reduce((result, obj) => {
        if (!result[obj.groupLabel]) {
          result[obj.groupLabel] = [];
        }
        result[obj.groupLabel].push(obj);
        return result;
      }, {});

      // Map the grouped data to accountingCategories and format eventName
      const updatedAccountingCategories = this.accountingCategories.map(category => {
        const matchingGroup = groupedData[category.value.name];

        if (matchingGroup) {
          // Format eventName to title case for each row
          category.value.row = matchingGroup.map(row => {
            const mappingNames = this.eventMaps[0].mappings.split(', ') ?? [];
            const selectedMapping = this.eventMaps.find(item => item.mapping_name.toLowerCase() === row.eventName.toLowerCase());
            
            return {
              ...row,
              eventName: `${row.eventName.toUpperCase()}-${row.eventMapping.toUpperCase()}`,
              eventMapping: this.utils.titleCase(this.utils.underScoreToSpace(row.eventMapping)),
              eventMappingList: selectedMapping.mappings.split(', ') ?? [],
              eventNameList: mappingNames ?? [],
              creditGlAccountList: this.glAccounts,
              debitGlAccountList: this.glAccounts
            };
          });
        }

        return category;
      });

      // Assign the updated categories back to accountingCategories
      this.accountingCategories = updatedAccountingCategories;


      this.initialBasicFormValue = this.basicForm.value;
      this.initialLoanFormValue = this.loanForm.value;
      this.initialLoanFormValue.isCalenderDays = isCalenderDays;

      this.disableAllFields();
    }
  }

  ngOnInit(): void {
    this.rateListener(this.loanForm, 'defaultInterestRate');
    this.rateListener(this.loanForm, 'minimumInterestRate');
    this.rateListener(this.loanForm, 'maximumInterestRate');
    this.businessDays = this.calendarDays;
  }

  chargeIdChange(selectedIds: number[]) {
    this.dragDrop.chargeIdChange(selectedIds);
  }

  latePaymentchargeIdChange(selectedId: number) {
    this.dragDrop.latePaymentchargeIdChange(selectedId);
  }

  resetAccountingCategories(): void {
    for (const cat of this.accountingCategories) {
      cat.value.row = [];
    }
  }

  mapRecordsSearchType(records: Array<any>) {
    return records.map((record) => ({
      label: record?.name,
      value: record?.id,
    }));
  }

  get fundSourceCtrl(){
    return this.basicForm.get('fundSources') as FormControl
  }

  initForm() {
    this.initBasicForm();
    this.initLoanForm();
  }

  initBasicForm() {
    this.basicForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      fundSources: new FormControl('', [Validators.required]),
    });
  }

  initLoanForm() {
    this.loanForm = this.fb.group({
      defaultPrincipal: new FormControl('', [Validators.required]),
      minimumPrincipal: new FormControl('', [Validators.required]),
      maximumPrincipal: new FormControl('', [Validators.required]),
      interestPeriodUnit: new FormControl('DAY', [Validators.required]),
      defaultInterestRate: new FormControl('', [Validators.required, Validators.pattern(/^(\d{1,2}(\.\d+)?|100(\.0+)?)$/)]),
      minimumInterestRate: new FormControl('', [Validators.required, Validators.pattern(/^(\d{1,2}(\.\d+)?|100(\.0+)?)$/)]),
      maximumInterestRate: new FormControl('', [Validators.required, Validators.pattern(/^(\d{1,2}(\.\d+)?|100(\.0+)?)$/)]),
      loanPeriodLengthUnit: new FormControl('MONTH', [Validators.required]),
      defaultLoanPeriodLength: new FormControl('', [Validators.required]),
      minimumLoanPeriodLength: new FormControl('', [Validators.required]),
      maximumLoanPeriodLength: new FormControl('', [Validators.required]),
      loanRepaymentFrequencyPeriodUnit: new FormControl('DAY', [Validators.required,]),
      loanRepaymentFrequency: new FormControl('', [Validators.required]),
      minimumLoanRepaymentFrequency: new FormControl('', [Validators.required]),
      maximumLoanRepaymentFrequency: new FormControl('', [Validators.required]),
      gracePeriodUnit: new FormControl('DAY', [Validators.required]),
      gracePeriodLength: new FormControl('', [Validators.required]),
      minimumGracePeriod: new FormControl('', [Validators.required]),
      maximumGracePeriod: new FormControl('', [Validators.required]),
      amortizationType: new FormControl('NORMAL', [Validators.required]),
      chargeIds: new FormControl('', [Validators.required]),
      latePaymentChargeId: new FormControl(''),
      latePaymentPeriodLength: new FormControl('', [Validators.required]),
      latePaymentPeriodUnit: new FormControl('DAY', [Validators.required]),
      isCalenderDays: new FormControl('WORKING', [Validators.required]),
      compulsorySavingsType: new FormControl('NONE'),
      prepaymentPenaltyType: new FormControl('NONE'),
      roundingMultiple: new FormControl('', [Validators.required]),
      accountingType: new FormControl('CASH', [Validators.required]),

      deductUpfrontInterest: new FormControl(false),

    });
  }

  initAccountingForm() {
    this.accountingForm = this.fb.group({
      loanPortfolioAccountId: new FormControl('', [Validators.required]),
      interestIncomeAccountId: new FormControl('', [Validators.required]),
      feeIncomeAccountId: new FormControl('', [Validators.required]),
      penaltyIncomeAccountId: new FormControl('', [Validators.required]),
      recoveryIncomeAccountId: new FormControl('', [Validators.required]),
      lossesWrittenOffAccountId: new FormControl('', [Validators.required]),
      interestWrittenOffAccountId: new FormControl('', [Validators.required]),
      overpaymentAccountId: new FormControl('', [Validators.required]),
    });
  }

  get loanFormCtrl() {
    return this.loanForm.controls;
  }

  switchAccountType(accountType: 'ACCRUAL' | 'CASH') {
    this.activeAccountType = accountType;
    this.loanForm.get("accountingType").setValue(accountType);
  }

  submitForm() {
    this.loanFormCtrl['loanRepaymentFrequency'].addValidators(Validators.required);
    this.loanFormCtrl['loanRepaymentFrequency'].updateValueAndValidity();

    if (this.basicForm.invalid) {
      this.validator.CheckMessagesAndNotify(this.basicForm.controls);
      this.basicForm.markAllAsTouched();
      return;
    }

    if (this.loanForm.invalid) {
      this.validator.CheckMessagesAndNotify(this.loanForm.controls);
      this.loanForm.markAllAsTouched();
      return;
    }

    if (typeof this.loanFormCtrl['isCalenderDays'].value === 'string') {
      this.oldCalendarType = this.loanFormCtrl['isCalenderDays'].value;
    }
    
    switch (this.loanFormCtrl['isCalenderDays'].value) {
      case 'CALENDAR':
        this.loanFormCtrl['isCalenderDays'].setValue(true)
        break;
      case 'WORKING':
        this.loanFormCtrl['isCalenderDays'].setValue(false)
    }
    this.dragDrop.submit();
  }

  submit(mergedRowArray) {

    if (this.isFormValueChanged(this.basicForm.value, this.initialBasicFormValue) ||
      this.isFormValueChanged(this.loanForm.value, this.initialLoanFormValue) ||
      this.isMergedRowArrayChanged(mergedRowArray, this.initialLoanProductAccounting)) {
      this.isChangedValue = true;
      const payload = {
        ...this.basicForm.value,
        ...this.loanForm.value,
        loanProductAccounting: mergedRowArray
      };

      this.sanitizePayload(payload);
      if (this.isViewMode) return this.editLoanProduct(payload);
      this.addNewLoanProduct(payload);
    } else {
      this.isChangedValue = false;
      this.toast.warn($localize`:@@yu_ht_me_ay_cs_pe_cl:You haven't made any change. Please cancel`);
    }



  }

  editLoanProduct(payload) {
    this.loanProductService.updateLoanProduct(payload, this.selectedLoanProductId).subscribe((result) => {
      if (result?.successful) {
        this.toast.success($localize`:@@loan_product_updated_successfully:Loan product updated successfully`);
        this.disableAllFields();
        this.dialoRef.close(true);
      }
    });
  }

  addNewLoanProduct(payload) {
    this.loanProductService.createLoanProduct(payload).subscribe((result) => {
      if (result?.successful) {
        this.toast.success($localize`:@@loan_product_created_successfully:Loan product created successfully`);
        this.dialoRef.close(true);
      }
    });
  }

  cancel() {
    this.dialoRef.close()
  }

  selectedLoanTenorUnit(event) {
    switch (event.value) {
      case 'DAY':
        this.repaymentFrequencyOptions = [{ label: 'Day', value: 'DAY' }];
        break;
      case 'WEEK':
        this.repaymentFrequencyOptions = [
          { label: 'Day', value: 'DAY' },
          { label: 'Week', value: 'WEEK' },
        ];
        break;
      case 'YEAR':
        this.repaymentFrequencyOptions = [
          { label: 'Day', value: 'DAY' },
          { label: 'Week', value: 'WEEK' },
          { label: 'Month', value: 'MONTH' },
          { label: 'Year', value: 'YEAR' },
        ];
        break;
      default:
        this.repaymentFrequencyOptions = [
          { label: 'Day', value: 'DAY' },
          { label: 'Week', value: 'WEEK' },
          { label: 'Month', value: 'MONTH' },
        ];
    }

    const selectedRepayment = this.loanForm.get('loanRepaymentFrequencyPeriodUnit').value;

    this.validateMinMaxInputs(this.loanForm, { default: 'defaultLoanPeriodLength', min: 'minimumLoanPeriodLength', max: 'maximumLoanPeriodLength', frequency: 'loanRepaymentFrequency' }, 'loan tenor', true)
  }

  selectGracePeriodUnit(event) {
    switch (event.value) {
      case 'DAY':
        this.gracePeriodUnits = [{ label: 'Day', value: 'DAY' }];
        break;
      case 'WEEK':
        this.gracePeriodUnits = [
          { label: 'Day', value: 'DAY' },
          { label: 'Week', value: 'WEEK' },
        ];
        break;
      case 'YEAR':
        this.gracePeriodUnits = [
          { label: 'Day', value: 'DAY' },
          { label: 'Week', value: 'WEEK' },
          { label: 'Month', value: 'MONTH' },
          { label: 'Year', value: 'YEAR' },
        ];
        break;
      default:
        this.gracePeriodUnits = [
          { label: 'Day', value: 'DAY' },
          { label: 'Week', value: 'WEEK' },
          { label: 'Month', value: 'MONTH' },
        ];
    }
  }

  validateMinMaxInputs(
    form: FormGroup,
    controls: { default: string; min: string; max: string; frequency?: string },
    field: string,
    compareUnit?: boolean,
    repaymentFrequency?: boolean
  ) {


    setTimeout(() => {
      const repaymentVal = this.loanForm.get('loanRepaymentFrequencyPeriodUnit').value;
      this.selectGracePeriodUnit({ value: repaymentVal });
    }, 10)

    let defaultValue = form.get(controls.default).value;
    let minValue = form.get(controls.min).value;
    let maxValue = form.get(controls.max).value;

    if (repaymentFrequency) {
      defaultValue = this.loanForm.get('loanRepaymentFrequency').value;
      minValue = this.loanForm.get('minimumLoanRepaymentFrequency').value;
      maxValue = this.loanForm.get('maximumLoanRepaymentFrequency').value;
    }

    // Check if the values are strings before attempting to use .replace
    this.cleanMinMaxInput(defaultValue, minValue, maxValue);

    // const defaultValue = form
    //   .get(controls.default)
    //   .value?.replace(/[^0-9.]/g, '');
    // const minValue = form.get(controls.min).value?.replace(/[^0-9.]/g, '');
    // const maxValue = form.get(controls.max).value?.replace(/[^0-9.]/g, '');


    if (minValue && defaultValue && Number(defaultValue) < Number(minValue)) {
      this.toast.error(
        `Default ${field} can not be less than minimum ${field}`
      );
      this.formValid = false;
      return;
    }

    if (maxValue && defaultValue && Number(defaultValue) > Number(maxValue)) {
      this.toast.error(
        `Default ${field} can not be greater than maximum ${field}`
      );
      this.formValid = false;
      return;
    }

    if (minValue && maxValue && Number(minValue) > Number(maxValue)) {
      this.toast.error(
        `Minimum ${field} can not be greater than maximum ${field}`
      );
      this.formValid = false;
      return;
    }

    this.formValid = true;

    if (compareUnit) {
      if (repaymentFrequency) {
        defaultValue = form.get(controls.default).value;
        minValue = form.get(controls.min).value;
        maxValue = form.get(controls.max).value;

        this.cleanMinMaxInput(defaultValue, minValue, maxValue);
      }
      const freqValue = form.get(controls.frequency).value.replace(/[^0-9.]/g, '');
      const freqUnit = this.loanFormCtrl['loanRepaymentFrequencyPeriodUnit'].value.toLowerCase()
      switch (this.loanFormCtrl['loanPeriodLengthUnit'].value.toLowerCase()) {
        case 'day':
          {
            if (minValue && minValue < freqValue) {
              this.toast.error(
                `Minimum ${field} can not be less than Repayment Frequency`
              );
              this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
              this.formValid = false;
            } else if (freqValue && ((defaultValue % freqValue) !== 0)) {
              this.toast.error(
                `Repayment frequency not consistent with loan tenor`
              );
              this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
              this.formValid = false;
            } else {
              this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
              this.formValid = true;
            }
          }
          break;
        case 'week':
          {
            switch (freqUnit) {
              case 'day':
                {
                  if (minValue && minValue < (freqValue / this.businessDays.day.week)) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && (((defaultValue * this.businessDays.day.week) % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
                break;
              case 'week':
                {
                  if (minValue && minValue < freqValue) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && ((defaultValue % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
            }
          }
          break;
        case 'month':
          {
            switch (freqUnit) {
              case 'day':
                {
                  if (minValue && minValue < (freqValue / this.businessDays.day.month)) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && (((defaultValue * this.businessDays.day.month) % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
                break;
              case 'week':
                {
                  if (minValue && minValue < (freqValue / this.businessDays.week.month)) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && (((defaultValue * this.businessDays.week.month) % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
                break;
              case 'month':
                {
                  if (minValue && minValue < freqValue) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && ((defaultValue % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
            }
          }
          break;
        case 'year':
          {
            switch (freqUnit) {
              case 'day':
                {
                  if (minValue && minValue < (freqValue / this.businessDays.day.year)) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && (((defaultValue * this.businessDays.day.year) % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
                break;
              case 'week':
                {
                  if (minValue && minValue < (freqValue / this.businessDays.week.year)) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && (((defaultValue * this.businessDays.week.year) % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
                break;
              case 'month':
                {
                  if (minValue && minValue < (freqValue / this.businessDays.month.year)) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && (((defaultValue * this.businessDays.month.year) % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
                break;
              case 'year':
                {
                  if (minValue && minValue < freqValue) {
                    this.toast.error(
                      `Minimum ${field} can not be less than Repayment Frequency`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  }
                  else if (freqValue && ((defaultValue % freqValue) !== 0)) {
                    this.toast.error(
                      `Repayment frequency not consistent with loan tenor`
                    );
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors({ 'incorrect': true });
                    this.formValid = false;
                  } else {
                    this.loanFormCtrl['loanRepaymentFrequency'].setErrors(null);
                    this.formValid = true;
                  }
                }
            }
          }
      }
      // return
    }
  }

  rateListener(form: FormGroup, control: string) {
    form
      .get(control)
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        filter((value) => typeof value === 'string' && !!value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => {
        const amount = value.replace(/[^0-9.]/g, '');
        form.patchValue(
          {
            [control]: amount,
          },
          { emitEvent: false }
        );
      });
  }

  amountRateListener(
    form: FormGroup,
    conditionControl: string,
    control: string
  ) {
    if (form.get(control)) {
      form
        .get(control)
        .valueChanges.pipe(
          takeUntil(this.unsubscribe$),
          filter((value) => !!value)
        )
        .subscribe((value) => {
          const amount = value.replace(/[^0-9.]/g, '');
          if (form.get(conditionControl).value === 'FLAT') {
            form.patchValue(
              {
                [control]: this.formatCurrency('₦', amount),
              },
              { emitEvent: false }
            );
          } else {
            form.patchValue(
              {
                [control]: amount,
              },
              { emitEvent: false }
            );
          }
        });
    }
  }

  private formatCurrency(currencySymbol, amount) {
    return `${currencySymbol}${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  private formatRate(amount) {
    return `${amount}%`;
  }

  public isPercentRange(
    form: FormGroup,
    conditionControl: string,
    control: string
  ) {
    let rate;
    if (form.get(control)) {
      rate = form.get(control).value.replace(/[^0-9\.]/g, '');
    }
    if (form.get(conditionControl).value !== 'FLAT' && Number(rate) > 100) {
      this.toast.error('Rate can not be greater than 100');
      this.formValid = false;
      return;
    }

    this.formValid = true;
  }

  cleanMinMaxInput(defaultValue, minValue, maxValue) {
    if (typeof defaultValue === 'string') {
      defaultValue = defaultValue.replace(/[^0-9.]/g, '');
    }
    if (typeof defaultValue === 'number') {
      defaultValue = defaultValue.toString().replace(/[^0-9.]/g, '');
    }

    if (typeof minValue === 'string') {
      minValue = minValue.replace(/[^0-9.]/g, '');
    }
    if (typeof minValue === 'number') {
      minValue = minValue.toString().replace(/[^0-9.]/g, '');
    }

    if (typeof maxValue === 'string') {
      maxValue = maxValue.replace(/[^0-9.]/g, '');
    }
    if (typeof maxValue === 'number') {
      maxValue = maxValue.toString().replace(/[^0-9.]/g, '');
    }
  }

  checkCompulsorySavingsType(ev) {
    const value = ev.value;

    if (
      value.toLowerCase() === 'flat' ||
      value.toLowerCase().includes('percentage')
    ) {
      if (!this.loanForm.contains('savingsProductId'))
        this.loanForm.addControl(
          'savingsProductId',
          new FormControl('', [Validators.required])
        );

      if (!this.loanForm.contains('compulsorySavingsAmount'))
        this.loanForm.addControl(
          'compulsorySavingsAmount',
          new FormControl('', [Validators.required])
        );
    } else {
      if (this.loanForm.contains('savingsProductId'))
        this.loanForm.removeControl('savingsProductId');

      if (this.loanForm.contains('compulsorySavingsAmount'))
        this.loanForm.removeControl('compulsorySavingsAmount');
    }

    if (this.loanForm.contains('compulsorySavingsAmount'))
      this.loanForm.get('compulsorySavingsAmount').reset(null);

    this.amountRateListener(
      this.loanForm,
      'compulsorySavingsType',
      'compulsorySavingsAmount'
    );
  }

  checkPrepaymentPenaltyType(ev) {
    const value = ev.value;

    if (
      value.toLowerCase() === 'flat' ||
      value.toLowerCase().includes('percentage')
    ) {
      if (!this.loanForm.contains('prepaymentPenaltyAmount'))
        this.loanForm.addControl(
          'prepaymentPenaltyAmount',
          new FormControl('', Validators.required)
        );
    } else {
      if (this.loanForm.contains('prepaymentPenaltyAmount'))
        this.loanForm.removeControl('prepaymentPenaltyAmount');
    }

    if (this.loanForm.contains('prepaymentPenaltyAmount'))
      this.loanForm.get('prepaymentPenaltyAmount').reset(null);

    this.amountRateListener(
      this.loanForm,
      'prepaymentPenaltyType',
      'prepaymentPenaltyAmount'
    );
  }

  checkAccountingRule(ev) {
    const value = ev.value;

    value.toLowerCase() === 'accrual'
      ? (this.accountCopy.cash = {
        ...this.accountingForm.value,
        accountingType: 'CASH',
      })
      : (this.accountCopy.accrual = {
        ...this.accountingForm.value,
        accountingType: 'ACCRUAL',
      });

    if (value === 'ACCRUAL') {
      this.accountingForm.setControl(
        'interestReceivableAccountId',
        new FormControl('', Validators.required)
      );
      this.accountingForm.setControl(
        'penaltyReceivableAccountId',
        new FormControl('', Validators.required)
      );
      this.accountingForm.setControl(
        'feeReceivableAccountId',
        new FormControl('', Validators.required)
      );

      this.accountingForm.reset({
        ...this.accountCopy.accrual,
        accountingType: 'ACCRUAL',
      });
    } else {
      if (this.accountingForm.contains('interestReceivableAccountId'))
        this.accountingForm.removeControl('interestReceivableAccountId');
      if (this.accountingForm.contains('penaltyReceivableAccountId'))
        this.accountingForm.removeControl('penaltyReceivableAccountId');
      if (this.accountingForm.contains('feeReceivableAccountId'))
        this.accountingForm.removeControl('feeReceivableAccountId');

      this.accountingForm.reset({
        ...this.accountCopy.cash,
        accountingType: 'CASH',
      });
    }

  }

  public onTriggerTitleActionBtn(emittedValue: string) {
    if (emittedValue === 'back') return history.back();
  }

  sanitizePayload(payload) {
    if (
      payload?.compulsorySavingsAmount &&
      payload?.compulsorySavingsType === 'FLAT'
    )
      payload.compulsorySavingsAmount = this.utils.sanitizeCurrency(
        payload?.compulsorySavingsAmount
      );
    else if (
      payload?.compulsorySavingsAmount &&
      payload?.compulsorySavingsType === 'PERCENTAGE'
    )
      payload.compulsorySavingsAmount = this.utils.sanitizePercentage(
        payload?.compulsorySavingsAmount
      );

    if (
      payload?.prepaymentPenaltyAmount &&
      payload?.prepaymentPenaltyType === 'FLAT'
    )
      payload.prepaymentPenaltyAmount = this.utils.sanitizeCurrency(
        payload?.prepaymentPenaltyAmount
      );
    else if (
      payload?.prepaymentPenaltyAmount &&
      payload?.prepaymentPenaltyType === 'PERCENTAGE'
    )
      payload.prepaymentPenaltyAmount = this.utils.sanitizePercentage(
        payload?.prepaymentPenaltyAmount
      );

    payload.defaultInterestRate = this.utils.sanitizePercentage(
      payload?.defaultInterestRate
    );
    payload.minimumInterestRate = this.utils.sanitizePercentage(
      payload?.minimumInterestRate
    );
    payload.maximumInterestRate = this.utils.sanitizePercentage(
      payload?.maximumInterestRate
    );

    payload.defaultPrincipal = this.utils.sanitizeCurrency(
      payload?.defaultPrincipal
    );
    payload.minimumPrincipal = this.utils.sanitizeCurrency(
      payload?.minimumPrincipal
    );
    payload.maximumPrincipal = this.utils.sanitizeCurrency(
      payload?.maximumPrincipal
    );

    const fundSourceGl = [];
    payload.fundSources.forEach((fundId) => {
      const fundSources = this.fundSources.find(
        (fundSource) => fundSource.id === fundId
      );
      fundSourceGl.push({
        accountId: fundSources?.id,
        accountName: fundSources?.name,
      });
    });

    payload.fundSources = fundSourceGl;
  }

  selectCalendarType(event) {
    switch (event.value) {
      case 'CALENDAR':
        this.businessDays = this.calendarDays
        break;
      case 'WORKING':
        this.businessDays = this.workingDays
    }

    this.validateMinMaxInputs(this.loanForm, { default: 'defaultLoanPeriodLength', min: 'minimumLoanPeriodLength', max: 'maximumLoanPeriodLength', frequency: 'loanRepaymentFrequency' }, 'loan tenor', true)
  }

  isFormValueChanged(newFormValue: any, initialFormValue: any): boolean {
    return JSON.stringify(newFormValue) !== JSON.stringify(initialFormValue);
  }

  isMergedRowArrayChanged(newMergedRowArray: AccountingTransaction[], initialMergedRowArray: any): boolean {
    return JSON.stringify(newMergedRowArray) !== JSON.stringify(initialMergedRowArray);
  }

  disableAllFields() {
    this.basicForm.disable();
    this.loanForm.disable();
    this.isDisabled = true;
  }

  enableAllFields() {
    this.basicForm.enable();
    this.loanForm.enable();
    this.isDisabled = false;
  }

  editForm(type: 'edit' | 'save') {
    if (type === 'edit') return this.enableAllFields();
    this.submitForm()
  }

}
