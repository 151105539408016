import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoanProductService {

  baseAccountUrl: string = environment.baseUrl + environment.accountUrl;
  public searchLoanProductsEndpoint = `${this.baseAccountUrl}loan-products?page=0&pageSize=500&state=APPROVED&sort-order=DESC&filter=`

  constructor(private http: HttpClient) { }

  public createLoanProduct(data) {
    return this.http.post<any>(this.baseAccountUrl + 'loan-products', data);
  }

  public getLoanProducts(params: string):Observable<any> {
    return this.http.get(`${this.baseAccountUrl}loan-products?${params}`);
  }

  public searchLoanProducts(param: string):Observable<any> {
    return this.http.get(`${this.baseAccountUrl}loan-products?page=0&pageSize=500&status=APPROVED&sort-field=&sort-order=DESC&filter=${param}`);
  }

  public getLoanProduct(loanProductId) {
    return this.http.get(`${this.baseAccountUrl}loan-products/${loanProductId}`);
  }
  public getEditedLoanProduct(loanProductId) {
    return this.http.get(`${this.baseAccountUrl}loan-products/${loanProductId}/compare`);
  }
  public toggleEditedLoanProduct(loanProductId, state):Observable<any> {
    return this.http.put(`${this.baseAccountUrl}loan-products/${loanProductId}/approve-edit`,{state});
  }
  

  public updateLoanProduct(data, loanProductId: number) {
    return this.http.put<any>(`${this.baseAccountUrl}loan-products/${loanProductId}`, data);
  }

  public toggleLoanProducts(data:any): Observable<any>{
    return this.http.put<any>(`${this.baseAccountUrl}loan-products/toggle`, data);
  }

  public getEventMappings():Observable<any> {
    return this.http.get(`${this.baseAccountUrl}loan-products/mappings`);
  }

  public compareLoanProducts(loanProductId: number): Observable<any>{
    return this.http.get<any>(`${this.baseAccountUrl}loan-products/${loanProductId}/compare`);
  }

}
