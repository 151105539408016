import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';
import { ClientService } from '../pages/client-management/services/client.service';
import { GroupService } from '../pages/client-management/services/group.service';
import { ChargeService } from '../pages/settings/services/charges.service';
import { DepositProductService } from '../pages/settings/services/deposit-products.service';
import { GlAccountService } from '../pages/settings/services/gl.service';
import { GuarantorService } from '../pages/loan-management/services/guarantor.service';
import { StaffService } from '../pages/staff-management/services/staff.service';
import { PageRequest } from '../shared/models/common';
import { AddressService } from '../shared/services/address.service';
import { DataService } from '../shared/services/data.service';
import { DepositService } from '../pages/deposit/services/deposit.service';
import { LoanProductService } from '../pages/settings/services/loan-product.service';
import { LoanService } from '../pages/loan-management/services/loan.service';
import { IDTypeRes } from '../shared/models/dependency';
import { PostingsService } from '../pages/loan-management/services/postings.service';
import { AccountingService } from '../pages/accounting/services/accounting.service';

@Injectable()
export class ResetPasswordResolver implements Resolve<Observable<any[]>> {
  constructor(private _auth: AuthService, private route: ActivatedRoute) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const token: any = route.queryParamMap.get('token');
    return this._auth.verifyToken(token);
  }
}

@Injectable()
export class GuarantorResolver implements Resolve<Observable<any[]>> {
  constructor(
    private _guarantor: GuarantorService,
    private route: ActivatedRoute
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    var id = route.paramMap.get('id');
    return this._guarantor.getGuarantor(parseInt(id));
  }
}

@Injectable()
export class DepositAccountResolver implements Resolve<Observable<any[]>> {
  constructor(
    private _deposit: DepositService,
    private route: ActivatedRoute
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const id = route.paramMap.get('id');    
    return this._deposit.getSavingAccount(parseInt(id));
  }
}
@Injectable()
export class ClientDepositAccountResolver implements Resolve<Observable<any[]>> {
  constructor(
    private _deposit: DepositService,
    private route: ActivatedRoute
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const pageRequest: PageRequest = {
      page: 0,
      pageSize: 500,
      state: 'APPROVED',
      sortField: 'lastUpdatedTime',
      sortOrder: 'DESC',
    };
    const id = route.paramMap.get('id');
    return this._deposit.getClientSavingAccounts(parseInt(id), pageRequest);
  }
}

@Injectable()
export class ClientLoanAccountResolver implements Resolve<Observable<any[]>> {
  constructor(
    private _loanService: LoanService,
    private route: ActivatedRoute
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const pageRequest: PageRequest = {
      page: 0,
      pageSize: 500,
      state: 'APPROVED',
      sortField: 'lastUpdatedTime',
      sortOrder: 'DESC',
    };
    const id = route.paramMap.get('id');
    return this._loanService.getClientLoanAccounts(parseInt(id), pageRequest);
  }
}
@Injectable()
export class LoanAccountResolver implements Resolve<Observable<any[]>> {
  constructor(
    private _loanService: LoanService,
    private route: ActivatedRoute
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const id = route.paramMap.get('id');
    return this._loanService.getLoanAccount(parseInt(id));
  }
}
@Injectable()
export class ClientResolver implements Resolve<Observable<any[]>> {
  constructor(private _client: ClientService, private route: ActivatedRoute) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const id = route.paramMap.get('id');
    return this._client.getClient(parseInt(id));
  }
}

@Injectable()
export class StaffResolver implements Resolve<Observable<any[]>> {
  constructor(private _staff: StaffService, private route: ActivatedRoute) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const id: number = parseInt(route.paramMap.get('id'));
    return this._staff.getStaff(id);
  }
}

@Injectable()
export class ClientGroupResolver implements Resolve<Observable<any[]>> {
  constructor(private _group: GroupService, private route: ActivatedRoute) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    var id = route.paramMap.get('id');
    return this._group.getGroup(parseInt(id));
  }
}

@Injectable()
export class GroupResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getGroup();
  }
}
@Injectable()
export class GroupFirstFiveHundredResolver
  implements Resolve<Observable<any[]>>
{
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const pageRequest: PageRequest = {
      page: 0,
      pageSize: 1000,
      state: 'approved',
      sortField: 'last_update_time',
      sortOrder: 'DESC',
    };
    return this._data.getGroup(pageRequest);
  }
}

@Injectable()
export class StateResolver implements Resolve<Observable<IDTypeRes>> {
  constructor(private _address: AddressService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IDTypeRes> {
    return this._address.getState();
  }
}

@Injectable()
export class LoanOfficerResolver implements Resolve<Observable<any[]>> {
    constructor(private _data: DataService){ }
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
        return this._data.getLoanOfficers();
    }
}

@Injectable()
export class GenderResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getData('gender');
  }
}

@Injectable()
export class GroupRoleResolver implements Resolve<Observable<any[]>> {
  constructor(private dataService: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this.dataService.getData('group-role');
  }
}

@Injectable()
export class IdTypeResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService, private authService: AuthService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const tenantId = this.authService.getuser.tenantId;
    return this._data.getIdType(tenantId);
  }
}


@Injectable()
export class BusinessTypeResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getBusinessType();
  }
}

@Injectable()
export class BankResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getBank();
  }
}

@Injectable()
export class BranchResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    if(localStorage.getItem('branches') === null) {
      this._data.getBranches().subscribe(
        res => {
          localStorage.setItem('branches', JSON.stringify(res));
        }
      )
      return this._data.getBranches();
    }
    return of(JSON.parse(localStorage.getItem('branches')));
  }
}

@Injectable()
export class UserBranchResolver implements Resolve<Observable<any[]>> {
  constructor(private _group: GroupService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    if(localStorage.getItem('userBranch') === null) {
      const userProfile = JSON.parse(localStorage.getItem('pf'))
      const userGroupId = userProfile.branch.id
      this._group.getGroupByBranch(userGroupId).subscribe(
        res => {
          localStorage.setItem('userBranch', JSON.stringify(res));
        }
      )
      return this._group.getGroupByBranch(userGroupId);
    }
    return of(JSON.parse(localStorage.getItem('userBranch')));
  }
}

@Injectable()
export class ApprovedAreasResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getApprovedAreas();
  }
}

@Injectable()
export class NonUserStaffsResolver implements Resolve<Observable<any[]>> {
  constructor(private _staff: StaffService) {}
  public resolve(): Observable<any[]> {
    return this._staff.getNonUserStaffs();
  }
}

@Injectable()
export class UserRoleResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getRoles();
  }
}

@Injectable()
export class RolesResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getRoles();
  }
}

@Injectable()
export class GroupPostingResolver implements Resolve<Observable<any[]>> {
  constructor(private service: PostingsService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const id = route.params['id'];
    return this.service.getGroupPosting(id);
  }
}

@Injectable()
export class RoleResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const id = route.params['id'];
    return this._data.getRole(id);
  }
}

@Injectable()
export class PermissionsResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getPermissions();
  }
}

@Injectable()
export class AccountsResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getAccounts();
  }
}

@Injectable()
export class LoanProductResolver implements Resolve<Observable<any[]>> {
  constructor(
    private _data: DataService,
    private loanProducts: LoanProductService
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const pageRequest: PageRequest = {
      page: 0,
      pageSize: 500,
      state: 'APPROVED',
      sortField: '',
      sortOrder: 'DESC',
      filter: '',
    };
    const request = `page=${pageRequest.page}&pageSize=${pageRequest.pageSize}&status=${pageRequest.state}&sort-field=${pageRequest.sortField}&sort-order=${pageRequest.sortOrder}&filter=${pageRequest.filter}`;
    return this.loanProducts.getLoanProducts(request);
  }
}
@Injectable()
export class SavingsProductResolver implements Resolve<Observable<any[]>> {
  constructor(
    private _data: DataService,
    private depositProduct: DepositProductService
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this.depositProduct.getAllRecordsUnpaged();
  }
}

@Injectable()
export class getGlTypeResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService, private route: ActivatedRoute) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getGlType();
  }
}

@Injectable()
export class getGlAccountListsResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: AccountingService, private route: ActivatedRoute) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getGlAccountLists();
  }
}

@Injectable()
export class getDepositProductsInitialDataResolver
  implements Resolve<Observable<any[]>>
{
  constructor(private _data: DataService, private route: ActivatedRoute) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getGlType();
  }
}

@Injectable()
export class ChargesResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getCharges();
  }
}

@Injectable()
export class PenaltyChargesResolver implements Resolve<Observable<any[]>> {
  constructor(private _data: DataService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    return this._data.getPenaltyCharges();
  }
}

@Injectable()
export class MappedGlAccountsResolver implements Resolve<Observable<any[]>> {
  constructor(private _glAccount: GlAccountService) {}
  public resolve(): Observable<any[]> {
    return this._glAccount.getMappedGlAccounts();
  }
}
