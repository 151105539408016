import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartLoaderComponent } from './chart-loader/chart-loader.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';

import {
  EyeInvisibleOutline,
  EyeFill,
  LeftCircleFill,
  RightCircleFill
} from '@ant-design/icons-angular/icons';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTableModule } from 'ng-zorro-antd/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '../utils/utils.module';
import { MomentModule } from 'ngx-moment';
import { ModalLockScreenComponent } from './modals/modal-lock-screen/modal-lock-screen.component';
import { ModalChangePasswordComponent } from './modals/modal-change-password/modal-change-password.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { NgxWigModule } from 'ngx-wig';
import { ColorPickerModule } from 'ngx-color-picker';
import { TermsAndConditionModalComponent } from './modals/terms-and-condition-modal/terms-and-condition-modal.component';
import { NewUpdateModalComponent } from './modals/new-update-modal/new-update-modal.component';
import { TableUiComponent } from './components/table-ui/table-ui.component';
import { PaginationUiComponent } from './components/pagination-ui/pagination-ui.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { ActionBarUiComponent } from './components/action-bar-ui/action-bar-ui.component';
import { SearchUiComponent } from './components/search-ui/search-ui.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS,
} from '@angular-material-components/datetime-picker';

import { TitleBarUiComponent } from './components/title-bar-ui/title-bar-ui.component';
import { StateUiComponent } from './components/state-ui/state-ui.component';
import { BreadcrumbUiComponent } from './components/breadcrumb-ui/breadcrumb-ui.component';
import { ImageUploadUiComponent } from './components/image-upload-ui/image-upload-ui.component';
import { ButtonIconUiComponent } from './components/button-icon-ui/button-icon-ui.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ButtonIcon2UiComponent } from './components/button-icon2-ui/button-icon2-ui.component';
import { ImageAvatarUiComponent } from './components/image-avatar-ui/image-avatar-ui.component';
import { BoardUiComponent } from './components/board-ui/board-ui.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { ReportsBoardComponent } from './components/reports-board/reports-board.component';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from '../../environments/environment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Board2UiComponent } from './components/board2-ui/board2-ui.component';
import { GeneratedReportTableComponent } from './components/generated-report-table/generated-report-table.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzInputModule } from 'ng-zorro-antd/input';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { AccountSummaryComponent } from './components/account-summary/account-summary.component';
import { AccountChargesComponent } from './components/account-charges/account-charges.component';
import { AccountRepaymentScheduleComponent } from './components/account-repayment-schedule/account-repayment-schedule.component';
import { AccountTransactionsComponent } from './components/account-transactions/account-transactions.component';
import { AccountDocumentsComponent } from './components/account-documents/account-documents.component';
import { AccountGuarantorsComponent } from './components/account-guarantors/account-guarantors.component';
import { AccountAuditsComponent } from './components/account-audits/account-audits.component';
import { ReasonModalComponent } from './modals/reason-modal/reason-modal.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { MatTabsModule } from '@angular/material/tabs';
import { NewDepositAccountComponent } from './modals/new-deposit-account/new-deposit-account.component';
import { NewLoanAccountComponent } from './modals/new-loan-account/new-loan-account.component';
import { StrictNumbersDirective } from '../utils/directives/strict-numbers.directive';
import { DecimalNumbersDirective } from '../utils/directives/decimal-numbers.directive';
import { RemoveSpacesDirective } from '../utils/directives/remove-spaces.directive';
import { CommentUiComponent } from './components/comment-ui/comment-ui.component';
import { CurrencyDirective } from '../utils/directives/currency.directive';
import { PercentDirective } from '../utils/directives/percent.directive';
import { ProceedModalComponent } from './modals/proceed-modal/proceed-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Table2UiComponent } from './components/table2-ui/table2-ui.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { ButtonImgViewerComponent } from './components/button-img-viewer/button-img-viewer.component';
import { ModalDocViewerComponent } from './modals/modal-doc-viewer/modal-doc-viewer.component';
import { MainLoadingOverlayComponent } from './components/main-loading-overlay/main-loading-overlay.component';
import { CanvasDrawComponent } from './modals/canvas-draw/canvas-draw.component';
import { InputModalComponent } from './modals/input-modal/input-modal.component';
import { PreviewSignatureComponent } from './modals/preview-signature/preview-signature.component';
import { Table3UiComponent } from './components/table3-ui/table3-ui.component';
import { FilterDisbursementFundsourcePipe, StripNegativeSignPipe, ValueIsNegativePipe } from '../utils/wizer.pipes';
import { NavChildUiComponent } from './components/nav-child-ui/nav-child-ui.component';
import { TableActionFilterComponent } from './components/table-action-filter/table-action-filter.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SearchSelectComponent } from './components/search-components/search-select/search-select.component';
import { ActionBar2UiComponent } from './components/action-bar2-ui/action-bar2-ui.component';
import { Table4UiComponent } from './components/table4-ui/table4-ui.component';
import { SearchSelectMultipleComponent } from './components/search-components/search-select-multiple/search-select-multiple.component';
import { ActionBar3Component } from './components/action-bar3/action-bar3.component';
import { AccordionUiComponent } from './components/accordion-ui/accordion-ui.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { UnhandledTransferUiComponent } from './components/unhandled-transfer-ui/unhandled-transfer-ui.component';
import { AreaSearchSelectComponent } from './components/search-components/area-search-select/area-search-select.component';
import { BranchSearchSelectComponent } from './components/search-components/branch-search-select/branch-search-select.component';
import { LoanOfficerSearchSelectComponent } from './components/search-components/loan-officer-search-select/loan-officer-search-select.component';
import { LoanProductSearchSelectComponent } from './components/search-components/loan-product-search-select/loan-product-search-select.component';
import { LoanStatusSearchSelectComponent } from './components/search-components/loan-status-search-select/loan-status-search-select.component';
import { DepositProductSearchSelectComponent } from './components/search-components/deposit-product-search-select/deposit-product-search-select.component';
import { ClientSearchSelectComponent } from './components/search-components/client-search-select/client-search-select.component';
import { GroupSearchSelectComponent } from './components/search-components/group-search-select/group-search-select.component';
import { DragDropListComponent } from './components/drag-drop-list/drag-drop-list.component';
import { NewViewLoanProductComponent } from './modals/new-view-loan-product/new-view-loan-product.component';
import { NewViewDepositProductComponent } from './modals/new-view-deposit-product/new-view-deposit-product.component';
import { FileUploadUiComponent } from './components/file-upload-ui/file-upload-ui.component';
import { ModalHeaderUiComponent } from './components/modal-header-ui/modal-header-ui.component';
// import { NgxMatIntlTelInputComponent, NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { TitleCaseDirective } from '../utils/directives/title-case.directive';
import { SentenceCaseDirective } from '../utils/directives/sentence-case.directive';
import { SelectChargeIdComponent } from './modals/select-charge-id/select-charge-id.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ButtonToggleUiComponent } from './components/button-toggle-ui/button-toggle-ui.component';
import { UnhandledUiComponent } from './components/unhandled-ui/unhandled-ui.component';
import { CardUiComponent } from './components/card-ui/card-ui.component';
import { ChartLoaderIndicatorComponent } from './modals/chart-loader-indicator/chart-loader-indicator.component';
import { NoDataToShowComponent } from './components/no-data-to-show/no-data-to-show.component';
import { HasPermissionsDirective } from '../utils/directives/has-permissions.directive';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { ButtonToggleUi2Component } from './components/button-toggle-ui2/button-toggle-ui2.component';
import { ClientDepositSearchSelectComponent } from './components/search-components/client-deposit-search-select/client-deposit-search-select.component';

// import { MY_DATE_FORMATS as MY_FORMATS } from '../../environments/environment';
// import { MomentDateAdapter } from '@angular/material-moment-adapter';

const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '₦',
  suffix: '',
  thousands: ',',
  nullable: false,
  min: 0,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

const antIcons: IconDefinition[] = [
  EyeInvisibleOutline,
  EyeFill,
  LeftCircleFill,
  RightCircleFill,
];

const INTL_DATE_INPUT_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hourCycle: 'h12',
  hour: '2-digit',
  minute: '2-digit',
};

// If using Moment
const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: INTL_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: INTL_DATE_INPUT_FORMAT,
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

const MATERIAL_MODULE = [
  MatTableModule,
  MatCheckboxModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonToggleModule,
  MatIconModule,
  MatTooltipModule,
  MatSelectModule,
  MatStepperModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatBottomSheetModule,
  MatNativeDateModule,
  MatDialogModule,
  MatGridListModule,
  MatMenuModule,
  MatDividerModule,
  MatTabsModule,
  MatRadioModule,
  MatProgressBarModule,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  MatStepperModule,
  MatDividerModule,
  MatTabsModule,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatExpansionModule,
  NgxMatSelectSearchModule,
  NgxIntlTelInputModule,
  NzCarouselModule,
];

@NgModule({
  declarations: [
    UnhandledUiComponent,
    ClientDepositSearchSelectComponent,
    ChartLoaderComponent,
    NotificationModalComponent,
    ModalLockScreenComponent,
    ModalChangePasswordComponent,
    LoadingOverlayComponent,
    TermsAndConditionModalComponent,
    NewUpdateModalComponent,
    TableUiComponent,
    ActionBarUiComponent,
    ActionBar2UiComponent,
    SearchUiComponent,
    PaginationUiComponent,
    SearchUiComponent,
    LoaderComponent,
    TitleBarUiComponent,
    StateUiComponent,
    BreadcrumbUiComponent,
    ImageUploadUiComponent,
    ButtonIconUiComponent,
    ButtonIcon2UiComponent,
    ImageAvatarUiComponent,
    BoardUiComponent,
    ReportsBoardComponent,
    Board2UiComponent,
    GeneratedReportTableComponent,
    SearchableSelectComponent,
    AccountSummaryComponent,
    AccountChargesComponent,
    AccountRepaymentScheduleComponent,
    AccountTransactionsComponent,
    AccountDocumentsComponent,
    AccountGuarantorsComponent,
    AccountAuditsComponent,
    ReasonModalComponent,
    NewDepositAccountComponent,
    NewLoanAccountComponent,
    RemoveSpacesDirective,
    DecimalNumbersDirective,
    StrictNumbersDirective,
    TitleCaseDirective,
    SentenceCaseDirective,
    CurrencyDirective,
    PercentDirective,
    CommentUiComponent,
    ProceedModalComponent,
    Table2UiComponent,
    ButtonImgViewerComponent,
    ModalDocViewerComponent,
    MainLoadingOverlayComponent,
    CanvasDrawComponent,
    InputModalComponent,
    PreviewSignatureComponent,
    Table3UiComponent,
    SearchSelectComponent,
    FilterDisbursementFundsourcePipe,
    NavChildUiComponent,
    TableActionFilterComponent,
    LoaderComponent,
    SearchSelectComponent,
    ActionBar2UiComponent,
    Table4UiComponent,
    SearchSelectMultipleComponent,
    ActionBar3Component,
    AccordionUiComponent,
    AccordionUiComponent,
    UnhandledTransferUiComponent,
    AreaSearchSelectComponent,
    BranchSearchSelectComponent,
    LoanOfficerSearchSelectComponent,
    LoanProductSearchSelectComponent,
    LoanStatusSearchSelectComponent,
    DepositProductSearchSelectComponent,
    ClientSearchSelectComponent,
    GroupSearchSelectComponent,
    DragDropListComponent,
    NewViewLoanProductComponent,
    NewViewDepositProductComponent,
    FileUploadUiComponent,
    ModalHeaderUiComponent,
    SelectChargeIdComponent,
    ButtonToggleUiComponent,
    ButtonToggleUiComponent,
    CardUiComponent,
    ChartLoaderIndicatorComponent,
    ChartLoaderIndicatorComponent,
    NoDataToShowComponent,
    HasPermissionsDirective,
    CanvasDrawComponent,
    CustomInputComponent,
    ButtonToggleUi2Component,
    ValueIsNegativePipe,
    StripNegativeSignPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    NgxDocViewerModule,
    FormsModule,
    UtilsModule,
    MomentModule,
    NgxWigModule,
    SlickCarouselModule,
    ColorPickerModule,
    NzTableModule,
    NzTimePickerModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    DragDropModule,
    NzInputModule,
    NzIconModule.forChild(antIcons),
    ...[MATERIAL_MODULE],
  ],
  exports: [
    ChartLoaderComponent,
    NotificationModalComponent,
    NzDatePickerModule,
    SlickCarouselModule,
    FormsModule,
    UtilsModule,
    MomentModule,
    Table2UiComponent,
    LoaderComponent,
    ModalChangePasswordComponent,
    ModalLockScreenComponent,
    LoadingOverlayComponent,
    TermsAndConditionModalComponent,
    NewUpdateModalComponent,
    ClientDepositSearchSelectComponent,
    TableUiComponent,
    Table3UiComponent,
    Table4UiComponent,
    ActionBarUiComponent,
    ActionBar2UiComponent,
    SearchUiComponent,
    StateUiComponent,
    TitleBarUiComponent,
    BreadcrumbUiComponent,
    ImageUploadUiComponent,
    ButtonIconUiComponent,
    ReactiveFormsModule,
    ImageAvatarUiComponent,
    BoardUiComponent,
    Board2UiComponent,
    ReportsBoardComponent,
    GeneratedReportTableComponent,
    SearchableSelectComponent,
    AccountSummaryComponent,
    AccountChargesComponent,
    AccountRepaymentScheduleComponent,
    AccountTransactionsComponent,
    AccountDocumentsComponent,
    AccountGuarantorsComponent,
    NzTimePickerModule,
    AccountAuditsComponent,
    NewDepositAccountComponent,
    ButtonImgViewerComponent,
    ModalDocViewerComponent,
    RemoveSpacesDirective,
    DecimalNumbersDirective,
    StrictNumbersDirective,
    TitleCaseDirective,
    SentenceCaseDirective,
    CurrencyDirective,
    PercentDirective,
    MainLoadingOverlayComponent,
    DragDropModule,
    NgxCurrencyModule,
    NavChildUiComponent,
    TableActionFilterComponent,
    DragDropListComponent,
    LoaderComponent,
    SearchSelectComponent,
    SearchSelectMultipleComponent,
    AreaSearchSelectComponent,
    BranchSearchSelectComponent,
    LoanOfficerSearchSelectComponent,
    LoanProductSearchSelectComponent,
    LoanStatusSearchSelectComponent,
    DepositProductSearchSelectComponent,
    GroupSearchSelectComponent,
    ActionBar3Component,
    AccordionUiComponent,
    AccordionUiComponent,
    UnhandledTransferUiComponent,
    ModalHeaderUiComponent,
    FileUploadUiComponent,
    ButtonToggleUiComponent,
    CardUiComponent,
    ClientSearchSelectComponent,
    ChartLoaderIndicatorComponent,
    CanvasDrawComponent,
    NoDataToShowComponent,
    CustomInputComponent,
    HasPermissionsDirective,
    NzIconModule,
    ButtonToggleUi2Component,
    ...[MATERIAL_MODULE],
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
  ],
})
export class SharedModule {}
