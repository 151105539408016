import { Component, Input, OnInit } from '@angular/core';
import { ItemType, KeyValueType } from '../../models/common';

@Component({
  selector: 'app-board2-ui',
  templateUrl: './board2-ui.component.html',
  styleUrls: ['./board2-ui.component.css']
})
export class Board2UiComponent {
  @Input() alignContent:'vertical' | 'horizontal' = 'vertical'
  @Input() items: KeyValueType[] = [];
  @Input() classNames: string

  constructor() { }

  isString(val): boolean { 
    return typeof val === 'string'; 
  }

}
