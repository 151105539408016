/** A Table object that can be used to generate a table on the frontend using the @link ReportTableComponent
 *
 */
 export class Table {
    /**Optional title of the table. Will be displayed on the view*/
    title: string = "";
    /** Classes to be set on the table*/
    tableClass: string = ""; //
    /**Extra headers and footers to be stacked on the main column header */
    extraHeaders: Array<Column[]> = [];
    /**Columns that form the main header*/
    columns: Array<Column> = [];
    /** Should be first column be fixed*/
    firstColumnFixed: boolean = false;
    /** the rows of data to be rendered in nthe table*/
    rows: TableRow[] = [];
  }
  
  /** Contains an array of data to be displayed on the table from left to right
   *
   */
  export class TableRow {
    data: any[] = [];
  }
  
  /** Defines a column/header.
   * @param columnName: the name of the column. this is displayed in the html header
   * @param colType: the type of the column. this helps determine how to render a particular column's data elements
   * @param columnSpan: how many columns should this header span
   */
  export class Column {
  
    constructor(public columnName: string, public colType: ColumnType, public columnSpan: number = 1){
    }
  
    /** Returns true if this column's data elements should be aligned to the right. this is usually true
     * for decimals and monetary values
     */
    public isRightAligned(): boolean {
      return this.colType == ColumnType.MONEY || this.colType == ColumnType.DECIMAL || this.colType == ColumnType.INTEGER;
    }
  }
  
  /** Enum of possible Column Types
   *
   */
  export enum ColumnType {
    INTEGER, MONEY, STRING, DECIMAL, PERCENTAGE
  }
  