<div class="flex flex-wrap gap-8">
    <app-interest-income-interest-expense></app-interest-income-interest-expense>

    <app-net-interest-income></app-net-interest-income>

    <app-fees-commissions></app-fees-commissions>

    <app-gross-earnings></app-gross-earnings>

    <app-staff-cost></app-staff-cost>
</div>