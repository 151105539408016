import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, merge, switchMap, takeUntil } from 'rxjs';
import { OperationalIndicatorService } from 'src/app/pages/dashboard/services/operational-indicator.service';
import { TopFilterService } from 'src/app/pages/dashboard/services/topFIlter.service';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-disbursed-loans',
  templateUrl: './disbursed-loans.component.html',
  styleUrls: ['./disbursed-loans.component.css']
})
export class DisbursedLoansComponent implements OnInit {
  public topFiltersFormInstance: FormGroup;
  public productId: any;
  public tenantId = JSON.parse(localStorage.getItem('pf') || null).tenantId;
  public numberOfTenantBranches: number = 0;

  public hideFilters: boolean = false
  public isLoading: boolean = false
  public showSortControl: boolean = false
  public disbursedLoansPalette: string[] = ["#4d5d76", "#93A1B1", "#C0C7D1", "#E5E8EC"];

  public disbursedLoanForm: FormGroup
  public disbursedLoanFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]
  public disbursedLoanChartData?: {name: string, value: number}[] = [];
  public disbursedLoanSortingOptions: { key: 'name' | 'value', sortingDirection: 'asc' | 'desc' | 'default' } = {
    key: 'name',
    sortingDirection: 'asc'
  };

  public subject = new Subject<number>();

  public form:FormGroup;


  public reportsInput = ReportInputTypes;

  public sortLabel = 'Sort';


  public sortOptions = [
    { label: 'Default', value: 'default'},
    { label: 'Value: Asc.', value: 'value-asc'},
    { label: 'Value: Desc.', value: 'value-desc'},
    { label: 'Name: Asc.', value: 'name-asc'},
    { label: 'Name: Desc.', value: 'name-desc'},
  ]




  constructor(
    private operationalIndicatorService: OperationalIndicatorService,
    private topFilterService: TopFilterService,
    private utils: UtilityService,
    private fb: FormBuilder
  ) { 
    this.topFiltersFormInstance = this.topFilterService.topFilterForm
  }

  ngOnInit(): void {
    this.initForm()
    this.getDisbursedLoansChartData();
    this.subject.next(Math.random());
  }



  private initForm() {
    this.form = this.fb.group({
      [ReportInputTypes.SORT]: new FormControl(`default`, [Validators.required]),
      [ReportInputTypes.LOAN_PRODUCT]: new FormControl(0, [Validators.required]),
    });
  }

  public get productCtrl(){
    return this.form.get(ReportInputTypes.LOAN_PRODUCT) as FormControl
  }

  getDisbursedLoansChartData() {
    

    merge(
      this.topFilterService.topFilterForm.valueChanges,
      this.subject.asObservable()
    )
      .pipe(
        switchMap(() => {
          const { areaId, branchId, loanOfficerId, startDate, endDate } = this.topFiltersFormInstance.value;
          const { productId, tenantId } = this;
      
          const queryParams = new URLSearchParams({
            startDate: this.utils.formatDate_custom_yearFirst(startDate),
            endDate: this.utils.formatDate_custom_yearFirst(endDate),
            ...(tenantId && { tenantId }),
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
            ...(productId && { productId }),
          });
        
          this.isLoading = true;
          return this.operationalIndicatorService.getDisbursedLoans(queryParams)
        })).subscribe(
      res => {
        this.disbursedLoanChartData = res.data.data.map((item:any) => ({
          name: item.branchName,
          value: item.amountDisbursed
        }));
        this.isLoading = false;

        this.numberOfTenantBranches = res.data.total[0].numberOfBranchesForTenant;
        if(this.disbursedLoanChartData?.length < 2) {
          this.showSortControl = false;
        }else {
          this.showSortControl = true;
        }
      }
    )
  }


  onSort(sortOption: any) {
    this.sortLabel = sortOption.label
    this.filterDisbursedLoans({value: sortOption.value, name: ReportInputTypes.SORT, type: ReportInputTypes.SORT })
  }

  filterDisbursedLoans(ev: any) {    
    switch(ev.type) {
      case ReportInputTypes.SORT:
        const selectedString = ev.value === 'default' ? 'name-default' : ev.value;
        const [key, sortingDirection] = selectedString.split('-');
        this.disbursedLoanSortingOptions = { key, sortingDirection };
        break;
      case ReportInputTypes.LOAN_PRODUCT:
        this.productId = ev.value;
        this.subject.next(Math.random());
        break;
    }
  }

  
}
