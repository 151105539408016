import { Component, OnInit, ViewChild } from '@angular/core';
import { WebSocketService } from 'src/app/utils/webSocket.service';
import { Permissions } from 'src/app/shared/dto/permissions';
import { StateTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  public state: string = 'operational_indicators';

  public states: StateTypes[] = [
    {
      name: 'Operational Indicators',
      value: 'operational_indicators',
      perms: [Permissions.NONE],
      isActive: true,
    },
    // {
    //   name: 'Financial Indicators',
    //   value: 'financial_indicator',
    //   perms: [Permissions.NONE],
    // },
    {
      name: 'User Activities',
      value: 'user_activity',
      perms: [Permissions.NONE],
    },
  ];

  constructor(
    private webSocketService: WebSocketService,
    
  ) {
    
  }
  public onStateChange(val: StateTypes['value']) {
    this.state = val;
  }

  ngOnInit(): void {
    this.webSocketService.storeIndexedDbData();
  }
}
