<div class="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8">
    <app-daily-repayment-disbursement></app-daily-repayment-disbursement>

    <app-running-loans></app-running-loans>

    <app-clients></app-clients>

    <app-deposit-balance></app-deposit-balance>

    <app-portfolio-at-risk></app-portfolio-at-risk>

    <app-write-offs></app-write-offs>

    <app-disbursed-loans></app-disbursed-loans>
    
    <app-disbursed-loans-count></app-disbursed-loans-count>

    <app-client-occupation></app-client-occupation>
</div>