<div class="flex flex-wrap items-center">
    <div class="flex gap-x-4" *ngIf="actionButtons?.length > 0">
        <ng-container *ngFor="let button of actionButtons">
            <!-- <button [matTooltip]="button.name" [disabled]="disableActionButtons" [ngClass]="evalClass(button.value)" class="w-[40px] h-[40px] text-white flex items-center justify-center rounded-full opacity-90 border-none font-semibold mx-1" *ngIf="hasPermission(button.perms)"
                (click)="actionToggled(button.value)">
      <i-feather
      [ngClass]="disableActionButtons ? '!cursor-not-allowed' : ''"
      class="!w-[20px] !h-[20px] exclude" [name]="evalIcon(button.value)"></i-feather>
      </button> -->
            <button [disabled]="disableActionButtons" [ngClass]="evalClass(button.value)" class="p-3 w-[80px] text-white rounded-xl opacity-90 border-none font-semibold mx-1" *hasPermissions="button.perms" (click)="actionToggled(button.value)">
        {{ button.name }}
      </button>
        </ng-container>
    </div>
</div>