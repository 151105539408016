<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <app-modal-header-ui [title]="title"></app-modal-header-ui>

    <div class="dialog-body">
        <form [formGroup]="reasonForm">
            <div class="flex flex-col my-4">
                <mat-form-field appearance="outline" class="textarea-input">
                    <mat-label>Reason</mat-label>
                    <textarea matInput appRemoveSpaces formControlName="reason" rows="6"></textarea>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="flex items-center justify-between mb-3">
        <app-button-icon-ui [buttonType]="'submit'" classNames="p-3 rounded-xl text-white bg-success/90 hover:bg-success w-[150px]" name="Submit" value="submit" (click)="submit()"></app-button-icon-ui>
        <app-button-icon-ui [buttonType]="'button'" classNames="p-3 rounded-xl border border-error opacity-80 hover:opacity-100 text-error w-[150px]" (clickEmit)="cancel()" name="Cancel" value="cancel"></app-button-icon-ui>
    </div>
</div>