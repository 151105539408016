import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IndexedDBInterface } from './interface/indexedDB.interface';
import { environment } from 'src/environments/environment';
import { objectStoreName } from './common/objectStoreName';

@Injectable({
  providedIn: 'root'
})
export class BranchIndexedDBService implements IndexedDBInterface {
  objectStoreName = objectStoreName.branchStoreName;
  dbName = environment.indexDBName;

  constructor() {}

  storeDataInIndexedDB(branches: { id: number, name: string, areaId: number }[]): void {
    const dbRequest = indexedDB.open(this.dbName);
    
    dbRequest.onsuccess = (event: any) => {
      const db = event.target.result;
      const transaction = db.transaction(this.objectStoreName, 'readwrite');
      const objectStore = transaction.objectStore(this.objectStoreName);

      branches.forEach((branch) => {
        objectStore.put(branch);
      });

      transaction.oncomplete = () => {
        // console.log('branches stored successfully');
      };

      transaction.onerror = (event) => {
        console.error('Error storing branches:', event.target.error);
      };
    };
  }

  getStoredData(): Observable<{ id: number, name: string }[]> {
    const dbRequest = indexedDB.open(this.dbName);

    return new Observable((observer) => {
      dbRequest.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(this.objectStoreName, 'readonly');
        const objectStore = transaction.objectStore(this.objectStoreName);
        const request = objectStore.getAll();

        request.onsuccess = (event) => {
          const branches = event.target.result as { id: number, name: string }[];
          observer.next(branches);
          observer.complete();
        };

        request.onerror = (event) => {
          console.error('Error retrieving branches:', event.target.error);
          observer.error(event.target.error);
        };
      };

      dbRequest.onerror = (event: any) => {
        console.error('Error opening database:', event.target.error);
        observer.error(event.target.error);
      };
    });
  }

  getStoredBranchesByAreaId(areaId: number): Observable<{ id: number, name: string }[]> {
    const dbRequest = indexedDB.open(this.dbName);

    return new Observable((observer) => {
      dbRequest.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(this.objectStoreName, 'readonly');
        const objectStore = transaction.objectStore(this.objectStoreName);
        const index = objectStore.index('areaIdIndex');
        const request = index.getAll(IDBKeyRange.only(areaId));

        request.onsuccess = (event) => {
          const branches = event.target.result as { id: number, name: string }[];
          observer.next(branches);
          observer.complete();
        };

        request.onerror = (event) => {
          console.error('Error retrieving branches by areaId:', event.target.error);
          observer.error(event.target.error);
        };
      };

      dbRequest.onerror = (event: any) => {
        console.error('Error opening database:', event.target.error);
        observer.error(event.target.error);
      };
    });
  }

}