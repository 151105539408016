import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data-to-show',
  templateUrl: './no-data-to-show.component.html',
  styleUrls: ['./no-data-to-show.component.css']
})
export class NoDataToShowComponent {

  constructor() { }

 

}
