<app-chart-wrapper [isLoading]="isLoading" [title]="'Clients'" (fullscreen)="hideFilters = $event">
    <!-- <app-chart-filter filter [form]="clientsForm" [formControls]="clientsFormControls" [legends]="legends" [showSortControl]="showSortControl" (filter)="filterClients($event)"></app-chart-filter> -->

    <!-- Filters -->
    <form filter [formGroup]="form" class="flex justify-between gap-1">
        <div class="legends">
            <p *ngFor="let legend of legends; let i = index" class="flex items-center gap-[3px] text-[11px]">
                <span class="indicator" [ngClass]="{ approved: i === 0, active: i === 1, pending: i === 2 }"></span> {{ legend.label }}:
                <span class="text-end ml-6 flex-1">{{ !legend.total ? '-' : (legend.total | number) }}</span>
            </p>
        </div>

        <div class="flex gap-2 w-[65%] justify-end">

            <ng-container *ngIf="showSortControl">
                <button mat-button [matMenuTriggerFor]="sorting" class="sort-btn">
                {{ sortLabel }}
                <img class="ml-2.5 h-5" src="assets/icons/sort-order.svg" alt="" />
              </button>
                <mat-menu #sorting="matMenu">
                    <button mat-menu-item *ngFor="let option of sortOptions" (click)="onSort(option)">
                  {{ option.label }}
                </button>
                </mat-menu>
            </ng-container>

            <app-loan-product-search-select [disabled]="hideFilters" class="w-[50%]" (emitSelectedVal)="filterClients({ type: reportsInput.LOAN_PRODUCT, value: $event?.id })" [control]="productCtrl" type="client">
            </app-loan-product-search-select>
        </div>


    </form>
    <!-- End Filters -->


    <app-grouped-column-chart chart *ngIf="showSortControl && clientsChartData.length && !isLoading" [chartData]="clientsChartData" [sortingOptions]="clientsSortingOptions">
    </app-grouped-column-chart>


    <app-doughnut-chart chart *ngIf="!showSortControl && clientsChartData.length && !isLoading" [chartData]="clientsChartData" [palette]="clientPalette">
    </app-doughnut-chart>

</app-chart-wrapper>