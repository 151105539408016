<mat-form-field class="w-[100%]" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-select (openedChange)="clickOut($event)" [formControl]="control" [placeholder]="placeholder" [multiple]="true" #singleSelect>
        <mat-option>
            <ngx-mat-select-search [alwaysRestoreSelectedOptionsMulti]="true" [placeholderLabel]="placeholder" noEntriesFoundLabel="'No match found'" [formControl]="filterCtrl" [showToggleAllCheckbox]="true" [toggleAllCheckboxChecked]="toggledAllCheckbox" toggleAllCheckboxTooltipMessage="Select / Unselect All"
                [toggleAllCheckboxIndeterminate]="isIndeterminate" (toggleAll)="toggleSelectAll($event)">
                <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
            </ngx-mat-select-search>
        </mat-option>
        <!-- <mat-option *ngIf="showDefaultListValue" [value]="0">All</mat-option> -->
        <mat-option *ngFor="let record of filteredList | async" [value]="record[keyValue['value']]">
            {{ keyValue["key"] | mergeMultiValues : record | titlecase }}
        </mat-option>
    </mat-select>
</mat-form-field>