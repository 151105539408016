import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ClientService } from 'src/app/pages/client-management/services/client.service';
import { PageRequest } from 'src/app/shared/models/common';
import { SearchSelectComponent } from '../search-select/search-select.component';

@Component({
  selector: 'app-client-search-select',
  templateUrl: './client-search-select.component.html',
  styleUrls: ['./client-search-select.component.css'],
})
export class ClientSearchSelectComponent implements OnInit, OnChanges {
  currentScrollPosition = 0;
  @ViewChild(SearchSelectComponent) childComponent: SearchSelectComponent;
  @Input() showDefaultListValue: boolean = false;
  @Input() defaultSelected: any;
  @Input() control: FormControl | string;
  @Input() areaId: number = 0;
  @Input() branchId: number = 0;
  @Input() type: 'client' | 'server' = 'server';
  @Input() clientData;
  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();

  public clientLabel = $localize`:@@client:Client`;
  public endpoint: string = this.clientService.searchClientByParamEndpoint();
  public clientKeyValue = {
    key: ['first_name', 'last_name'],
    value: 'id',
  };
  public pageRequest: PageRequest = {
    page: 0,
    pageSize: 10,
    state: 'APPROVED',
    sortField: 'last_update_time',
    sortOrder: 'DESC',
    filter:''
  };

  constructor(private clientService: ClientService, private ngZone: NgZone) {}
  ngOnInit(): void {
    if(this.type === 'client')
      this.getClients();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['branchId'] || changes['areaId']) {
      this.branchId = changes['branchId']
        ? changes['branchId'].currentValue
        : this.branchId;
      this.areaId = changes['areaId']
        ? changes['areaId'].currentValue
        : this.areaId;
      const param: any = {
        ...(this.areaId && { areaId: this.areaId }),
        ...(this.branchId && { branchId: this.branchId }),
      };
      const queryParam = new URLSearchParams(param).toString();
      if (changes['clientData']?.currentValue) {
        this.clientData = changes['clientData'].currentValue;
      } else {
        this.clientData = [];
      }
      this.endpoint =
        this.clientService.searchClientByParamEndpoint(queryParam);
    }
  }

  getClients() {
    this.clientService.getClients(this.pageRequest).subscribe((res) => {
      const mappedData = res.data.map(client => {
        return {
          ...client,
          name: `${client.first_name} ${client.last_name}`
        };
      });
      this.clientData = [...this.clientData, ...mappedData];

    })
  }

  emitSelected(value: { id: any; first_name: string; last_name: string }) {
    this.emitSelectedVal.emit(value);
  }

  loadAllOnScroll(event) {
    if(this.type === 'server')
      return;

    // Check if scrolled to the bottom
    if (Math.floor(event.target.scrollTop) === event.target.scrollHeight - event.target.clientHeight - 1) {
      this.pageRequest.page++;
      //this.childComponent.selectElem.panel.nativeElement.scrollTop = this.currentScrollPosition;
      this.setScrollPosition(event.target.scrollTop)
      
      this.getClients();
    }
  }

  setScrollPosition(scrollTop): void {
    if (!this.childComponent || !this.childComponent.selectElem || !this.childComponent.selectElem.panel) {
      return;
    }

    // Store the current scroll position
    this.currentScrollPosition = scrollTop === 0 ? this.currentScrollPosition : scrollTop;
  }
}
