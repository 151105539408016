import { Component, Input, OnInit } from '@angular/core';
import { CardType } from '../../models/common';



@Component({
  selector: 'app-card-ui',
  templateUrl: './card-ui.component.html',
  styleUrls: ['./card-ui.component.css'],
})
export class CardUiComponent {
  @Input() items:CardType<any>[] = [];
  effect = 'scrollx';
  constructor() {};

  public get isLoading(){
    return !this.items.find(val=>!val.isFetching)
  }
}
