<app-table-action-filter
  (searchEmit)="onSearch($event)"
></app-table-action-filter>

<app-table-ui
  (sortEmit)="onSort($event)"
  [data]="payload"
  [pageProp]="pageProp"
  [showPagination]="showPagination"
  (pageSizeToggle)="pageSizeToggle($event)"
  (PageCtrlToggle)="pageCtrlToggle($event)"
  [initColumns]="columns"
></app-table-ui>
