import { Menu } from 'src/app/shared/models/common';
import { Permissions } from 'src/app/shared/dto/permissions';

export function getSideMenus() {
  const AllMenu: Menu[] = [
    {
      label: $localize`:Sidebar dashboard|label for sidebar dashboard@@sidebar_dashboard:Dashboard`,
      link: '/',
      icon: 'chartPie',
      active: false,
      perm: [Permissions.NONE],
    },
    {
      label: $localize`:Sidebar reports|label for sidebar reports@@sidebar_reports:Reports`,
      icon: 'report',
      link: '/reports',
      active: false,
      perm: [Permissions.NONE],
    },
    {
      label: $localize`:Sidebar clients|label for sidebar clients@@sidebar_clients:Clients`,
      icon: 'clients',
      link: '/clients',
      active: false,
      perm: [Permissions.NONE],
    },
    // {
    //   label: $localize`:Sidebar franchisees|label for sidebar franchisees@@sidebar_franchisees:Franchisees`,
    //   icon: 'hierarchy',
    //   link: '/franchisees',
    //   active: false,
    //   perm: [Permissions.NONE],
    // },
    {
      label: $localize`:Sidebar loans|label for sidebar loans@@sidebar_loans:Loans`,
      icon: 'loan',
      link: '/loans',
      active: false,
      perm: [Permissions.NONE],
    },
    {
      label: $localize`:Sidebar deposit|label for sidebar deposit@@sidebar_deposit:Deposit`,
      link: '/deposits',
      icon: 'walletOutline',
      active: false,
      perm: [Permissions.NONE],
      // perm: [
      //   Permissions.CREATE_CLIENT_SAVINGS_ACCOUNT,
      //   Permissions.LIST_CLIENT_SAVINGS_ACCOUNT,
      //   Permissions.APPROVE_CLIENT_SAVINGS_ACCOUNT,
      //   Permissions.REJECT_CLIENT_SAVINGS_ACCOUNT,
      //   Permissions.POST_SAVINGS_ACCOUNT_DEPOSIT,
      //   Permissions.POST_SAVINGS_ACCOUNT_WITHDRAWAL,
      //   Permissions.POST_SAVINGS_FUND_TRANSFER,
      //   Permissions.REVERSE_SAVINGS_TRANSACTION,
      //   Permissions.DEPOSIT_DISABLE,
      //   Permissions.DEPOSIT_ENABLE,
      // ],
    },
    {
      label: $localize`:Sidebar staff|label for sidebar staff@@sidebar_staff:Staff`,
      icon: 'staff',
      link: '/staff',
      active: false,
      // perm: [Permissions.NONE],
      perm: [
        Permissions.LIST_STAFF,
        Permissions.CREATE_STAFF,
        Permissions.STAFF_APPROVE,
        Permissions.STAFF_REJECT,
        Permissions.STAFF_DISABLE,
        Permissions.STAFF_ENABLE,
      ],
    },
    {
      label: $localize`:Sidebar users|label for sidebar users@@sidebar_users:Users`,
      icon: 'users',
      link: '/users',
      active: false,
      // perm: [Permissions.NONE],
      perm: [
        Permissions.LIST_USERS,
        Permissions.CREATE_USER,
        Permissions.UPDATE_USER_ROLE,
        Permissions.USER_ACTIVATE,
        Permissions.USER_ENABLE,
        Permissions.USER_DISABLE
      ],
    },
    {
      label: $localize`:Sidebar transfer|label for sidebar transfer@@sidebar_transfer:Transfer`,
      icon: 'transfer',
      link: '/transfer',
      active: false,
      perm: [Permissions.NONE],
    },
    {
      label: $localize`:Sidebar accounting|label for sidebar accounting@@sidebar_accounting:Accounting`,
      icon: 'accounting',
      link: '/accounting',
      active: false,
      perm: [Permissions.NONE],
    },
    {
      label: $localize`:Sidebar settings|label for sidebar settings@@sidebar_settings:Settings`,
      icon: 'settings',
      link: '/settings',
      active: false,
      perm: [Permissions.NONE],
    },
    {
      label: $localize`:Sidebar support|label for sidebar support@@sidebar_support:Support`,
      icon: 'support',
      link: '/support',
      active: false,
      perm: [Permissions.NONE],
    },
    {
      label: $localize`:Sidebar updates|label for sidebar updates@@sidebar_updates:Updates`,
      icon: 'bell',
      link: '/updates',
      active: false,
      perm: [Permissions.NONE],
    },
    {
      label: $localize`:Sidebar logout|label for sidebar logout@@sidebar_logout:Logout`,
      icon: 'logout',
      link: 'logout',
      active: false,
      perm: [Permissions.NONE],
    },
  ];

  return AllMenu;
}
