import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CancelHttpRequestService {
  private cancelPendingRequestSubject = new Subject<void>()
  cancelPendingRequest$ = this.cancelPendingRequestSubject.asObservable();

  cancelPendingRequest(){
    this.cancelPendingRequestSubject.next();
  }
}