import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-user-activity-deposit-balance',
  templateUrl: './user-activity-deposit-balance.component.html',
  styleUrls: ['./user-activity-deposit-balance.component.css']
})
export class UserActivityDepositBalanceComponent implements OnInit {

  public hideFilters: boolean = false

  public depositBalanceForm: FormGroup
  public depositBalanceFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  filterDepositBalance(ev: any) {
  }

}
