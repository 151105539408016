import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginModule } from './auth/login/login.module';
import { ResetPasswordModule } from './auth/reset-password/reset-password.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { ResetPasswordResolver } from './resolvers/wizer.resolver';
import { Permissions } from 'src/app/shared/dto/permissions';
import { AuthGuard } from './shared/common/auth.guard';
import { PermissionGuard } from './shared/common/permission.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => DashboardModule,
  },
  {
    path: 'login',
    loadChildren: () => LoginModule,
    canLoad: [AuthGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => ResetPasswordModule,
    resolve: {
      resetPassword: ResetPasswordResolver,
    },
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./pages/client-management/clients.module').then(
        (m) => m.ClientsModule
      ),
    data: {
      perms: [Permissions.NONE],
    },
    // canLoad: [CanLoadRoutes],
    canLoad: [AuthGuard, PermissionGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/staff-management/user/user.module').then(
        (m) => m.UserModule
      ),
    data: {
      // perms: [Permissions.NONE],

      perms: [
        Permissions.LIST_USERS,
        Permissions.CREATE_USER,
        Permissions.UPDATE_USER_ROLE,
        Permissions.USER_ACTIVATE,
        Permissions.USER_ENABLE,
        Permissions.USER_DISABLE,
      ],
    },
    // canLoad: [CanLoadRoutes],
    canLoad: [AuthGuard, PermissionGuard],
    // canActivateChild: [CanActivateChildRoutes],
  },
  {
    path: 'staff',
    loadChildren: () =>
      import('./pages/staff-management/staff/staff.module').then(
        (m) => m.StaffModule
      ),
    data: {
      // perms: [Permissions.NONE],
      perms: [
        Permissions.CREATE_STAFF,
        Permissions.LIST_STAFF,
        Permissions.STAFF_APPROVE,
        Permissions.STAFF_REJECT,
        Permissions.STAFF_DISABLE,
        Permissions.STAFF_ENABLE,
      ],
    },
  },
  {
    path: 'loans',
    loadChildren: () =>
      import('./pages/loan-management/loan.module').then((m) => m.LoanModule),

    data: {
      perms: [Permissions.NONE],
    },
    // canLoad: [CanLoadRoutes],
    canLoad: [AuthGuard, PermissionGuard],
  },
  {
    path: 'deposits',
    loadChildren: () =>
      import('./pages/deposit/deposit.module').then((m) => m.DepositModule),
    data: {
      // perms: [Permissions.NONE],
      perms: [
        Permissions.CREATE_CLIENT_SAVINGS_ACCOUNT,
        Permissions.LIST_CLIENT_SAVINGS_ACCOUNT,
        Permissions.APPROVE_CLIENT_SAVINGS_ACCOUNT,
        Permissions.REJECT_CLIENT_SAVINGS_ACCOUNT,
        Permissions.POST_SAVINGS_ACCOUNT_DEPOSIT,
        Permissions.POST_SAVINGS_ACCOUNT_WITHDRAWAL,
        Permissions.POST_SAVINGS_FUND_TRANSFER,
        Permissions.REVERSE_SAVINGS_TRANSACTION,
        Permissions.DEPOSIT_DISABLE,
        Permissions.DEPOSIT_ENABLE,
      ],
    },
    // canLoad: [CanLoadRoutes],
    canLoad: [AuthGuard, PermissionGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./pages/report/report.module').then((m) => m.ReportModule),
  },
  {
    path: 'franchisees',
    loadChildren: () =>
      import('./pages/franchisee/franchisee.module').then(
        (m) => m.FranchiseeModule
      ),
  },
  {
    path: 'accounting',
    loadChildren: () =>
      import('./pages/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
  },
  {
    path: 'transfer',
    loadChildren: () =>
      import('./pages/transfer/transfer.module').then((m) => m.TransferModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'updates',
    loadChildren: () =>
      import('./pages/update/update.module').then((m) => m.UpdateModule),
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./pages/support/support.module').then((m) => m.SupportModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
