import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from 'src/app/shared/models/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SummaryCardsService {
  constructor(private http: HttpClient) {}

  public getTotalActiveAndApprovedClients(queryParams) {
    return this.http.get<
      API<{
        approvedClients: number;
        activeClients: number;
      }>
    >(
      `${environment.baseUrl}core/clients/approvedClients?${queryParams}`
    );
  }

  public getDisbursedLoans(queryParams) {
    return this.http.get<
      API<{
        disbursedLoans: number;
      }>
    >(
      `${environment.baseUrl}accounts/loan-accounts/disbursedLoans?${queryParams}`
    );
  }

  public getAmountDisbursed(queryParams) {
    return this.http.get<
      API<{
        amountDisbursed: number;
      }>
    >(
      `${environment.baseUrl}accounts/loan-accounts/amountDisbursed?${queryParams}`
    );
  }

  public getRunningLoans(queryParams) {
    return this.http.get<
      API<{
        runningLoans: number;
      }>
    >(
      `${environment.baseUrl}accounts/loan-accounts/runningLoans?${queryParams}`
    );
  }

  public getOustandingLoans(queryParams) {
    return this.http.get<
      API<{
        loansOutstanding: number;
      }>
    >(
      `${environment.baseUrl}accounts/loan-accounts/loansOutstanding?${queryParams}`
    );
  }

  public getDepositBalance(queryParams) {
    return this.http.get<
      API<{
        depositBalance: number;
      }>
    >(
      `${environment.baseUrl}accounts/loan-accounts/depositBalance?${queryParams}`
    );
  }

  public getTotalRepayment(queryParams) {
    return this.http.get<
      API<{
        totalRepayment: number;
      }>
    >(
      `${environment.baseUrl}accounts/loan-accounts/totalRepayment?${queryParams}`
    );
  }

  public getLoanProductPar(queryParams) {
    return this.http.get<
      API<{
        loanProductPar: any;
      }>
    >(
      `${environment.baseUrl}accounts/loan-accounts/loanProductPar?${queryParams}`
    );
  }

  
}
