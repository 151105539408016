import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public franchiseeDetails: any = {};
  public tenantDetails: any = {};
  public year: any;

  constructor() { }

// get franchisee and tenant details
  public getFranchiseTenantDetails(){
    this.franchiseeDetails = {};
    this.tenantDetails = {};
    // this.franchiseeDetails = JSON.parse(localStorage.getItem('user')).company;
    // this.tenantDetails = JSON.parse(localStorage.getItem('user')).tenant;
  }

  private onInit(){
    this.getFranchiseTenantDetails()
  }

  ngOnInit(): void {
    this.onInit()
  }

}
