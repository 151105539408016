<!-- <div class="bg-white  py-0.5 z-10"> -->
<div class="sticky -top-5 z-[200]">
    <app-top-filters></app-top-filters>
</div>

<app-summary-cards class="md:sticky md:top-10 z-[200]"></app-summary-cards>
<!-- </div> -->

<div class="inline-block my-3">
    <app-button-toggle-ui [buttons]="states" (stateEmit)="onStateChange($event)"></app-button-toggle-ui>
</div>

<ng-container [ngSwitch]="state">
    <div *ngSwitchCase="'operational_indicators'">
        <app-operational-indicators></app-operational-indicators>
    </div>

    <div *ngSwitchCase="'financial_indicator'">
        <app-financial-indicators></app-financial-indicators>
    </div>

    <div *ngSwitchCase="'user_activity'">
        <app-user-activity></app-user-activity>
    </div>
</ng-container>