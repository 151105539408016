import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-financial-indicators',
  templateUrl: './financial-indicators.component.html',
  styleUrls: ['./financial-indicators.component.css']
})
export class FinancialIndicatorsComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
}
