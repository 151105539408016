<div class="modal fade" id="modal-change-password" tabindex="-1" role="dialog" aria-lfabelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="panel panel-default">
            <header class="panel-heading">Reset Password</header>
            <div class="panel-body">
                <form class="form-horizontal" id="form-password-required">
                    <div class="form-group">
                        <label class="col-sm-4 control-label required-field">Username</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" id="username" disabled="disabled" placeholder="Username" value="{{ u.username }}" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4 control-label required-field">Password</label>
                        <div class="col-sm-8">
                            <input type="password" class="form-control" [(ngModel)]="password.oldPassword" id="password" name="oldPassword" autocomplete="off" placeholder="Current password" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4 control-label required-field">New Password</label>
                        <div class="col-sm-8">
                            <input type="password" class="form-control" [(ngModel)]="password.newPassword" id="new-password" name="newPassword" autocomplete="off" placeholder="New Password" data-smk-strongPass="weak" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4 control-label required-field">Retype Password</label>
                        <div class="col-sm-8">
                            <input type="password" class="form-control" [(ngModel)]="password.retypedPassword" id="retyped-password" name="retypedPassword" autocomplete="off" placeholder="Retype Password" data-smk-strongPass="weak" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="" class="col-sm-4 control-label"></label>
                        <div class="col-sm-8">
                            <button type="button" (click)="logout()" class="btn btn-default">Cancel</button>
                            <button type="button" (click)="changePassword()" class="btn ">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="panel-footer">
                <strong class="text-danger">You're required to reset your password.</strong>
            </div>
        </div>
    </div>
</div>