import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toast: ToastrService) { }

  public success(msg: any){
    this.toast.success(msg)
  }

  public error(msg: any){
    this.toast.error(msg)    
  }
  public info(msg: any){
    this.toast.info(msg)    
  }

  public warn(msg: any){
    this.toast.warning(msg)    
  }

  // public test(msg: any) {
  //   var bar = $.peekABar({
  //     html: 'Custom Message',
  //     delay: 2000,
  //     autohide: true,
  //     padding: '2em',
  //     backgroundColor: 'rgb(195, 195, 195)',
  //     animation: {
  //         type: 'fade',
  //         duration: '2000'
  //     },
  //     opacity: '0.8',
  //     cssClass: 'custom-class',
  //     position: 'bottom',
  //     closeOnClick: true,
  //     onShow: function() {
  //     },
  //     onHide: function() {
  //     }
  // });
  
  // // Show the bar
  // bar.show();
  // }
}
