import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl: string = environment.baseUrl + 'user-service/';

  constructor(private http: HttpClient, private dataService: DataService) {
  }

  public postUser(data: Object) {
      return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/users', data);
  }

  public getUsers(req: any, type: string): Observable<any> {
    return this.dataService.getList(this.baseUrl + 'api/staff/users/all', req, type);
  }

  public getLoanOfficersTransferDetailList(req: any): Observable<any> {
    return this.dataService.getList(this.baseUrl + 'api/v2/internal/core/users/getLoanOfficersTransferDetailList', req);
  }

  public getUser(id: number): Observable<any> {
    return this.http.get(this.baseUrl + 'api/v2/internal/core/users/' + id);
  }

  public getUsersByRole(role: String): Observable<any> {
    return this.http.get(this.baseUrl + 'api/v2/internal/core/idName/users?role=' + role);
  }

  public approveUsers(data: Object) {
      return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/users/approve', data);
  }

  /* enable / disable branches.Takes data{'ids': [1,2,3], 'enable': bool} */
  public toggleUsers(data:any): Observable<any> {
      return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/users/toggle', data);
  }

  public getDefaultPassword(): Observable<any> {
    return this.http.get(this.baseUrl + 'api/v2/internal/core/users/defaultPassword');
  }

  public changePassword(data: Object) {
      return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/users/changePassword', data);
  }

  public changeRole(data: Object) {
    return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/users/changeRole', data);
  }

  public postLoanOfficersTransferRequest(data: Object) {
    return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/users/submitLoanOfficersTransferRequest', data);
  }

  public toggleLoanOfficersTransferRequest(data: Object) {
    return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/users/toggleLoanOfficersTransferRequest', data);
  }

  public setViewedUpdateToTrue(id: number): Observable<any> {
    return this.http.get(this.baseUrl + 'api/v2/internal/core/users/update/viewed/' + id);
  }

  public keepUserAlive(token: any) {
      return this.http.post(this.baseUrl + 'api/v2/internal/core/keep-alive', {params: token});
  }
}
