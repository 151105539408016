import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import {
  BehaviorSubject,
  debounceTime,
  map,
  of,
  ReplaySubject,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.css'],
})
export class SearchSelectComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('singleSelect') selectElem: MatSelect;
  @Input() currentScrollPosition = 0;
  @Input() type: 'client' | 'server' = 'server';
  @Input() label: string = 'Add Label';
  @Input() placeholder: string = 'Find record..';
  @Input() endpoint: string = '';
  @Input() set disabled(val: boolean) {
    setTimeout(()=>{
      if (val) {
        this.ctrl.disable();
      } else {
        this.ctrl.enable();
      }
    }, 100)
    
  }
  @Input() scrollEvent: boolean = false;
  // pass list for client side search
  @Input() set fetchedList(list) {
    this.clientList = list;
    this.filteredList.next(list);
  }
  // list of parameter you want to filter by on client side search
  @Input() filterBy = ['name'];
  @Input() defaultSelected: any;
  @Input() defaultListValue: any;

  @Input() showDefaultListValue: boolean = true;
  @Input() control: FormControl | string;
  @Input() controlGroup: FormGroup;
  @Input() keyValue: { key: string[] | string; value: string } = {
    key: 'name',
    value: 'id',
  };
  @Input() filteredList = new BehaviorSubject<any>([]);
  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();
  @Output() scrollEmit = new EventEmitter<void>();

  private clientList = [];
  public filterCtrl: FormControl = new FormControl();
  public currentVal;

  constructor(private http: HttpClient) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentScrollPosition']) {
      this.currentScrollPosition =
        changes['currentScrollPosition'].currentValue;
    }
    

  }

  public get ctrl() {
    if (this.controlGroup) {
      return this.controlGroup.get(this.control as string) as FormControl;
    }
    return this.control as FormControl;
  }

  ngAfterViewInit(): void {
    // this.selectElem.openedChange.subscribe(() => this.registerPanelScrollEvent());
    this.selectElem.openedChange.subscribe((opened) => {
      this.registerPanelScrollEvent();
    });
  }

  registerPanelScrollEvent() {
    if (!this.selectElem?.panel) return;
    this.selectElem.panel.nativeElement.scrollTop = this.currentScrollPosition;
    const panel = this.selectElem.panel.nativeElement;
    panel.addEventListener('scroll', (event) => {
      this.scrollEmit.emit(event);
    });
  }

  public setScrollPosition(): void {
    if (!this.selectElem.panel) return;
    const panel = this.selectElem.panel.nativeElement;
    panel.scrollTop = this.currentScrollPosition;
  }

  private initFilter() {
    if (this.defaultSelected) {
      if(!this.clientList?.length){
        this.filteredList.next([this.defaultSelected]);
      }else{
        this.filteredList.next(this.clientList);
      }
        this.ctrl?.setValue(this.defaultSelected[this.keyValue['value']]);
        this.currentVal = this.defaultSelected;
    }

    // this.filterCtrl.valueChanges
    //   .pipe(
    //     debounceTime(800),
    //     switchMap((value) => {
    //       if (value === '') return [];
    //       if (!value) return of([]);
    //       if (this.type === 'client') {
    //         return of({
    //           list: this.clientList,
    //           param: value,
    //         });
    //       }
    //       return this.http
    //         .get(`${this.endpoint}${value}`)
    //         .pipe(map((d: any) => d.data));
    //     })
    //   )
    //   .subscribe((res) => {
    //     if (this.type === 'client') {
    //       this.filterClientList(res.list, res.param);
    //       return;
    //     }
    //     this.filterServerList(res);
    //   });

    if (this.type === 'client') {
      this.filterCtrl.valueChanges
        .pipe(
          switchMap((value) => {
            return of({
              list: this.clientList,
              param: value || '',
            });
          })
        )
        .subscribe((res) => {
          this.filterClientList(res.list, res.param);
        });
    } else {
      this.filterCtrl.valueChanges
        .pipe(
          debounceTime(800),
          switchMap((value) => {
            if (value === '') return [];
            if (!value) return of([]);
            return this.http
              .get(`${this.endpoint}${value}`)
              .pipe(map((d: any) => d.data));
          })
        )
        .subscribe((res) => {
          this.filterServerList(res);
        });
    }

    this.ctrl?.valueChanges.subscribe((val) => {
      const value = this.filteredList.value.find(
        (obj) => obj[this.keyValue['value']] === val
      );
      this.currentVal = value;
      this.emitSelectedVal.emit(value);
      // if (this.type === 'client') {
      //   const selectedValIndex = this.clientList.findIndex(
      //     (item) => item[this.keyValue['value']] === val
      //   );
      //   if (selectedValIndex < 0) {
      //     this.filteredList.next(this.clientList);
      //     return;
      //   }
      //   this.clientList.splice(selectedValIndex, 1);
      //   this.clientList.unshift(this.currentVal);
      //   this.filteredList.next(this.clientList);
      // }
    });
  }

  private filterServerList(res: any) {
    const currentVal = this.ctrl.value;
    res = res.filter((val) => val[this.keyValue['value']] !== currentVal);
    const records = this.currentVal ? [this.currentVal, ...res] : res;
    this.filteredList.next(records);
  }

  private filterClientList(res: any, value: string) {
    const records = res.filter((record) => {
      const currentVal = this.ctrl.value;
      if (record[this.keyValue['value']] === currentVal) return true;
      for (let i = 0, len = this.filterBy.length; i < len; i++) {
        const key = record[this.filterBy[i]].toString().toLowerCase();
        if (key.includes(value.toLowerCase())) return true;
      }
      return false;
    });
    this.filteredList.next(records);
  }

  ngOnInit(): void {
    this.initFilter();
  }
}
