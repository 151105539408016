import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, map } from 'rxjs';
import { PageRequest } from 'src/app/shared/models/common';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DepositProductService {

  baseAccountUrl: string = environment.baseUrl + environment.accountsUrl;
  baseUrl: string = environment.baseUrl;

  public searchEndpoint = `${this.baseUrl}accounts/savings-products?page=0&pageSize=10&status=APPROVED&sort-field=&sort-order=DESC&filter=`
 
  constructor(private http: HttpClient, private utilityService: UtilityService,) { }

  create(data) {
    return this.http.post<any>(this.baseAccountUrl + 'account/savings-products', data);
  }

  update(data) {
    return this.http.put<any>(this.baseAccountUrl + 'account/savings-products', data);
  }

  getAllRecordsUnpaged():Observable<any> {
    return this.http.get(`${this.baseUrl}accounts/savings-products/all`);
  }
  getAllRecords(queryParams: PageRequest):Observable<any> {
    const payload = this.utilityService.objToQueryParams(queryParams);
    return this.http.get(`${this.baseUrl}account/savings-products?${payload}`);
  }
  getRecord(id: number):Observable<any> {
    return this.http.get(`${this.baseUrl}account/savings-products/${id}`);
  }

  public toggleRecord(data:any): Observable<any>{
    return this.http.post<any>(this.baseAccountUrl + 'account/savings-products/toggle', data);
  }
}
 

@Injectable()
export class DepositProductsFirstPageResolver implements Resolve<Observable<any[]>> {
  constructor(private service: DepositProductService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any[]> {
    const pageRequest: PageRequest = {
      page: 0,
      pageSize: 10,
      state: 'APPROVED',
      sortField: 'lastUpdateTime',
      sortOrder: 'DESC',
    };
    return this.service.getAllRecords(pageRequest).pipe(
      map((res) => ({
        ...res,
        pagination: { ...res.pagination, pageRequest },
      }))
    );
  }
}