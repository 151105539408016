import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-interest-income-interest-expense',
  templateUrl: './interest-income-interest-expense.component.html',
  styleUrls: ['./interest-income-interest-expense.component.css']
})
export class InterestIncomeInterestExpenseComponent implements OnInit {

  public hideFilters: boolean = false
  public interestIncomeForm: FormGroup;
  public interestIncomeFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  filterInterestIncome(ev: any) {
  }

}
