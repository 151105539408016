import { Directive, Input, TemplateRef, ViewContainerRef, AfterViewChecked } from '@angular/core';
import { Permissions } from 'src/app/shared/dto/permissions';
import { PermissionService } from '../permission.service';

@Directive({
  selector: '[hasPermissions]'
})
export class HasPermissionsDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private permissionService: PermissionService
  ) { }

  @Input() 
  set hasPermissions(permission: Permissions | Permissions[] | string[]) {
    this.viewContainerRef.clear();
    if (!Array.isArray(permission)) permission = [permission];
    

    const hasPermission = this.permissionService.hasPermissionList(permission);
    
    if (hasPermission) this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
