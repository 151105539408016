import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-user-activity-daily-repayment-disbursement',
  templateUrl: './user-activity-daily-repayment-disbursement.component.html',
  styleUrls: ['./user-activity-daily-repayment-disbursement.component.css']
})
export class UserActivityDailyRepaymentDisbursementComponent implements OnInit {

  public hideFilters: boolean = false

  public dailyDisbursementForm: FormGroup;
  public dailyDisbursementFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.START_DATE, type: 'START_DATE', position: 'left' },
    { name: ReportInputTypes.END_DATE, type: 'END_DATE', position: 'left' },
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]
  
  constructor() { }

  ngOnInit(): void {
  }

  filterDailyRepayment(ev: any) {
  }

}
