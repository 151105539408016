import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, firstValueFrom, tap } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { BranchIndexedDBService } from '../indexDB/branch.service';
import { LoanOfficerIndexedDBService } from '../indexDB/loanOfficer.service';
import { AreaIndexedDBService } from '../indexDB/area.service';
import { LoanProductIndexedDBService } from '../indexDB/loanProduct.service';
import { DepositProductIndexedDBService } from '../indexDB/depositProduct.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
    baseCoreUrl: string = environment.baseUrl + environment.coreUrl;
    private socket$: WebSocketSubject<any>;

    constructor(
        private http: HttpClient,
        private branchIndexedDBService: BranchIndexedDBService,
        private areaIndexedDBService: AreaIndexedDBService,
        private loanOfficerIndexedDBService: LoanOfficerIndexedDBService,
        private loanProductIndexedDBService: LoanProductIndexedDBService,
        private depositProductIndexedDBService: DepositProductIndexedDBService
        ) {
        this.socket$ = webSocket('ws://localhost:3000'); // Replace with your Java WebSocket server URL

        // this.socket$.subscribe((message: any) => {
        // if (message.type === 'branch-update') {
        //     this.updateBranchList();
        // } else if (message.type === 'area-update') {
        //     this.updateAreaList();
        // } else if (message.type === 'loanOfficer-update') {
        //     this.updateLoanOfficer();
        // } else if (message.type === 'loanProduct-update') {
        //     this.updateLoanProduct();
        
        // } else if (message.type === 'depositProduct-update') {
        //     this.updateDepositProduct();
        // }
        // });

    }

    updateBranchList(): Observable<any> {
        return this.http.get(`${this.baseCoreUrl}branch/list-branch`).pipe(
          tap((res: any) => {
            const branches = res.data;
            this.branchIndexedDBService.storeDataInIndexedDB(branches.map(branch => ({ id: branch.id, name: branch.name, areaId: branch.areaId })));
          }),
          catchError((error) => {
            console.error('Error updating branch list:', error);
            return [];
          })
        );
    }
    
    updateAreaList(): Observable<any> {
        return this.http.get(`${this.baseCoreUrl}areas/all-areas`).pipe(
            tap((res: any) => {
            const areas = res.data;
            console.warn("GOT AREAS", areas)
            this.areaIndexedDBService.storeDataInIndexedDB(areas.map(area => ({ id: area.areaId, name: area.areaName})));
            }),
            catchError((error) => {
            console.error('Error updating area list:', error);
            return [];
            })
        );
    }
    
    updateLoanOfficer(): Observable<any> {
        return this.http.get(`${this.baseCoreUrl}users/loan-officers`).pipe(
            tap((res: any) => {
            const loanOfficers = res.data;
            this.loanOfficerIndexedDBService.storeDataInIndexedDB(loanOfficers.map(loanOfficer => ({ id: loanOfficer.id, name: loanOfficer.name, branchId: loanOfficer.branch_id, areaId: loanOfficer.area_id })));
            }),
            catchError((error) => {
            console.error('Error updating account officers:', error);
            return [];
            })
        );
    }
    
    updateLoanProduct(): Observable<any> {
        return this.http.get(`${this.baseCoreUrl}loanProduct/getAllLoanProducts`).pipe(
            tap((res: any) => {
            const products = res.data;
            this.loanProductIndexedDBService.storeDataInIndexedDB(products.map(product => ({ id: product.id, name: product.name})));
            }),
            catchError((error) => {
            console.error('Error updating loan products:', error);
            return [];
            })
        );
    }
    
    updateDepositProduct(): Observable<any> {
        return this.http.get(`${environment.baseUrl}accounts/savings-products/all`).pipe(
            tap((res: any) => {
            const products = res.data;
            this.depositProductIndexedDBService.storeDataInIndexedDB(products.map(product => ({ id: product.id, name: product.name})));
            }),
            catchError((error) => {
            console.error('Error updating deposit products:', error);
            return [];
            })
        );
    }


    public async storeIndexedDbData() {
        const localStorageKey = 'indexDBFetched'
      
        // Retrieve the array from the local storage
        const storedArrayString = localStorage.getItem(localStorageKey);
        const storedArray = storedArrayString ? JSON.parse(storedArrayString) : [];
      
        const requests: Record<string, Observable<any>> = {};
      
        // Check if each data key is present in the stored array, and only add it to requests if not found
        if (!storedArray.includes('areaList')) {
          requests['areaList'] = this.updateAreaList();
        }
        if (!storedArray.includes('branchList')) {
          requests['branchList'] = this.updateBranchList();
        }
        if (!storedArray.includes('loanOfficer')) {
          requests['loanOfficer'] = this.updateLoanOfficer();
        }
        if (!storedArray.includes('loanProduct')) {
          requests['loanProduct'] = this.updateLoanProduct();
        }
        if (!storedArray.includes('depositProduct')) {
          requests['depositProduct'] = this.updateDepositProduct();
        }
    
        if (Object.keys(requests).length === 0) {
          localStorage.setItem('isIndexDBFetched', 'true');
          const instance =  JSON.parse(localStorage.getItem('instance') || '0'); 
          Number(instance) + 1
          localStorage.setItem('instance',JSON.stringify(instance))
          return;
        }
      
        try {
          await Promise.all(
            Object.entries(requests).map(async ([key, observable]) => {
              try {
                await firstValueFrom(observable);
                storedArray.push(key);
              } catch (error) {
                console.error(`Request for ${key} failed:`, error);
              }
            })
          );
      
          // Update localStorage with the successful request keys
          localStorage.setItem(localStorageKey, JSON.stringify(storedArray));
        } catch (error) {
          console.error('Error while processing requests:', error);
        }
        
    }
}