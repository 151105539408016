import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-user-activity-par',
  templateUrl: './user-activity-par.component.html',
  styleUrls: ['./user-activity-par.component.css']
})
export class UserActivityPARComponent implements OnInit {

  public hideFilters: boolean = false
  public portfolioForm: FormGroup
  public portfolioFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
    { name: ReportInputTypes.PORTFILIO_AT_RISK, type: 'PORTFILIO_AT_RISK', position: 'right' },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  filterPAR(ev: any) {
  }
}
