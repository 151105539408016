<div class="modal fade" id="terms-modal" tabindex="-1" role="dialog" aria-lfabelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="panel panel-default">
            <header class="panel-heading">
                <strong>Terms & Conditions</strong>
            </header>
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="terms-and-condition-body">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In bibendum posuere tortor, quis accumsan augue pretium sed. Suspendisse at fermentum nunc, in rutrum dui. Etiam in magna cursus, porttitor magna eget, iaculis dolor. In ac suscipit turpis. Donec
                                quis auctor dolor. Cras sed augue pretium, fermentum ligula in, tempor arcu. Vestibulum placerat lobortis justo, ut tempor purus ultrices quis. Nullam quam dui, aliquam nec gravida id, egestas eget dui. Nunc euismod risus
                                euismod, dignissim enim vel, ultrices tellus. Maecenas rutrum risus eget dignissim sollicitudin. Nullam interdum justo vel nulla lacinia elementum. Vestibulum eget ultricies ligula. Phasellus ut lorem id lectus ultricies
                                suscipit at vel mauris. Vestibulum eu justo eget enim tincidunt molestie vitae nec felis. Nulla sit amet scelerisque arcu. Morbi id suscipit mi. Proin facilisis sodales velit vitae vehicula. Vestibulum congue felis hendrerit
                                odio mattis, elementum tincidunt nulla convallis. Suspendisse posuere a nibh ut sollicitudin. Nam arcu nisl, lobortis faucibus cursus in, volutpat nec ligula. Curabitur euismod, augue ac faucibus mattis, felis elit cursus
                                tellus, sodales tempus ante odio convallis libero. Proin eget lacus nec sem consectetur dictum. Etiam ullamcorper orci massa, suscipit ultrices erat egestas ut. Sed non magna ac est varius scelerisque non scelerisque ex.
                                Nunc sed aliquet nulla. Donec a nibh a turpis luctus consequat id sit amet magna. Aenean tincidunt a neque ut porta. Sed accumsan dapibus ultricies. Phasellus tortor quam, fermentum scelerisque condimentum tempor, posuere
                                quis dolor. Nam pulvinar augue id egestas pellentesque. Nam fringilla egestas dui et vehicula. Donec at tincidunt tortor. Curabitur congue suscipit pellentesque. Vivamus ac facilisis ex. Nunc lacinia ligula massa. Praesent
                                id rutrum augue, quis volutpat augue. Mauris vitae arcu vel massa faucibus commodo. Ut sit amet aliquet ex, quis elementum nulla. Duis bibendum et risus mattis condimentum. Orci varius natoque penatibus et magnis dis parturient
                                montes, nascetur ridiculus mus. Duis lobortis sem enim, sit amet dignissim ex aliquet eget. Donec dapibus tortor vel porta egestas. Ut odio sem, dictum in nibh sed, pellentesque molestie erat. Etiam aliquet tincidunt dolor
                                vitae vulputate. Pellentesque accumsan ligula at neque malesuada, et laoreet massa elementum. Donec cursus pellentesque pharetra. Donec lacinia, nibh quis venenatis molestie, enim nulla condimentum tellus, sed blandit massa
                                nisl et nisl. Donec imperdiet id libero non vulputate. Phasellus euismod nisl quis nulla feugiat dignissim. Donec fermentum, eros at porttitor venenatis, lectus enim euismod augue, at venenatis velit ante vitae ligula.
                                Donec hendrerit felis in interdum vulputate. Phasellus sed euismod tortor. Curabitur non libero a velit rutrum eleifend. Aenean ultricies sapien ac elit aliquet elementum. Proin non faucibus turpis. Proin vitae justo non
                                augue aliquet malesuada vel sit amet metus. Mauris eget dignissim velit, vitae dignissim mauris. Nullam ac purus egestas, semper turpis vitae, posuere tortor. Cras eget diam neque. Vestibulum vehicula aliquet purus aliquet
                                congue. Morbi lobortis urna sed leo facilisis tincidunt. Nunc nec nulla eros. Nunc auctor, nibh id venenatis iaculis, massa lectus sodales dui, eget aliquam augue diam in ex. Suspendisse in velit magna. Curabitur vitae
                                dignissim arcu, vitae finibus nunc. Vivamus est arcu, posuere ut risus sit amet, finibus consectetur nulla. Phasellus lacinia ac nunc aliquet venenatis. Nullam sapien urna, vestibulum quis ultricies id, vehicula nec nisi.
                                Fusce tincidunt eu libero convallis lobortis. Sed condimentum a nunc nec porta. Vivamus elementum ex et neque tincidunt, vitae bibendum tellus ultricies. Donec libero urna, dapibus quis eleifend a, fermentum a lorem. Nulla
                                facilisi. Nulla interdum mi id justo bibendum, a gravida eros lacinia. Sed congue felis sem, a fringilla lectus iaculis nec. Sed efficitur turpis lacus, ac hendrerit tortor sagittis vitae. Phasellus efficitur lacinia lectus.
                                Etiam vulputate nibh diam, vitae consectetur libero auctor sit amet. Vestibulum ut maximus sem. Proin nec finibus lectus. Donec ut nisl vestibulum mi vulputate faucibus in sagittis lectus. Vivamus quis leo aliquam, varius
                                urna ac, aliquet erat. Pellentesque tristique turpis vel ante porttitor cursus quis id enim. Mauris vel metus sollicitudin, lacinia lacus nec, bibendum lorem. Sed lobortis erat massa, eu efficitur tellus accumsan id. Suspendisse
                                nisl diam, pretium id metus facilisis, vulputate sodales tellus. Vestibulum hendrerit tortor augue, vel ornare justo condimentum vitae. Fusce leo enim, mattis bibendum dolor at, viverra efficitur odio. Morbi sollicitudin
                                odio nec sapien posuere posuere. Aliquam ultricies, purus a volutpat dictum, urna diam tempor nulla, nec fringilla mauris felis vestibulum ante. Morbi placerat orci nec diam posuere, eget porttitor quam finibus. Aliquam
                                nec placerat nunc. Nulla fringilla mauris sed magna laoreet tempus. Sed dignissim condimentum placerat. Nullam accumsan massa ac odio vestibulum elementum. Aliquam lacinia malesuada velit sed suscipit. Curabitur consectetur
                                leo quis tempus ultricies. Cras hendrerit odio sed augue vestibulum aliquam. Mauris a orci feugiat, ullamcorper mi non, tincidunt ipsum. Suspendisse sit amet fringilla dui, quis hendrerit libero. Quisque pulvinar nec quam
                                nec convallis. Quisque vitae nulla sed erat fringilla ullamcorper sed sit amet orci. Nam nec interdum neque. Maecenas urna dolor, luctus a diam ac, blandit egestas purus. Curabitur ut euismod felis. Pellentesque accumsan,
                                nisi at mollis eleifend, augue lacus vestibulum metus, in maximus nisi ligula id dolor. In a efficitur diam, nec rhoncus tortor. Maecenas id condimentum nisi. Aenean dignissim nibh nibh, eu lacinia leo ultricies ac. Etiam
                                elementum dui sit amet purus ultricies finibus. Donec cursus dapibus bibendum. Aliquam efficitur mauris tortor, nec semper odio lobortis eu. In justo lacus, mollis vulputate dapibus vel, condimentum sit amet felis. Sed
                                in libero ac est tincidunt gravida. Suspendisse id erat a nisi consequat pretium et viverra massa. Fusce sit amet purus lectus. Donec purus lectus, condimentum quis nunc at, bibendum congue purus. Ut nec ullamcorper dui,
                                vel malesuada felis. Cras lobortis purus et volutpat pharetra. Aenean varius orci in rhoncus tristique. Ut non congue eros, sit amet cursus nulla. In facilisis vestibulum mattis. Proin consectetur venenatis dolor ut fermentum.
                                Nam mollis elementum maximus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris eu lacus sit amet mauris fringilla fermentum. Quisque rhoncus eget nisl a pulvinar. Cras placerat
                                metus risus. Mauris vel dictum massa. Sed tristique justo quis urna posuere, quis lobortis orci faucibus. Phasellus luctus ex nec ultricies mattis. Praesent id sapien purus. Maecenas ipsum ligula, scelerisque et purus et,
                                mollis mollis purus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aliquam eu dictum nibh. Etiam rutrum molestie ante at rutrum. Mauris non sem vitae mauris efficitur iaculis
                                sed suscipit nulla. Proin convallis quis ex quis sollicitudin. Integer facilisis ligula eu pharetra laoreet. Praesent consectetur molestie turpis eu tempor. Ut consequat ex eu quam tempor, ac mollis sem scelerisque. Vestibulum
                                placerat molestie sagittis. Nunc volutpat sapien lacus, ac egestas tellus faucibus at. Praesent maximus tellus sed arcu ultrices, vitae lacinia sapien gravida. Praesent lorem purus, volutpat quis commodo nec, tincidunt
                                in mauris. Aliquam vel maximus lectus. Sed congue in sapien ac semper. Praesent porttitor dictum facilisis. Aliquam erat volutpat. Duis id ante justo. Maecenas semper aliquam ipsum eu lacinia. Vivamus a felis et ante convallis
                                gravida. Vestibulum ligula turpis, ornare nec pretium non, faucibus vel augue. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras consequat, ex in finibus venenatis, risus
                                risus sagittis quam, eget vulputate nisi erat a risus. Sed interdum euismod vestibulum. Curabitur eget risus leo.
                            </p>
                            <div class="checkbox">
                                <label class="pull-right">
                  <input
                    type="checkbox"
                    [(ngModel)]="termsAndConditionAccepted"
                  />
                  <strong>I agree to accept terms and condition</strong>
                </label>
                            </div>
                            <form id="form-change-password" *ngIf="termsAndConditionAccepted">
                                <div class="row">
                                    <!--<div class="form-group col-md-4">
                    <label for="oldPassword">Password</label>
                    <input type="password" class="form-control" name="oldPassword" id="oldPassword" value="written">
                  </div>-->
                                    <div class="form-group col-md-6">
                                        <label for="newPassword">New Password</label>
                                        <input type="password" class="form-control" name="newPassword" id="newPassword" required />
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="retypePassword">Retype Password</label>
                                        <input type="password" class="form-control" name="retypePassword" id="retypePassword" required />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-success pull-right" [disabled]="!termsAndConditionAccepted" (click)="redirectToDashboard()">
              Submit
            </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>