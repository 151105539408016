import { Component, Input, OnInit } from '@angular/core';
import { ItemType } from '../../models/common';

@Component({
  selector: 'app-board-ui',
  templateUrl: './board-ui.component.html',
  styleUrls: ['./board-ui.component.css']
})
export class BoardUiComponent  {
  @Input() dividerClassNames: string = 'bg-grey-light'
  @Input() classNames: string = 'bg-grey-light'
  @Input() items:ItemType[] = [] 

  constructor() { }

  

}
