<div *ngIf="showLoader" class="transparent-overlay" id="loading-overlay">
    <div class="loading-img">
        <app-chart-loader></app-chart-loader>
        <br />
        <span style="color: #f96969">{{
      isConfiguring
        ? "Setting up inprogress. This might take several minutes..."
        : "Loading . . "
    }}</span>
    </div>
</div>