<section [ngClass]="alignState === 'left'? 'flex-row-reverse' : '' " class="flex wrap h-[40px] items-center justify-between">
    <div>
        <h5 class="font-light">{{ title }}</h5>
    </div>
    <div *ngIf="states.length" class="leftAligned">
        <mat-button-toggle-group [value]="initialState?.toLowerCase()" (change)="actionToggled($event.value)" name="fontStyle" aria-label="Font Style">
            <ng-container *ngFor="let button of states">
                <mat-button-toggle *ngIf="hasPermission(button.perms)" [value]="button.value">{{ button.name }}
                </mat-button-toggle>
            </ng-container>
        </mat-button-toggle-group>
    </div>
</section>