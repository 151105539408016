<div class="w-[25rem] xxl:w-[100%] mx-auto md:w-[130px] h-[130px] rounded-lg bg-primary border border-primary relative">
    <app-chart-loader-indicator *ngIf="isLoading"></app-chart-loader-indicator>

    <nz-carousel #carousel [nzDots]="false" [nzEffect]="effect">
        <div nz-carousel-content *ngFor="let item of items">
            <div class="w-full h-full flex flex-col items-center !text-white gap-5 justify-center">
                <h1 *ngIf="item.pipeType === 'currency'" class="text-white font-semibold">
                    {{ item.value | wz_naira_money_format }}
                </h1>
                <h1 *ngIf="item.pipeType === 'number'" class="text-white font-semibold">
                    {{ item.value | numberWithComma }}
                </h1>
                <h1 *ngIf="item.pipeType === 'percent'" class="text-white font-semibold">
                    <span *ngIf="item.value">
            {{ item.value }}%
          </span>
                    <span *ngIf="!item.value"> - </span>
                </h1>
                <mat-icon class="!w-[66.12px] !h-[40px]" [ngClass]="item.iconClass || ''" [svgIcon]="item.icon"></mat-icon>
                <h3 class="text-white text-[11px] font-thin">{{ item.name }}</h3>
            </div>
        </div>
    </nz-carousel>
    <ng-container *ngIf="items.length && items.length > 1">
        <mat-icon (click)="carousel.pre()" class="cursor-pointer absolute text-white left-1 top-[40%]">chevron_left</mat-icon>
        <mat-icon (click)="carousel.next()" class="cursor-pointer absolute text-white right-0 top-[40%]">chevron_right</mat-icon>
    </ng-container>
</div>