import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { NotificationService } from '../notification.service';

@Directive({
  selector: '[appCurrency]',
})
export class CurrencyDirective {
  @Input() symbol: string = '₦';

  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
    // '0',
    // '1',
    // '2',
    // '3',
    // '4',
    // '5',
    // '6',
    // '7',
    // '8',
    // '9',
  ];

  constructor(
    @Self() private ngControl: NgControl,
    private elementRef: ElementRef,
    private notification: NotificationService,
  ) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys    
    if (this.specialKeys.indexOf(event.key) !== -1) return

    let current: string = this.elementRef.nativeElement.value;
    current = current.slice(1).replace(/,/g, '');

    // Prevent zero as initial input
    // if(current[0].match(/[\D]/) || current[0].match(/[0]/)) event.preventDefault()

    // const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: KeyboardEvent) {
    const value = this.ngControl.value ? this.ngControl.value.replace(/[^0-9\.]/g, '').trim() : null
    if ((value && isNaN(Number(value))) || (!value && !isNaN(Number(value)))) {
      this.ngControl.reset(null);
      // this.notification.error('Invalid amount value')
    }
     
  }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    const value = this.ngControl.value;
    this.elementRef.nativeElement.value = this.formatCurrency(value);
  }

  formatCurrency(amount) {
    // TODO: Use currency code to get the currency symbol
    // i.e en-NG/NGN = ₦, en-US/USD = $ etc
    return amount ? `${this.symbol}${amount
      .replace(/[^0-9\.]/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : null;
  }
}
