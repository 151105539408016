import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { depositChartData } from 'src/app/shared/charts/datasource';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-staff-cost',
  templateUrl: './staff-cost.component.html',
  styleUrls: ['./staff-cost.component.css']
})
export class StaffCostComponent implements OnInit {

  public hideFilters: boolean = false

  public staffCostForm: FormGroup
  public staffCostFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]

  staffCostChartData?: any[] = depositChartData;
  public staffCostSortingOptions: { key: 'name' | 'value', sortingDirection: 'asc' | 'desc' | 'default' } = {
    key: 'name',
    sortingDirection: 'asc'
  }

  constructor() { }

  ngOnInit(): void {
    this.staffCostChartData = depositChartData.map((item: any) => ({
      name: item.country,
      value: item.gold
    }))
  }

  filterStaffCost(ev: any) {
    if (ev.type === 'SORT') {
      const selectedString = ev.value === 'default' ? 'name-default' : ev.value;
      const [key, sortingDirection] = selectedString.split('-');
      this.staffCostSortingOptions = { key, sortingDirection };
    }
  }

}
