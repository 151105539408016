import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionService } from 'src/app/utils/permission.service';
import { ActionButtonType } from '../../models/common';

@Component({
  selector: 'app-action-bar2-ui',
  templateUrl: './action-bar2-ui.component.html',
  styleUrls: ['./action-bar2-ui.component.css']
})
export class ActionBar2UiComponent {

  @Input() actionButtons: ActionButtonType[] = [];
  @Input() showActionButtons: boolean = false;
  @Input() disableActionButtons: boolean = true;
  @Output() actionEmit: EventEmitter<ActionButtonType['value']> =
    new EventEmitter();
    
  constructor(private permissionsService: PermissionService) {}


  public evalClass(val: ActionButtonType['value']) {
    if (val === 'reject' || val === 'disable' || val === 'decline' || val === 'cancel') {
      return 'bg-error';
    }
    return 'bg-success';
  }
  public evalIcon(val: ActionButtonType['value']) {
    if (val === 'reject' || val === 'disable' || val === 'decline' || val === 'cancel') {
      return 'x';
    }
    return 'check';
  }

  public actionToggled(val: ActionButtonType['value']) {
    this.actionEmit.emit(val);
  }

}
