import { Component, Input } from '@angular/core';
import { number } from 'echarts';

@Component({
  selector: 'app-accordion-ui',
  templateUrl: './accordion-ui.component.html',
  styleUrls: ['./accordion-ui.component.css']
})
export class AccordionUiComponent {

  @Input() showHeader: boolean = false
  @Input() headerTitles: string[] | number[]= []
  @Input() data: {parent: any, children: any[]}[] = [];

  @Input() showFooter: boolean = false
  @Input() footerTitles: string[] = []

  constructor() { }
}
