export let columnData: {name: string, value: number}[]= [
    { name: "USA", value: 50 },
    { name: "China", value: 40 },
    { name: "Japan", value: 70 },
    { name: "Australia", value: 60 },
    { name: "France", value: 50 },
    { name: "Nigeria", value: 40 },
    { name: "Ghana", value: 40 },
    { name: "Sweden", value: 30 },
    { name: "Germany", value: 40 },
    { name: "Italy", value: 40 },
    { name: "Sweden", value: 30 }
];
export let depositChartData: Object[]= [
    { country: "USA", gold: 50 },
    { country: "China", gold: 40 },
    { country: "Japan", gold: 70 },
    { country: "Australia", gold: 60 },
    { country: "France", gold: 50 },
    { country: "Nigeria", gold: 40 },
    { country: "Ghana", gold: 40 },
    { country: "Sweden", gold: 30 },
    { country: "Germany", gold: 40 },
    { country: "Italy", gold: 40 },
    { country: "Sweden", gold: 30 }
];

export let parChartData: Object[]= [
    { tenure: "USA", amount: 50 },
    { tenure: "China", amount: 40 },
    { tenure: "Japan", amount: 70 },
    { tenure: "Australia", amount: 60 },
    { tenure: "France", amount: 50 },
    { tenure: "Nigeria", amount: 40 },
    { tenure: "Ghana", amount: 40 },
    { tenure: "Sweden", amount: 30 },
    { tenure: "Germany", amount: 40 },
    { tenure: "Italy", amount: 40 },
    { tenure: "Sweden", amount: 30 }
];

export let writeOffChartData: Object[]= [
    { loanProduct: "USA", amount: 50 },
    { loanProduct: "China", amount: 40 },
    { loanProduct: "Japan", amount: 70 },
    { loanProduct: "Australia", amount: 60 },
    { loanProduct: "France", amount: 50 },
    { loanProduct: "Nigeria", amount: 40 },
    { loanProduct: "Ghana", amount: 40 },
    { loanProduct: "Sweden", amount: 30 },
    { loanProduct: "Germany", amount: 40 },
    { loanProduct: "Italy", amount: 40 },
    { loanProduct: "Sweden", amount: 30 }
];

export let groupColumnData: Object[] = [
    { country: "USA", gold: 50, silver: 70, bronze: 45 },
    { country: "China", gold: 40, silver: 60, bronze: 55 },
    { country: "Japan", gold: 70, silver: 60, bronze: 50 },
    { country: "Australia", gold: 60, silver: 56, bronze: 40 },
    { country: "France", gold: 50, silver: 45, bronze: 35 },
    { country: "Germany", gold: 40, silver: 30, bronze: 22 },
    { country: "Italy", gold: 40, silver: 35, bronze: 37 },
    { country: "Sweden", gold: 30, silver: 25, bronze: 27 }
]

export let data1: Object[] = [
    { x: "2014", y: 76.9 },
    { x: "2015", y: 99.5 },
    { x: "2016", y: 121.7 },
    { x: "2017", y: 142.5 }
  ];
export let data2: Object[] =[
    { x: "2014", y: 66.1 },
    { x: "2015", y: 79.3 },
    { x: "2016", y: 91.3 },
    { x: "2017", y: 102.4 }
  ];

export let lineData: {x:string, y:number, y1:number}[] = [
     { x: '2014', y: 150, y1: 198 },
     { x: '2015', y: 230, y1: 173 },
     { x: '2016', y: 224, y1: 312 },
     { x: '2017', y: 218, y1: 185 },
     { x: '2018', y: 135, y1: 245 },
     { x: '2019', y: 147, y1: 279 },
     { x: '2020', y: 260, y1: 201 }
];
export let pieData: Object[] = [
    { value: 10900, name: 'Nigeria' },
    { value: 54400, name: 'China' },
    { value: 88000, name: 'Japan' },
    { value: 67500, name: 'USA' },
    { value: 76500, name: 'Germany' },
    { value: 67900, name: 'France' },
    // { value: 77000, name: 'Italy' },
    // { value: 70000, name: 'Sweden' },
    // { value: 80000, name: 'Australia' },
    // { value: 90000, name: 'France' },
    // { value: 90000, name: 'Italy' },
    // { value: 90000, name: 'Sweden' },
    // { value: 90000, name: 'Australia' },
    // { value: 90000, name: 'France' },
    // { value: 90000, name: 'Italy' },
    // { value: 90000, name: 'Sweden' },
    // { value: 90000, name: 'Australia' },
    // { value: 90000, name: 'France' },
    // { value: 90000, name: 'Italy' },
    // { value: 90000, name: 'Sweden' },
    // { value: 90000, name: 'Australia' }
];