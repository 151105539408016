import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { NotificationService } from 'src/app/utils/notification.service';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';
import { PageRequest } from '../models/common';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  baseUrl: string = environment.baseUrl;
  coreUrl: string = environment.coreUrl;
  accountUrl: string = environment.accountUrl
  imageUrl: string = environment.baseUrl + environment.coreUrl + 'data/image?imageName=';

  user: any;

  urlVisited: any;

  private banksRes = new BehaviorSubject<any>(null);
  private idTypeRes = new BehaviorSubject<any>(null);
  private businessTypeRes = new BehaviorSubject<any>(null);

  public searchLoanOfficersEndpoint = `${this.baseUrl + this.coreUrl}users/loan-officers?unPaged=false&page=0&pageSize=500&state=APPROVED&sort-order=DESC&filter=`


  constructor(private http: HttpClient, private utilityService: UtilityService, private toast: NotificationService) { }

  public getList(url: string, request: any, type?: string): Observable<any> {
    if (request == null) {
    } else {
      const page = request.start; // > 0 ? (request.start / request.length) : request.start;
      url += `?limit=${request.length}&page=${page}&`;
      const columns = request.columns;

      if (request.order && type !== 'user' && type !== 'staff') {
        let n = 0;
        request.order.forEach((order) => {
          if (n < 1)
            url += `sortField=submittedOn&sortOrder=${order.dir.toUpperCase()}&`;
          n++;
        });
      }

      /*request.filters.forEach((filter) => {
        if (filter.value) {
          url += `${filter.name}like=${filter.value}&`;
        }
      });*/

      if (request.hasOwnProperty('search') && request.search.value) {
        url += `searchParam=${request.search.value}&`;
      }

      if (request.extra) {
        JSON.parse(request.extra).map((obj, index) => {
          url += `${obj.name}=${obj.value}&`;
        });
      }
    }

    return this.http.get(url).pipe(map(res => {
      const data: any = res;
      if (data.code !== 96) {
        const count = data.total;
        return {
          recordsTotal: data.total,
          recordsFiltered: data.total,
          data: data.entities,
          draw: 5
        };
      } else {
        this.toast.error(data.message);
        return {
          recordsTotal: 0,
          recordsFiltered: 0,
          data: []
        };
      }
    }));

  }

  public getData(dataType: String): Observable<any> {
    return this.http.get(this.baseUrl + 'user-service/api/' + dataType);
  }

  public getGroup(queryParams?:PageRequest): Observable<any> {
    if(queryParams){
      const payload = this.utilityService.objToQueryParams(queryParams)
      return this.http.get(this.baseUrl +  `core/groups${payload}`) 
    }
    return this.http.get(this.baseUrl + 'core/groups');
  }
  
  public getNigeriaStates(): Observable<any> {
    return this.http.get(this.baseUrl + 'user-service/api/states/1');
  }
// cache banks
  public getBank(): Observable<any> {
    if(this.banksRes.value) return of(this.banksRes.value)
    return this.http.get(this.baseUrl + 'core/data/banks').pipe(tap(res=>{
      this.banksRes.next(res)
    }));
  }
  // cache id type
  public getIdType(tenantId: number): Observable<any> {
    if(this.idTypeRes.value) return of(this.idTypeRes.value)
    return this.http.get(`${this.baseUrl}core/clients/id-types?tenantId=${tenantId}`).pipe(tap(res=>{
      this.idTypeRes.next(res)
    }));
  }
  // cache business type
  public getBusinessType(): Observable<any> {
    if(this.businessTypeRes.value) return of(this.businessTypeRes.value)
    return this.http.get(this.baseUrl + 'core/clients/business-types').pipe(tap(res=>{
      this.businessTypeRes.next(res);
    }));
  }


  public getAccountData(dataType: String): Observable<any> {
    return this.http.get(this.baseUrl + 'api/v2/internal/account/data/' + dataType);
  }

  public getAccountingData(dataType: String): Observable<any> {
    return this.http.get(this.baseUrl + 'api/v2/internal/accounting/data/' + dataType);
  }
  public getGlType(): Observable<any> {
    return this.http.get(this.baseUrl + 'accounting/accounts/headers');
  }

  public setUser(data: any) {
    this.user = data;
  }

  public getUser() {
    return this.user;
  }

  public filterAnalytics(request: any): Observable<any> {
    let url;
    if (request == null) {
    } else {
      url = `startDate=${request.startDate}&endDate=${request.endDate}`;
    }
    return this.http.get(this.baseUrl + 'api/v2/internal/core/analytics/dashboardStats?' + url);
  }

  /*public setLastUrlVisited(url: any) {
    this.urlVisited = url;
  }

  public getLastUrlVisited() {
    return this.urlVisited;
  }*/

  public getAreas(): Observable<any> {
    const urlString = `api/v2/internal/core/areas`;
    return this.http.get(this.baseUrl + urlString);
  }

  public getApprovedAreas(): Observable<any>{
    const params = 'state=APPROVED&pageSize=500'
    return this.http.get(`${this.baseUrl}${this.coreUrl}areas?${params}`)
  }

  public getBranches(): Observable<any> {
    const urlString = `branch/list`;
    return this.http.get(this.baseUrl + this.coreUrl + urlString);
  }

  public getBranchByAreaId(id: number) {
    const urlString = `api/v2/internal/core/areas/${id}`;
    return this.http.get(this.baseUrl + urlString);
  }

  public getLoanOfficers(): Observable<any> {
    const urlString = `users/loan-officers`;
    return this.http.get(this.baseUrl + this.coreUrl + urlString);
  }

  public getLoanOfficersByAreaId(id: number) {
    const urlString = `api/v2/internal/core/areas/loanOfficers/${id}`;
    return this.http.get(this.baseUrl + urlString);
  }

  /*public getList(url:string, request:any): Observable<any> { 
      let page = request.start;// > 0 ? (request.start / request.length) : request.start;
      url += `?offset=${page}&limit=${request.length}&`;

      let n:number = 0;
      request.orders.forEach((order) => {
        if(n<1)
          url += `sort-field=${order.name}&sort-order=${order.dir.toUpperCase()}&`;
        n++;
      });

      request.filters.forEach((filter) => {
        if (filter.value) {
          url += `${filter.name}like=${filter.value}&`;
        }
      });

      if(request.fullTextFilter){
        url += `q=${request.fullTextFilter}&`;
      }



      return this.http.get(url).map(res => {
          let data:any = res;
          let count = data.data.total;
          return {
              recordsTotal: data.total,
              recordsFiltered: data.total,
              data: data.data,
              draw: 5
          };
      });

  }*/


  deleteANotification(data) {
    return this.http.delete<any>(`${this.baseUrl}api/v2/internal/users/notifications/support/delete/${data.notificationType}`);
  }

  getAccounts(params?: { useInLoanSettings?: boolean, useInDepositSetting?: boolean }): Observable<any>{
    return this.http.get(`${this.baseUrl}accounting/accounts/headers`, { params })
  }

  public getSavingsProduct(): Observable<any>{
    const query = 'status=APPROVED'
    return this.http.get(`${this.baseUrl}${this.accountUrl}savings-products?${query}`);
  }

  public getCharges(): Observable<any>{
    const query = 'is-penalty=false'
    return this.http.get(`${this.baseUrl}${this.accountUrl}charges/list?${query}`);
  }

  public getPenaltyCharges(): Observable<any>{
    const query = 'is-penalty=true'
    return this.http.get(`${this.baseUrl}${this.accountUrl}charges/list?${query}`);
  }

  public getRoles(): Observable<any>{
    return this.http.get(`${this.baseUrl}${this.coreUrl}roles`);
  }

  public getRole(id: number): Observable<any>{
    return this.http.get(`${this.baseUrl}api/v2/internal/core/roles/${id}`);
  }

  public getPermissions(): Observable<any>{
    return this.http.get(`${this.baseUrl}core/permissions`);
  }

}
