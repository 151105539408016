import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-charge-id',
  templateUrl: './select-charge-id.component.html',
  styleUrls: ['./select-charge-id.component.css']
})
export class SelectChargeIdComponent {
  @Input() charges:{id: number, name: string}[] = [];
  chargeForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SelectChargeIdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(data) {
      this.charges = data.charges;
    } 
    this.chargeForm = this.fb.group({
      chargeIds: new FormControl([Validators.required])
    })
  }

  public submit() {
    if(this.chargeForm.invalid) return
    this.dialogRef.close(this.chargeForm.value);
  }

  public cancel(){
    this.dialogRef.close();
  }

}
