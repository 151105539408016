import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-canvas-draw',
  templateUrl: './canvas-draw.component.html',
  styleUrls: ['./canvas-draw.component.css']
})
export class CanvasDrawComponent implements AfterViewInit {
  @Input() title: string = "Add Signature";
  @Input() identifier: string = "";
  @Output() getImageData = new EventEmitter<any>();
  @Output() clearImageData = new EventEmitter<any>();

  @ViewChild("can") can: ElementRef<HTMLCanvasElement>;

  private canvas: HTMLCanvasElement;
  private ctx;
  private flag = false;
  private prevX = 0;
  private currX = 0;
  private prevY = 0;
  private currY = 0;
  private dot_flag = false;
  private x = "black";
  private y = 2;

  constructor(private dialogRef: MatDialogRef<CanvasDrawComponent>) {}

  init() {
    this.canvas = this.can.nativeElement;
    this.ctx = this.canvas.getContext("2d");

    // On Pen Move
    this.canvas.addEventListener("touchmove", (e) => this.findxy("move", e));
    this.canvas.addEventListener("mousemove", (e) => this.findxy("move", e));
    // On Pen Down
    this.canvas.addEventListener("touchstart", (e) => this.findxy("down", e));
    this.canvas.addEventListener("mousedown", (e) => this.findxy("down", e));
    // On Pen Up
    this.canvas.addEventListener("touchend", (e) => this.findxy("up", e));
    this.canvas.addEventListener("mouseup", (e) => this.findxy("up", e));
    this.canvas.addEventListener("mouseout", (e) => this.findxy("out", e));
  }
  findxy(res, e) {
    if (res == "down") {
      this.prevX = this.currX;
      this.prevY = this.currY;
      this.currX = e.clientX - this.canvas.getBoundingClientRect().left;
      this.currY = e.clientY - this.canvas.getBoundingClientRect().top;

      this.flag = true;
      this.dot_flag = true;
      if (this.dot_flag) {
        this.ctx.beginPath();
        this.ctx.fillStyle = this.x;
        this.ctx.fillRect(this.currX, this.currY, 0, 0);
        this.ctx.closePath();
        this.dot_flag = false;
      }
    }
    if (res == "up" || res == "out") {
      this.flag = false;
    }
    if (res == "move") {
      if (this.flag) {
        this.prevX = this.currX;
        this.prevY = this.currY;
        this.currX = e.clientX - this.canvas.getBoundingClientRect().left;
        this.currY = e.clientY - this.canvas.getBoundingClientRect().top;
        this.draw();
      }
    }
  }
  draw() {
    this.ctx.beginPath();
    this.ctx.moveTo(this.prevX, this.prevY);
    this.ctx.lineTo(this.currX, this.currY);
    this.ctx.strokeStyle = this.x;
    this.ctx.lineWidth = this.y;
    this.ctx.stroke();
    this.ctx.closePath();
  }

  save() {
    // const canvasImg = document.getElementById("canvasimg") as HTMLImageElement;

    // canvasImg.style.border = "2px solid";
    this.canvas.style.width = "150px";
    this.canvas.style.height = "100px";
    const dataURL = this.canvas.toDataURL();

    

   
    if (this.isCanvasBlank(this.canvas)) {
      this.clear();
      this.dialogRef.close();
      return
    }

    // canvasImg!.src = dataURL;
    // document.getElementById("canvasimg").style.display = "inline";
    this.reset();
    this.canvas.style.width = "300px";
    this.canvas.style.height = "150px";

    this.dialogRef.close(dataURL)
  }

  reset() {
    const w = this.canvas.width;
    const h = this.canvas.height;
    this.ctx.clearRect(0, 0, w, h);
  }

  private isCanvasBlank(canvas) {
    const context = canvas.getContext("2d");

    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    return !pixelBuffer.some((color) => color !== 0);
  }

  clear() {
    this.reset();
    this.clearImageData.emit();
  }

  
  ngAfterViewInit(): void {
    this.init();
    
  }
  

}
