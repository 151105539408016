import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeesCommissionsComponent } from './components/fees-commissions/fees-commissions.component';
import { GrossEarningsComponent } from './components/gross-earnings/gross-earnings.component';
import { InterestIncomeInterestExpenseComponent } from './components/interest-income-interest-expense/interest-income-interest-expense.component';
import { NetInterestIncomeComponent } from './components/net-interest-income/net-interest-income.component';
import { StaffCostComponent } from './components/staff-cost/staff-cost.component';
import { ChartsModule } from 'src/app/shared/charts/charts.module';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    InterestIncomeInterestExpenseComponent,
    NetInterestIncomeComponent,
    FeesCommissionsComponent,
    GrossEarningsComponent,
    StaffCostComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChartsModule,
  ],
  exports: [
    InterestIncomeInterestExpenseComponent,
    NetInterestIncomeComponent,
    FeesCommissionsComponent,
    GrossEarningsComponent,
    StaffCostComponent
  ]
})
export class FinancialIndicatorsModule { }
