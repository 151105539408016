import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepositService } from 'src/app/pages/deposit/services/deposit.service';
import { DepositProductService } from 'src/app/pages/settings/services/deposit-products.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { WizerValidatorService } from 'src/app/utils/wizer.validators.service';
import { LoadingService } from '../../services/loading.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-new-deposit-account',
  templateUrl: './new-deposit-account.component.html',
  styleUrls: ['./new-deposit-account.component.css']
})
export class NewDepositAccountComponent implements OnInit {
  private clientId;
  public savingsProducts:any[] = []
  public savingsRef:any[] = [{
    id:'2',
    name:'Cash'
  }]

  public form: FormGroup;

  constructor(private fb: FormBuilder,
    private validatorService: WizerValidatorService,
    private service: DepositService,
    private notif: NotificationService,
    private loadingService: LoadingService,
    private depositProductService: DepositProductService,
    private dialogRef: MatDialogRef<NewDepositAccountComponent>,
    
    @Inject(MAT_DIALOG_DATA) public data: any) { }


    private initForm() {
      this.form = this.fb.group({
        savingsProductId: new FormControl('', [
          Validators.required,
        ]),
        // savingsReferenceGlAccountId: new FormControl('', Validators.required),
      });
    }

    get savingsFormCtrl() {
      return this.form.get('savingsProductId') as FormControl
    }

    private mapRecordsSearchType(records: Array<any>) {
      return records.map(record => ({...record, label: record?.name, value: record?.id}))
    }

    public selectRef(savingProduct:any){
     
    }

    public onSubmit(){

      if (!this.form.valid) {
        this.validatorService.CheckMessagesAndNotify(this.form.controls);
        return;
      }
      
      this.service.addSavingsAccount({...this.form.value, clientId: this.data.id }).subscribe(res=>{
        if(!res.successful) return;
        this.notif.success(res.message)
        this.dialogRef.close(true);
      })
    }
    
    public cancel() {
      this.dialogRef.close();
    }

    emitSelectedVal(val: any) {
      this.form.get('savingsProductId').patchValue(val.id)
    }

  ngOnInit(): void {
    this.initForm()
  }

}
