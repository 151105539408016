<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="bg-bg_outline text-black-70 p-6 py-2">
    <div class="flex justify-end items-center">
        <mat-icon class="text-[20px]" mat-dialog-close>close</mat-icon>
    </div>
    <hr>

    <!-- <h5 class="my-5 w-[330px] min-h-[15px] text-lg leading-7">Select a charge</h5> -->
    <form [formGroup]="chargeForm" class="my-6">
        <mat-form-field appearance="outline" class="short_height w-[330px]">
            <mat-label i18n="@@charges">Select Charge</mat-label>
            <mat-select formControlName="chargeIds">
                <mat-option [value]="charge?.id" *ngFor="let charge of charges">{{ charge?.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <div style="gap: 0 10px;" class="flex items-center justify-between mb-3">
        <app-button-icon-ui [buttonType]="'button'" classNames="px-[33px] py-[14px] h-[40px] w-[150px] rounded-[10px] text-white bg-success/90 hover:bg-success w-[120px] h-[45px]" (clickEmit)="submit()" name="Submit" value="submit"></app-button-icon-ui>
        <app-button-icon-ui [buttonType]="'button'" classNames="px-[33px] py-[14px] h-[40px] w-[150px] rounded-[10px] border border-error opacity-80 hover:opacity-100 text-error w-[120px] h-[45px]" (clickEmit)="cancel()" name="Cancel" value="cancel"></app-button-icon-ui>
    </div>
</div>