<div [ngClass]="{'gap-y-6': showLabel}" class="flex flex-col items-center">
    <!-- default photo viewer container -->
    <ng-container *ngIf="componentName === ''">
        <!-- <label class="text-sm" for="upload">{{ label }}</label> -->
        <div class="relative rounded-xl border w-[180px] h-[200px]">
            <img class="w-[100%] h-[100%] object-cover" *ngIf="isViewMode" [src]="srcImage" alt="avatar" />
            <img class="w-[100%] h-[100%] object-cover" *ngIf="!isViewMode && !filePreview" [src]="srcImage || 'assets/images/system-images/avatar.png'" alt="avatar" />
            <img class="w-[100%] h-[100%] object-cover" *ngIf="!isViewMode && filePreview && filePreview.type !== 'pdf'" [src]="filePreview.url" [alt]="filePreview.name" />
            <ngx-doc-viewer *ngIf="filePreview && filePreview.type === 'pdf'" [url]="filePreview.url" viewer="url" style="width:100%;height:100%;"></ngx-doc-viewer>
        </div>
    </ng-container>

    <ng-container *ngIf="componentName === 'modal'">
        <div class="relative rounded-xl border w-[100%]">
            <img class="w-[100%] h-[100%] object-cover" *ngIf="isViewMode" [src]="srcImage" alt="avatar" />
            <img class="w-[100%] h-[100%] object-cover" *ngIf="!isViewMode && !filePreview" [src]="srcImage || 'assets/images/system-images/avatar.png'" alt="avatar" />
            <img class="w-[100%] h-[100%] object-cover" *ngIf="!isViewMode && filePreview && filePreview.type !== 'pdf'" [src]="filePreview.url" [alt]="filePreview.name" />
            <ngx-doc-viewer *ngIf="filePreview && filePreview.type === 'pdf'" [url]="filePreview.url" viewer="url" style="width:100%;height:100%;"></ngx-doc-viewer>
        </div>
    </ng-container>

    <!-- staff photo viewer container -->
    <ng-container *ngIf="componentName === 'staff'">
        <div class="relative rounded-xl border w-[100%]" [ngClass]="isViewMode ? 'h-[178px]' : 'h-[100px]'">
            <img class="w-[100%] object-fill" *ngIf="isViewMode && !filePreview" [src]="srcImage" alt="avatar" />
            <img class="w-[100%] object-fill" *ngIf="!filePreview && !isViewMode" [src]="'assets/images/system-images/avatar.png'" alt="avatar" />
            <img class="w-[100%] object-fill" *ngIf="filePreview && filePreview.type !== 'pdf'" [src]="filePreview.url" [alt]="filePreview.name" />
            <ngx-doc-viewer *ngIf="filePreview && filePreview.type === 'pdf'" [url]="filePreview.url" viewer="url" style="width:100%;height:100%;"></ngx-doc-viewer>
        </div>
    </ng-container>

    <span *ngIf="!disabled" class="error font-weight-light text-center">Accepted formats: {{ acceptedFileTypes }}</span
  >
  <button type="button" *ngIf="!disabled" class="relative cursor-pointer bg-secondary/95 hover:bg-secondary text-white rounded-xl p-4 px-8">
    <input
      class="absolute top-0 left-0 opacity-0 w-[100%] h-[100%]"
      required
      #uploadFile
      [multiple]="isMultiple"
      [accept]="acceptedFileTypes"
      (change)="upload($event)"
      type="file"
    />
    <span>{{buttonPrefix}} {{ buttonValue }}</span>
    </button>
</div>