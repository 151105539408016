import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { LineChartComponent } from './line-chart/line-chart.component';
import { ColumnChartComponent } from './column-chart/column-chart.component';
import { GroupedColumnChartComponent } from './grouped-column-chart/grouped-column-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { ChartFilterComponent } from './chart-filter/chart-filter.component';
import { ChartWrapperComponent } from './chart-wrapper/chart-wrapper.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { ChartSummaryTableComponent } from './chart-summary-table/chart-summary-table.component';
import { ChartClientTableComponent } from './chart-client-table/chart-client-table.component';
import { CircleChartComponent } from './circle-chart/circle-chart.component';
import { ChartPaginatorComponent } from './chart-paginator/chart-paginator.component';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [
    LineChartComponent,
    ColumnChartComponent,
    GroupedColumnChartComponent,
    DoughnutChartComponent,
    CircleChartComponent,
    ChartFilterComponent,
    ChartWrapperComponent,
    ChartSummaryTableComponent,
    ChartClientTableComponent,
    ChartPaginatorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LayoutModule,
    SharedModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [
    LineChartComponent,
    ColumnChartComponent,
    GroupedColumnChartComponent,
    DoughnutChartComponent,
    CircleChartComponent,
    ChartFilterComponent,
    ChartWrapperComponent,
    ChartSummaryTableComponent,
    ChartClientTableComponent,
    ChartPaginatorComponent
  ]
})
export class ChartsModule { }
