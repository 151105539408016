import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from 'src/app/shared/models/common';
import { environment } from 'src/environments/environment';

interface RecentActivity {
  id: 6819622;
  event_time: string;
  event: string;
  initiator_name: 'App Administrator';
}
@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private authURL = `${environment.baseUrl}${environment.authUrl}`;
  private accountURL = `${environment.baseUrl}${environment.accountUrl}`;
  constructor(private http: HttpClient) {}

  public loggedInUser() {
    return this.http.get<any[]>(`${this.authURL}loggedIn-users`);
  }

  public recentActivity() {
    return this.http.get<API<RecentActivity[]>>(`${this.accountURL}recent-activities`);
  }
}
