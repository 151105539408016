import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-user-activity-write-offs',
  templateUrl: './user-activity-write-offs.component.html',
  styleUrls: ['./user-activity-write-offs.component.css']
})
export class UserActivityWriteOffsComponent implements OnInit {

  public hideFilters: boolean = false

  public writeOffForm: FormGroup
  public writeOffFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  filterWriteOffs(ev: any) {
  }

}
