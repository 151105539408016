import { Directive, ElementRef, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRemoveSpaces]'
})
export class RemoveSpacesDirective {

  constructor(@Self() private ngControl: NgControl) {}

  @HostListener('keyup', ['$event']) onKeyUp() {
    const value = this.ngControl.value
    if (value?.trim() === '') {
      this.ngControl.reset(null);
    }
  }
}
