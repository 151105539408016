<div class="flex justify-between flex-wrap gap-4 items-center">
    <h1 class="font-bold text-xl" *ngIf="title"> {{ title }}</h1>
    <div class="flex items-center flex-wrap gap-3">
        <!-- Run/Download Button Section -->
        <ng-container *ngIf="showRunButton">
            <span class="flex items-center bg-primary p-0 rounded-md">
        <button
        (click)="onRunMenu()"
        class="px-3 h-[35px] pl-5">{{ runText }}</button>
        <button
              *ngIf="showDownloadButton"
          [matMenuTriggerFor]="runMenuOptions"
          class="px-3 pr-5 border-l border-white h-[40px] flex items-center justify-center"
        >
          <img width="30" height="30" [src]="runIconPath" [alt]="runText" />
        </button>
      </span>
            <mat-menu #runMenuOptions="matMenu">
                <button mat-menu-item *ngFor="let menu of runMenu" class="text-base" (click)="onRunMenuChange(menu.value)">
          {{ menu.key }}
        </button>
            </mat-menu>
        </ng-container>

        <!-- State Button Section -->
        <ng-container *ngIf="states?.length > 0">
            <button mat-button [matMenuTriggerFor]="tableStates" class="state-btn">
        {{ activeStateName }}
        <img class="ml-2.5 h-5" src="assets/icons/filter_icon.svg" alt="">
      </button>
            <mat-menu #tableStates="matMenu">
                <ng-container *ngFor="let state of states">
                    <button mat-menu-item *hasPermissions="state.perms" [ngClass]="{ 'active-state': state.value === activeState }" class="state-item-btn" (click)="onStateChange(state.value)">
                        <span> {{ state.name  }}</span>
                    </button>
                </ng-container>
                <!-- <button mat-menu-item *ngFor="let state of states" [ngClass]="{ 'active-state': state.value === activeState }" class="state-item-btn" (click)="onStateChange(state.value)">
                    <span> {{ state.name  }}</span>
                </button> -->
            </mat-menu>
        </ng-container>
        <!-- Search Section -->
        <app-search-ui [initialVal]="initialValue" (searchEmit)="onSearch($event)" *ngIf="showSearchInput"></app-search-ui>
        <!-- Number of Selected Row Section -->
        <div *ngIf="showNumOfRowSelected && numOfRowSelected > 0" class="flex gap-x-2 h-[35px]">
            <span class="flex items-center bg-primary p-3 rounded-xl">
            <img src="assets/icons/tally_icon.svg" alt="">
            &nbsp;: {{numOfRowSelected}}
          </span>
            <span class="flex items-center bg-primary p-3 rounded-xl">
            <img src="assets/icons/sigma_icon.svg" alt="">
            &nbsp;: {{totalAmtOfRowSelected | wz_currency}}</span>
        </div>
    </div>
    <!-- Action Section (Approve, Reject,...) -->
    <div class="flex gap-x-2 items-center">
        <ng-content></ng-content>
        <app-action-bar-ui *ngIf="!showActionBar2" [actionButtons]="actionButtons" [disableActionButtons]="disableActionButtons" (actionEmit)="onActionTrigger($event)"></app-action-bar-ui>
        <app-action-bar2-ui *ngIf="showActionBar2" [actionButtons]="actionButtons" [disableActionButtons]="disableActionButtons" (actionEmit)="onActionTrigger($event)"></app-action-bar2-ui>
    </div>
</div>