<div>
  <div *ngIf="actionButtons?.length > 0" class="flex flex-wrap gap-4 items-center">
    <ng-container *ngFor="let button of actionButtons">
      <button
        [disabled]="disableActionButtons"
        [ngClass]="evalClass(button.value)"
        class="p-3 px-8 text-white rounded-xl opacity-90 border-none font-semibold mx-1"
        *hasPermissions="button.perms"
        (click)="actionToggled(button.value)"
      >
        {{ button.name }}
      </button>
        </ng-container>
    </div>
</div>