import { Component, OnInit } from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ResolveEnd,
  ResolveStart,
  Router,
} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/services/auth.service';
import { NotificationService } from './utils/notification.service';
import { UtilityService } from './utils/utility.service';
import { WebSocketService } from './utils/ws.service';
import { UserService } from './shared/services/user.service';
import { DataService } from './shared/services/data.service';
import { RoleService } from './shared/services/role.service';
import { UsernotificationService } from './shared/services/usernotification.service';
import { ConfigService } from './shared/services/config.service';
import { Notification } from './shared/models/notification';
import { LoadingService } from './shared/services/loading.service';
import { delay } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CustomIconsService } from './utils/customIcon.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  baseUrl: string = environment.baseUrl;
  public loading: boolean = false;
  toggle: any = true;
  public url: String;
  year: any;
  public urls: any;
  public user: any = {};
  public u: any = {};
  public isConfiguring: any = false;
  filter: string;
  password: any = {};
  public keepAliveUrl: string = 'http://google.com';
  public userPermissions: object;
  public notifications: Notification[] = [];
  public notificationCount: number = 0;
  unlockScreenParams: any = {};
  hour: number;
  url_comp: string;
  showReports: boolean;
  token: any;

  moduleList: any[] = [];
  clientSettingsList: any[] = [];

  showMobileToggler: boolean = false;
  showSideNav: boolean = true;
  isUserLoggedIn: any = true;

  selectedColor: any = 0;
  themeOptionSelected: any = 1;
  selectImageOption: any = 1;
  currentStep: any = 1;

  paletteObj = {
    lightMuted: '',
    vibrant: '',
    muted: '',
    darkVibrant: '',
    darkMuted: '',
    lightVibrant: '',
  };

  selectedColorArray: any[] = [];

  /*
   * Keep Alive
   * */
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  franchiseeDetails: any = {};
  tenantDetails: any = {};

  _notifications: any = [];
  modalNotifications;
  hasNotification: boolean = false;

  constructor(
    private router: Router,
    private _loading: LoadingService,
    private utils: UtilityService,
    private _user: UserService,
    private _auth: AuthService,
    public roleService: RoleService,
    private customIconsService: CustomIconsService,
    private userNotificationService: UsernotificationService,
    private toast: NotificationService,
    private dataService: DataService,
    private utilsService: UtilityService,
    private wsService: WebSocketService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    
  }

  ngOnInit() {
    // this.wsService.fetchWebSocketData();
    this.utilsService.currentNotifications.subscribe((data) => {
      if (data !== undefined) {
        this._notifications = data.notifications;
        this.hasNotification = true;
      }
    });

    this.listenToLoading();

    // this.roleService.getAllPermissions().subscribe(data => {
    // })
    // this.franchiseeDetails = JSON.parse(localStorage.getItem('user')).company;
    // this.tenantDetails = JSON.parse(localStorage.getItem('user')).tenant;

    const that = this;
    if (window.innerWidth <= 1024) {
      this.showMobileToggler = true;
      this.showSideNav = false;
    }
    if (
      window.innerWidth <= 1366 &&
      window.innerHeight > 1000 &&
      window.innerHeight <= 1030
    ) {
      this.showMobileToggler = true;
      this.showSideNav = false;
    }

    // $('#img2-placeholder').imagepreview({
    //   input: '[name="clientLogo"]',
    //   reset: '',
    //   preview: '.client-photo-placeholder-two',
    // });

    // this.configService.getCountryList().subscribe((data: any) => {
    //   if (data.code == 0) {
    //     this.countryList = data.data.data;
    //     this.countryList.forEach((d) => {
    //       let currency = {
    //         id: d.id,
    //         name: d.currencyName,
    //         symbol: d.currencySymbol
    //       };
    //       this.currencies.push(currency);
    //     });
    //   }
    // });

    this.hour = new Date().getHours();
    this.year = new Date().getFullYear();

    this.url_comp = window.location.pathname;

    this.showReports =
      this.hour < 8 || (this.hour >= 10 && this.hour <= 16) || this.hour >= 20;

    this.dataService.urlVisited = this.router.url;

    if (localStorage['token'] == null) {
      if (this.url_comp == '/login') {
        // $('#modal-change-password').modal('hide');
        this.router.navigate(['login']);
      } else if (this.url_comp == '/reset-password') {
        $('#modal-change-password').modal('hide');
        this.token = window.location.search.split('?token=');
        if (this.token[0]) {
          this.router.navigate(['reset-password'], {
            queryParams: { token: window.location.search.split('?token=')[1] },
          });
        }
      } else {
        // $('#modal-change-password').modal('hide');
        // window.location.href = 'login';
        this.router.navigate(['login']);
      }
    }
 
    // else {
    //   this.u = JSON.parse(localStorage['user']);
    //   // Todo: Also check if the user is a first not a first timer
    //   if (this.u.passwordChangeRequired) {
    //     // $('#modal-change-password').modal('show');
    //   } else {
    //     this._user.getUser(this.u.id).subscribe(data => {
    //       if (data.code == 0) {
    //         this.user = data.data;
    //         this.dataService.setUser(this.user);
    //         this.roleService.getRolePermissions(this.u.role.id).subscribe(d => {
    //           this.roleService.setUserPermissions(d);
    //         });
    //         // this.initKeepAlive(this.keepAliveUrl);
    //       } else {
    //         this.router.navigate(['login']);
    //       }
    //     }, err => {
    //       this.router.navigate(['login']);
    //       // this.toast.error('Session has expired');

    //     });
    //   }
    // }

    // this.tenantInfo.themeName = 'Salted';
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
    this._auth.logout().subscribe((data) => {
      // localStorage.clear();
      // location.reload();
    });
  }

  /* reset password. first time login */
  changePassword(): void {
    // if ($('#form-password-required').smkValidate()) {
    if (
      !this.password.oldPassword ||
      this.password.oldPassword.trim().length === 0
    ) {
      this.toast.error('Kindly enter your old password');
    } else if (
      !this.password.newPassword ||
      this.password.newPassword.trim().length < 6
    ) {
      this.toast.error('Password should contain a minimum of six character');
    } else if (this.password.newPassword !== this.password.retypedPassword) {
      this.toast.error('Passwords do not match');
    } else {
      this.utils.showLoading();
      // this._auth
      //   .resetPassword(
      //     this.u.id,
      //     this.password.oldPassword,
      //     this.password.newPassword,
      //     'MONTHLY_CHANGE'
      //   )
      //   .subscribe((data) => {
      //     if (data.code == 0) {
      //       // $('#modal-change-password').modal('hide');
      //       this.toast.success(data.message);
      //       setTimeout(function () {
      //         localStorage.clear();
      //         location.reload();
      //       }, 2000);
      //     } else {
      //       this.toast.error(data.message);
      //     }
      //     this.utils.hideLoading();
      //   });
    }
    // }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.utils.showLoading();
      this.urls = event.url.split('?token=');
      if (this.urls[0]) {
        this.url = this.urls[0];
      }
    }
    if (event instanceof NavigationEnd) {

      this.utils.hideLoading();
      if (
        (window.innerWidth <= 1366 &&
          window.innerHeight > 1000 &&
          window.innerHeight <= 1030) ||
        window.innerWidth <= 1024
      ) {
        this.showMobileToggler = true;
        this.showSideNav = false;
      }
      // this.showSideNav = false;
      if (this.url === '/login') {
        this.notifications = [];
      } else {
        // this.userNotificationService.getNotifications().subscribe(data => {
        //   this.notifications = data.notifications;
        //   this.notificationCount = data.totalCount;
        // });
      }
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.utils.hideLoading();
      if (
        (window.innerWidth <= 1366 &&
          window.innerHeight > 1000 &&
          window.innerHeight <= 1030) ||
        window.innerWidth <= 1024
      ) {
        this.showMobileToggler = true;
        this.showSideNav = false;
      }
      // this.showSideNav = false;
    }
    if (event instanceof NavigationError) {
      this.utils.hideLoading();
      if (
        (window.innerWidth <= 1366 &&
          window.innerHeight > 1000 &&
          window.innerHeight <= 1030) ||
        window.innerWidth <= 1024
      ) {
        this.showMobileToggler = true;
        this.showSideNav = false;
      }
      // this.showSideNav = false;
    }
  }

  search(): void {
    this.router.navigateByUrl('/clients?filter=' + this.filter);
  }

  public inClient(): boolean {
    let val = this.router.isActive('/clients', false);
    return val;
  }

  toggleLogo() {
    this.toggle = this.toggle !== true;
  }

  selectBusinessType() {
    /*if (this.tenantInfo.type == 1) {
      this.tenantInfo.name = ''
    }*/
  }

  isModuleChecked(module: number): boolean {
    return this.moduleList.includes(module) ? true : false;
  }

  toggleModule(module: number) {
    var state = this.isModuleChecked(module);
    state
      ? this.moduleList.splice(this.moduleList.indexOf(module), 1)
      : this.moduleList.push(module);
  }

  toggleClientSettings(setting: number) {
    var state = this.isSettingsChecked(setting);
    state
      ? this.clientSettingsList.splice(
          this.clientSettingsList.indexOf(setting),
          1
        )
      : this.clientSettingsList.push(setting);
  }

  isSettingsChecked(setting: number) {
    return this.clientSettingsList.includes(setting) ? true : false;
  }

  showTermsAndConditionModal(status) {
    $('#terms-modal').modal(status == true ? 'show' : 'hide');
  }

  redirectToDashboard() {
    if ($('#form-change-password').smkValidate()) {
      if ($.smkEqualPass('#newPassword', '#retypePassword')) {
        this.utils.showLoading();
        setTimeout(function () {
          location.href = '/dashboard';
        }, 2000);
      }
    }
  }

  reset() {
    this.idle.watch();
    // this.idleState = 'Started.';
    this.timedOut = false;
  }

  selectColor(index: any) {
    this.selectedColor = index;
  }

  imageOption(imageOption) {
    this.selectImageOption = imageOption;
  }

  // previousStep() {
  //   if (this.pageNumber > 1) {
  //     this.pageNumber = this.pageNumber - 1;
  //   }
  // }

  // nextStep() {
  //   if (this.pageNumber !== 3) {
  //     this.pageNumber = this.pageNumber + 1;
  //   }
  // }

  removeNotification(data) {
    this.dataService.deleteANotification({ notificationType: data }).subscribe(
      (status) => {
        if (status.code !== 0) {
          this.toast.error(`${status.message}.`);
        }
      },
      (error) => {
        this.toast.error('An error occured when deleting notification.');
      }
    );
  }
}