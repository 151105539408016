<div class="relative">

    <div class="top-[-0.6rem] right-[-0.6rem] z-10 absolute text-white flex flex-col gap-1" *ngIf="isFileUploaded">
        <div class="w-[25px] h-[25px] rounded-lg cursor-pointer bg-red-400 grid place-items-center place-content-start" [ngClass]="disabled ? 'hidden': '' " (click)="onRemoveFile()">
            <mat-icon class="!w-[12px] !h-[12px]">delete</mat-icon>
        </div>

        <!-- <div class="w-[25px] h-[25px] rounded-lg cursor-pointer bg-sky-400 grid place-items-center place-content-start" [ngClass]="disabled ? 'hidden': '' " (click)="viewFile()">
            <mat-icon class="!w-[12px] !h-[12px]">search</mat-icon>
        </div> -->
    </div>

    <div class='overflow-hidden w-[130px] h-[130px] p-4 border-2 border-gray-300 rounded-lg flex flex-col items-center justify-center relative' [ngClass]="disabled ? 'cursor-not-allowed': '' " [ngClass]="classNames" (click)="selectFile()">
        <div class="cursor-pointer w-[80%] mx-auto h-[70%]" [ngClass]="disabled ? 'cursor-not-allowed': '' ">
            <ng-content></ng-content>

            <img class="w-full h-full object-contain rounded-lg" #imageElem [src]="currentPhoto" [ngClass]="{'d-none': !isFileUploaded}" accept=".jpg, .jpeg, .png, .svg" (error)="defaultImagePlaceholder($event)" />
            <input type="file" hidden name="fileUploadInput" class="form-control hidden" #fileInput (change)="setSelectedImg($event)">
        </div>

        <div [ngClass]="editing ? '!text-error': ''" class="text-center text-sm mb-1">
            <h3 class="font-bold mt-2 px-2">{{ formatLabel }}</h3>
            <small *ngIf="!isFileUploaded">.png, .jpg, .bmp (Max. 2MB)</small>
        </div>
    </div>
</div>