import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { depositChartData } from 'src/app/shared/charts/datasource';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-net-interest-income',
  templateUrl: './net-interest-income.component.html',
  styleUrls: ['./net-interest-income.component.css']
})
export class NetInterestIncomeComponent implements OnInit {

  public hideFilters: boolean = false
  public netInterestIncomeForm: FormGroup
  public netInterestIncomeFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]

  public netInterestSortingOptions: { key: 'name' | 'value', sortingDirection: 'asc' | 'desc' | 'default' } = {
    key: 'name',
    sortingDirection: 'asc'
  }
  netInterestChartData?: any[] = depositChartData;

  constructor() { }

  ngOnInit(): void {
    this.netInterestChartData = depositChartData.map((item: any) => ({
      name: item.country,
      value: item.gold
    }))
  }

  filterNetInterestIncome(ev: any) {
    if (ev.type === 'SORT') {
      const selectedString = ev.value === 'default' ? 'name-default' : ev.value;
      const [key, sortingDirection] = selectedString.split('-');
      this.netInterestSortingOptions = { key, sortingDirection };
    }
  }

}
